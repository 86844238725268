export interface GetActiveUpgradesProps {
    accountsApiURL: string;
    accountsToken: string;
}


const getUnactivatedUpgrades = async (props: GetActiveUpgradesProps): Promise<OmniAccounts.RegistrationObj> => {
    return await new Promise((resolve, reject) => {
        fetch(`${props.accountsApiURL}/api/1.1/unactivated_registrations/`, {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${props.accountsToken}`,
                'Content-Type': 'application/json',
            },
            method: 'GET',
            mode: 'cors',
        })
            .then((response) => resolve(response.json()))
            .catch((error) => {
                console.log('error getting upgrades to activate', error);
                return reject(error);

            });  
    })
};

export default getUnactivatedUpgrades;