import dayjs from 'dayjs';

export const giveMeEpochTimeAsTime = (epochtime: number): string => {
    const utcSeconds = epochtime;
    const momentTime = dayjs.unix(utcSeconds);
    const formattedMoment = momentTime.format('MMM DD, YYYY');
    return formattedMoment;
};

export const giveMeLongTimeStampAsTime = (
    longtime: number | string
): string => {
    const dayjstime = dayjs(longtime);
    return dayjstime.format('MMM DD, YYYY');
};
