import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';

import Routes from './Routes';
import * as serviceWorker from './serviceWorker';

if (process.env.NODE_ENV !== 'development') {
    Sentry.init({
        autoSessionTracking: true,
        dsn: 'https://d5ce730ec6b14403892e975befe595ac@sentry.omnigroup.com/4',
        environment:
            process.env.REACT_APP_PURCHASE_HOST ===
            'https://purchase.test.omnigroup.com'
                ? 'development'
                : 'production',
        release: process.env.REACT_APP_SENTRY_RELEASE
            ? process.env.REACT_APP_SENTRY_RELEASE
            : undefined,
    });
}

const rootNode = document.getElementById('root');

ReactDOM.render(<Routes />, rootNode);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
