import React, { useState, useEffect, createRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import Note from '../reusable-components/Note';
import { Button } from '../reusable-components/Button';
import ErrorBox from '../reusable-components/ErrorBox';
import OmniAccountTerms from './OmniAccountTerms';

import handleSignIn from '../utilities/handleSignIn';
import { ActionType } from '../store/types';

const UpdateForm = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const AugmentedP = styled.p`
    color: #808080;
    text-align: center;
    font-size: 14px;
`;

const TermsWrap = styled.div`
    margin: 40px auto 10px;
    line-height: 1.3em;
    scroll-padding: 20px;
    max-width: 500px;

    a {
        color: rgba(76, 168, 243, 1);
    }
`;

const AgreementWrap = styled.div`
    display: flex;
    flex-direction: row;
    margin: 20px auto 0;
    justify-content: center;
    padding: 25px 10px;

    label {
        margin: auto 10px;
    }

    input {
        width: 30px;
        margin: auto 0;
    }
`;

interface AccountSetupProps {
    accessToken: string;
    stepThreeComplete: () => void;
}

const AccountSetup: React.FC<AccountSetupProps> = (props: AccountSetupProps) => {
    const dispatch = useDispatch();

    const myRef = createRef<HTMLFormElement>();
    const checkboxRef = createRef<HTMLInputElement>();

    const accountsApiURL = useSelector((state: OmniAccounts.GlobalReduxState) => state.accountsApiURL);

    const [loading, setLoading] = useState<boolean>(false);
    const [checkedBox, checkBox] = useState<boolean>(false);
    const [newAccountName, setNewAccountName] = useState<string>('');
    const [newPassword, setNewPassword] = useState<string>('');
    const [confirmNewPassword, setConfirmNewPassword] = useState<string>('');
    const [errorLanguage, setErrorLanguage] = useState<string>('');
    const [theyAgree, setTheyAgree] = useState<boolean>(false);
    const [charError, setCharError] = useState<boolean>(false);
    const [clickTime, setClickTime] = useState<boolean>(false);

    const handleAccountName = (username: string) => {
        setNewAccountName(username.replace(/\s+/g, '').toLowerCase());
    };

    useEffect(() => {
        if (errorLanguage.length === 0) {
            return;
        } else {
            if (myRef.current !== null) {
                myRef.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                });
            }
        }
    }, [errorLanguage, clickTime]);

    const checkForValidity = async () => {
        if (newAccountName === '' || props.accessToken === '') {
            return { usernameValid: false }; // false because of no content to check
        }
        if (
            newAccountName.includes('$') ||
            newAccountName.includes('%') ||
            newAccountName.includes('*') ||
            newAccountName.includes('\\') ||
            newAccountName.includes(':') ||
            newAccountName.includes('/') ||
            newAccountName.includes('~') ||
            newAccountName.includes('`')
        ) {
            setErrorLanguage('Sorry, but some of the characters in your username aren’t allowed.');
            setCharError(true);
            return { usernameValid: false }; // false because of content
        }
        const checkUsername = await fetch( `${accountsApiURL}/api/1.1/accountnamevalid/`, {
            headers: {
                Authorization: 'Bearer ' + props.accessToken,
                'Content-Type': 'application/json; charset=utf-8',
            },
            body: JSON.stringify({ account_name: newAccountName, }),
            method: 'POST',
            mode: 'cors',
        });

        try {
            const response = await checkUsername.json();

            if (!response) {
                setErrorLanguage(response);
                setLoading(false);
                return { usernameValid: false }
            } else if (response) {
                setErrorLanguage('');
                setLoading(false);
                return { usernameValid: true }
            }
        } catch (error: any) {
            setErrorLanguage('Something went wrong when checking if this username is valid.');
            return error;
        }
    };

    const updateUserAccount = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);

        const validityCheckResult = await checkForValidity();

        if (validityCheckResult?.usernameValid === false) {
            setLoading(false);
            if (myRef.current !== null) {
                myRef.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                });
            }
            return;
        }
        if (newPassword !== confirmNewPassword) {
            setErrorLanguage('The passwords you’ve entered do not match. Please try again.');
            setLoading(false);
            return;
        }
        if (theyAgree !== true) {
            checkBox(true);
            setLoading(false);
            return;
        }
        checkBox(false);

        const updateCustomerCall = await fetch( `${accountsApiURL}/api/1.1/user/`, {
            body: JSON.stringify({
                new_account_name: newAccountName,
                new_password: newPassword,
                new_password_2: confirmNewPassword,
            }),
            headers: {
                Authorization: 'Bearer ' + props.accessToken,
                'Content-Type': 'application/json; charset=utf-8',
            },
            method: 'POST',
            mode: 'cors',
        });

        const updateCustomerCallResponse = await updateCustomerCall.json();

        if (!updateCustomerCallResponse.errors) {
            const signInResponse = await handleSignIn({
                accountsApiURL: accountsApiURL,
                password: newPassword,
                username: newAccountName,
            });

            if (signInResponse){
                if (!signInResponse.errors) {
                    if (signInResponse.token.access_token) {
                        const userAccountState = signInResponse.userinfo.account_state;
    
                        if ( userAccountState === 300 || userAccountState === 301 ) {
                            // this is a special case where the signin worked, but the account_state means closed
                            setErrorLanguage('Your account has been closed. You’ll need to sign up for a new account.');
                            setLoading(false);
                            return;
                        }
                        localStorage.setItem('accountsauth', JSON.stringify(signInResponse));
    
                        dispatch({
                            type: ActionType.USER_LOGGED_IN,
                            accessToken: signInResponse.token.access_token,
                        });
                    }
                } else {
                    switch (signInResponse.code) {
                        case 30003: //They need to go to accounts admin to do anything
                            console.log('special sign in error');
                            break;
                        case 3002: //they got a bad username or password
                            setErrorLanguage( 'Your account name or password was incorrect.');
                            break;
                        default:
                            setErrorLanguage('Your account name or password was incorrect.');
                            break;
                    }
                    setLoading(false);
                }
            }
            props.stepThreeComplete();
        } else {
            setErrorLanguage(updateCustomerCallResponse.errors[0].message);
            setLoading(false);
            setClickTime(!clickTime);
            return;
        }
    };

    return (
        <UpdateForm onSubmit={updateUserAccount} ref={myRef}>
            {errorLanguage.length > 0 && (
                <ErrorBox errorTitle='Error'>
                    <p>{errorLanguage}</p>
                    {charError && (
                        <p>
                            Specifically, don’t use special characters like{' '}
                            <code>$, %, `, ~, *, \, :, /</code>.
                        </p>
                    )}
                </ErrorBox>
            )}
            <label htmlFor='new_account_name'>Account Name:</label>
            <input
                autoComplete='username'
                autoCapitalize='false'
                name='new_account_name'
                placeholder=''
                title='An account name that contains lowercase letters and/or numbers. Spaces, `$`, and `%` are not allowed.'
                onChange={(e) => handleAccountName(e.target.value)}
                spellCheck='false'
                type='username'
                autoFocus
                value={newAccountName}
                style={{ textTransform: 'lowercase' }}
            />
            <AugmentedP>
                Account names can contain lowercase letters and/or numbers.
            </AugmentedP>
            <AugmentedP>
                Your account name is used when signing in to your Omni account,
                including when signing in to OmniFocus for the Web. It is also
                used when setting up sync in OmniFocus, OmniPlan, and
                OmniPresence.
            </AugmentedP>
            <label htmlFor='new_password'>Password:</label>
            <input
                autoComplete='new-password'
                name='new_password'
                placeholder=''
                onChange={(e) => setNewPassword(e.target.value)}
                spellCheck='false'
                type='password'
                required={true}
            />

            <label htmlFor='new_password_2'>Retype Password:</label>
            <input
                autoComplete='new-password'
                name='new_password_2'
                placeholder=''
                onChange={(e) => setConfirmNewPassword(e.target.value)}
                spellCheck='false'
                type='password'
                required={true}
            />

            {checkedBox ? (
                <ErrorBox>
                    ↓ Please read and agree to the terms below. ↓
                </ErrorBox>
            ) : null}
            <TermsWrap>
                <h2
                    style={{
                        color: 'rgba(76, 168, 243, 1.00)',
                        fontSize: '20px',
                    }}
                >
                    OMNI SYNC SERVER TERMS OF USE
                </h2>
                <OmniAccountTerms />
                <Note>
                    This service is free; we are not liable if something goes
                    wrong. We value your privacy, but{' '}
                    <a href='http://www.omnigroup.com/legal/privacy/'>
                        you should read our detailed policy
                    </a>
                    . If you have any questions, please{' '}
                    <a href='mailto:sync@omnigroup.com'>contact us</a>.
                </Note>
                <AgreementWrap>
                    <input
                        ref={checkboxRef}
                        type='checkbox'
                        id='agree'
                        style={{ float: 'right' }}
                        onChange={() => setTheyAgree(!theyAgree)}
                    />
                    <label htmlFor='agree'>
                        I agree to the terms and conditions above.
                    </label>
                </AgreementWrap>
            </TermsWrap>

            <Button
                type='submit'
                value='Create Account'
                showLoading={loading ? true : false}
                showDisabled={!theyAgree}
            >
                <span>Create Account</span>
            </Button>
        </UpdateForm>
    );
};

export default AccountSetup;