import * as React from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';

import { giveMeLongTimeStampAsTime } from '../utilities/givemetime';

const PurchaseBlock = styled.div`
    padding-left: 20px;
    border-left: 2px solid #404040;
    margin-bottom: 20px;
    font-size: 14px;
`;

const ProductName = styled.div`
    font-size: 16px;
    font-weight: bold;
    margin: 10px 10px 5px 0;
`;

const Highlight = styled.span`
    background-color: rgba(254, 255, 0, 0.31);
`;

interface LicenseEntryProps {
    licenseItem: OmniAccounts.LicenseObject;
    key: number;
    correspondingManagedLicenseID?: number;
}

const LicenseEntry: React.FC<LicenseEntryProps> = (props: LicenseEntryProps) => {
    Modal.setAppElement('#root');

    // const [modalIsOpen, setAssignSeatModalIsOpen] = React.useState(false);
    // const userInfo = useSelector((state: OmniAccounts.GlobalReduxState) => state.userInfo);
    return (
        <PurchaseBlock>
            <ProductName>
                {props.licenseItem.description === 'OmniOutliner 5 for Mac'
                    ? 'OmniOutliner 5 Essentials for Mac'
                    : props.licenseItem.description}
            </ProductName>
            {props.licenseItem.owner !== undefined && (
                <div>
                    <span>Owner: </span>
                    <span>{props.licenseItem.owner}</span>
                </div>
            )}
            {'key' in props.licenseItem && (
                <div>
                    <span>Key: </span>
                    <span>{props.licenseItem.key}</span>
                </div>
            )}
            <div>
                <span>Date registered: </span>
                <span>
                    {props.licenseItem.registration_date &&
                        giveMeLongTimeStampAsTime(
                            props.licenseItem.registration_date
                        )}
                </span>
            </div>
            {/* {props.licenseItem.owner === userInfo?.email && !props.archived && (
                <div>
                    <span
                        style={{
                            cursor: 'pointer',
                            fontSize: '.9em',
                            textDecoration: 'underline',
                        }}
                        onClick={() => setAssignSeatModalIsOpen(true)}
                    >
                        Assign Seat
                    </span>
                </div>
            )} */}
            {props.licenseItem.key && props.licenseItem.upgrades_remaining > 0 && (
                <div>
                    <span>Upgrades remaining: </span>
                    <span>{props.licenseItem.upgrade_eligible_registration ? props.licenseItem.upgrades_remaining : 0}</span>
                </div>
            )}
            {props.licenseItem.upgrades_remaining === 0 && (
                <div>
                    <Highlight>Archived: Fully upgraded</Highlight>
                </div>
            )}
        </PurchaseBlock>
    );
};

export default LicenseEntry;
