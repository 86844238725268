export interface GetSubscriptionDataParams {
    purchaseapiURL: string;
    customerID: string;
    accountsToken: string;
}

export interface GetSubscriptionDataResponse {
    expert: string;
}

const getSubscriptionData = async (
    props: GetSubscriptionDataParams
): Promise<GetSubscriptionDataResponse | undefined> => {
    try {
        const someJSON = await fetch(
            `${props.purchaseapiURL}/api/v2/customer/${props.customerID}/subscriptions`,
            {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${props.accountsToken}`,
                    'Content-Type': 'application/json',
                },
                method: 'GET',
                mode: 'cors',
            }
        );
        const response = await someJSON.json();
        return response;
    } catch(error) {
        console.log('error getting subscription data', error)
        return undefined;
    }
};

export default getSubscriptionData;
