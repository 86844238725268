interface AddEmailReturnI {
    status?: 'error' | 'success';
    resent_invites?: Array<string>;
    invalid_emails?: Array<string>;
    emails_not_sent?: Array<string>;
    message?: string;
}

const addListOfEmails = async (
    accountsToken: string,
    accountsApiURL: string,
    currentID: number,
    bulkEmailtoAdd: string
): Promise<AddEmailReturnI> => {
    const emailField = new URLSearchParams();
    emailField.set('seats', bulkEmailtoAdd);

    const productSeats = await fetch(
        `${accountsApiURL}/api/1.1/registration_seats/${currentID}/`,
        {
            body: emailField,
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + accountsToken,
                'Content-Type':
                    'application/x-www-form-urlencoded; charset=utf-8',
            },
            method: 'POST',
            mode: 'cors',
        }
    )
        .then((response) => {
            return response.json();
        })
        .catch((err) => {
            return err;
        });
    return productSeats;
};

export default addListOfEmails;
