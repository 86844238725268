import * as React from 'react';
import styled from 'styled-components';
import { TinyButton } from '../reusable-components/Button';

const InputWrap = styled.form`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
    text-align: center;
`;

const Wrapper = styled.div`
    width: 100%;
    margin-top: 20px;

    h3 {
        text-transform: unset;
        font-weight: 400;
    }
`;

const TinyInput = styled.input`
    font-size: 12px;
    padding: 3px;
    max-width: 200px;
    margin: 0 10px 0 0;
`;

interface SignUpForNewsletter {
    emailaddy: string;
}

const LikeEmail: React.FC<SignUpForNewsletter> = (
    props: SignUpForNewsletter
) => {
    return (
        <Wrapper>
            <h3>Subscribe to Omni Newsletter</h3>
            <InputWrap>
                <TinyInput type='email' defaultValue={props.emailaddy} />
                <TinyButton showDisabled={false} showLoading={false}>
                    Subscribe
                </TinyButton>
            </InputWrap>
        </Wrapper>
    );
};

export default LikeEmail;
