import Stripe from 'stripe';

export interface PurchasePostInterface {
    customer?: Stripe.Customer;
    errors?: Array<{ code: number; message: string }>;
}

export const getStripeCustomerData = async (
    accountsToken: string,
    cus_number: string,
    purchaseURL: string
): Promise<PurchasePostInterface> => {
    return await new Promise((resolve, reject) => {
        fetch(`${purchaseURL}/api/v2/customer/` + cus_number, {
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + accountsToken,
                'Content-Type': 'application/json',
            },
            method: 'GET',
            mode: 'cors',
        })
            .then((response) => {
                return response.json();
            })
            .then((response) => {
                if (response.errors === undefined) {
                    return resolve({ customer: response });
                } else if (response.errors) {
                    return resolve({ errors: response });
                }
            })
            .catch((err) => {
                return reject(err);
            });
    });
};
