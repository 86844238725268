export interface EmailPostI {
    accountsApiURL: string;
    email: string;
    accountsToken: string;
}

export interface EmailPostReturnI {
    updated_attributes?: string;
    errors?: Array<{ code: number; message: string }>;
}

const postEmailChange = async (
    props: EmailPostI
): Promise<EmailPostReturnI> => {
    return await new Promise((resolve, reject) => {
        fetch(`${props.accountsApiURL}/api/1.1/user/`, {
            body: JSON.stringify({
                new_email_address: props.email,
            }),
            headers: {
                Authorization: 'Bearer ' + props.accountsToken,
                'Content-Type': 'application/json; charset=utf-8',
            },
            method: 'POST',
            mode: 'cors',
        })
            .then((response) => {
                return resolve(response.json());
            })
            .catch((err) => {
                return reject(err);
            });
    });
};

export default postEmailChange;
