import React, { useState, useEffect } from 'react';
import Stripe from 'stripe';
import { List } from 'react-content-loader';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { ActionType } from '../store/types';
import { useTheme, useThemeUpdate } from './modifySubscription/ThemeContext';
import AutoRenewPortal from './modifySubscription/AutoRenewPortal';
import ReassignSeatPortal from './ReassignSeatPortal';
import ModifySubscriptionPortal from './modifySubscription/ModifySubscriptionPortal';
import HoldingBox from '../reusable-components/HoldingBox';
import Portal from '../reusable-components/Portal';

import { getSeatData } from '../utilities/postGrabSeats';
import getSubscriptionData from '../utilities/getSubscriptionData';
import { giveMeEpochTimeAsTime } from '../utilities/givemetime';


const ProductWrapper = styled.div`
    border-left: 2px solid #404040;
    margin: 10px 10px 10px 0;
    padding-left: 20px;
`;

const ProductContainer = styled.ul`
    list-style-type: none;
    padding-left: 0;
    margin: 0;

    li::last-child:after {
        content: '';
    }
`;
const Product = styled.h4`
    display: inline;
    padding: 0 5px 0 0;
`;

const ProductName = styled.span`
    display: inline;
    padding: 0 5px 0 0;

    span::after {
        content: ', ';
    }
    span:last-of-type:after {
        content: '';
    }
`;

const BillingDate = styled.div`
    font-weight: bold;
`;

const SeatsHolder = styled.div`
    display: flex;
    flex-direction: column;
    /* margin: 5px 0; */
    transition: all 0.5s ease-out;
`;

const ModalLink = styled.a`
    background-color: transparent;
    border: none;
    outline: none;
    text-decoration: underline;
    font-size: 14px;
    cursor: pointer;
    padding-right: 10px;
`;

const SectionSubtext = styled.div`
    padding-bottom: 20px;
`;
 

const Seats: React.FC = () => {
    const dispatch = useDispatch();

    const { currentSubscription, subscriptionMenu } = useTheme();
    const { setCurrentSubscription, toggleMenu } = useThemeUpdate();
    
    const [autoRenewPortal, setToggleRenewPortal] = useState<boolean>(false);
    const [ assignSeatPortal, setToggleAssignSeatPortal ] = useState<boolean>(false);
    const [ loading, setLoading ] = useState<boolean>(false);
    const [ subscriptionMenuPortal, setToggleSubscriptionMenuPortal ] = useState<boolean>(false);
    const [ subscriptionType, setSubscriptionType ] = useState<Stripe.Plan.Interval | null>(null); //useState<'month' | 'year' | null>(null);
    const [ teamIDtoEdit, setTeamIdtoEdit ] = useState<number | null>(null);

    const accountsToken = useSelector((state: OmniAccounts.GlobalReduxState) => state.accountsToken);
    const activeSubscriptionInfo = useSelector((state: OmniAccounts.GlobalReduxState) => state.activeSubscriptionInfo);
    const purchasesData = useSelector((state: OmniAccounts.GlobalReduxState) => state.purchasesData);
    const customerInfo = useSelector((state: OmniAccounts.GlobalReduxState) => state.customerInfo);
    const accountsApiURL = useSelector((state: OmniAccounts.GlobalReduxState) => state.accountsApiURL );
    const purchaseapiURL = useSelector((state: OmniAccounts.GlobalReduxState) => state.purchaseapiURL);
    const storeURL = useSelector((state: OmniAccounts.GlobalReduxState) => state.storeURL);
    const teamSeats = useSelector((state: OmniAccounts.GlobalReduxState) => state.teamSeats);
    const userInfo = useSelector((state: OmniAccounts.GlobalReduxState) => state.userInfo);
    // const supportedProductVersions = useSelector((state: OmniAccounts.GlobalReduxState) => state.supportedProductVersions);
    const managedLicenses = useSelector((state: OmniAccounts.GlobalReduxState) => state.managedLicenses);

    useEffect(() => {
        const fetchSubscriptionData = async () => {
            const custInfo = await customerInfo;
            const subscriptionDataResponse =  custInfo && await getSubscriptionData({ accountsToken, customerID: customerInfo.id, purchaseapiURL });
            
            if (subscriptionDataResponse) {
                dispatch({
                    subscriptions: subscriptionDataResponse,
                    type: ActionType.SAVE_JUST_SUBSCRIPTION_INFO,
                })
            }
        }
        fetchSubscriptionData();
    }, [customerInfo]);

    useEffect(() => {
        getSeatData(accountsApiURL, accountsToken)
            .then((response) => dispatch({ type: ActionType.SAVE_TEAM_SEATS, value: response.seats }))
            .catch((error) => console.log('Error when saving team seats', error) );
    }, [])

    const seatSubtext = (
        <>
            <p>
                These are the applications this account has access to. In-app
                purchases registered to this account will also show up here.
            </p>
            <p>To activate, sign in to the app.</p>
        </>
    );
    
    if (!userInfo) {
        return (
            <HoldingBox sectionTitle='Seats'>
                <List />
            </HoldingBox>
        );
    }

    
    if (!managedLicenses) {
        return null;
    }

    if (!teamSeats || !teamSeats[0] || !purchasesData) {
        return null;
    }
    
    if (!managedLicenses && (!teamSeats || !teamSeats[0])) {
        return (
            <HoldingBox 
                sectionTitle='App Access' 
                subtext={seatSubtext}
            >
                <SectionSubtext>You don’t have any licenses or subscriptions.</SectionSubtext>
                <p>
                    New purchases can be made through the{" "}
                    <a href={storeURL}>Omni Store</a>.
                </p>
                <p>
                    If you have a subscription purchased through Apple, 
                    it can be linked to this account by {" "}
                    <a href="https://support.omnigroup.com/omni-accounts/#iap">registering</a>
                    {' '} it through the application.
                </p>
            </HoldingBox>
        )
    }

    const teamSeatFilter = (teamSeats: Array<OmniAccounts.TeamSeatObject>, productID: Array<number>) => {
        const teamSeatsCopy = teamSeats.slice();
        productID.forEach((id) => {
            teamSeatsCopy.forEach((seat, genericKey) => {
                if (seat.registration_id === id) {
                        teamSeatsCopy.splice(genericKey, 1)
                }
            })
        })
        return teamSeatsCopy
    };

    const iAPSubscriptionSeats = purchasesData.filter((subscription) => !subscription.product_id?.includes('TRIAL') && subscription.product_id?.includes('SUB'));
    //MAS = mac app-store purchase, IT = ios app-store purchase
    const purchasedSeats = managedLicenses.filter((license) => !license.product_id.includes('TRIAL'));
    const inAppIOSPurchases = purchasedSeats.filter((license) => 
        license.product_id.includes('MAS') 
            || license.product_id.includes('IT') 
            || license.product_id.includes('IAP')
            || license.product_id.includes('LEGACY')
    );
    const inAppIDs = inAppIOSPurchases.map((app) => app.id);
    
    const filteredTeamSeats = teamSeatFilter(teamSeats, inAppIDs);  //separates ios/os purchases from subs and licenses
    const purchasedTeamSeats = filteredTeamSeats.filter((seat) => seat.purchase_type !== 'trial');
    const invitedSeats = purchasedTeamSeats.filter((seat) => seat.owners[0] !== userInfo.email);
    const teamSeatIDs = purchasedTeamSeats.map((seat) => seat.registration_id);

    const filterLicenses = () => {
        const licenses: Array<OmniAccounts.LicenseObject> = [];
        teamSeatIDs.forEach((id) => {
            purchasedSeats.forEach((seat) => {
                if (seat.id === id) {
                    licenses.push(seat)
                }
            })
        })
        return licenses;
    };

    const filteredLicenses = filterLicenses(); //team seats using managedLicense info
    const licenses = filteredLicenses.filter((license) => !license.description.includes('Subscription'));
    const subscriptions = filteredLicenses.filter((license) => license.description.includes('Subscription'));

    const initalSubObj: { [key:string]: OmniAccounts.LicenseObject} = {};
    const purchasedSubscriptionObject = subscriptions.reduce((prev, curr) => {
        initalSubObj[curr.subscription_id] = curr;
        return initalSubObj;
    }, initalSubObj);

    const modifySubscription = (subscriptionMenuPortal) ? (
        <Portal 
            modifySubscription={subscriptionMenuPortal}
        >
            <ModifySubscriptionPortal 
                subscription={currentSubscription as Stripe.Subscription}
                subscriptionType={subscriptionType as 'month' | 'year'}
                toggleModal={setToggleSubscriptionMenuPortal}
                teamId={teamIDtoEdit}
            />
        </Portal> 
    ) : null;

    
    const ressignSeatModal = (assignSeatPortal) ? (
        <Portal
            assignSeat={assignSeatPortal}
        > 
            <ReassignSeatPortal 
                toggleModal={setToggleAssignSeatPortal}
                teamId={teamIDtoEdit}
            />
        </Portal>
    ) : null;

    if (loading) {
        return <List/>
    }

    return (
        <HoldingBox sectionTitle='App Access' subtext={seatSubtext}>
            { teamSeats.length > 0 &&  (
                <SeatsHolder>
                    <SectionSubtext>
                        Seats your account has been granted access to. To
                        activate, sign in to the app.
                    </SectionSubtext>
                    {inAppIOSPurchases.map((prod, genericKey) => (
                        <ProductWrapper key={genericKey}>
                            <ProductContainer>
                                <Product>
                                    <ProductName>{prod.description}</ProductName>
                                </Product>
                            </ProductContainer>
                        </ProductWrapper>
                    ))}
                    {licenses.map((seat, genericKey) => (
                        <ProductWrapper key={genericKey}>
                            <ProductContainer>
                                <Product>
                                    <ProductName>{seat.description}</ProductName>
                                </Product>
                            </ProductContainer>
                            {seat.total_seats === 1 && (
                                <ModalLink onClick={() => {
                                    setTeamIdtoEdit(seat.id)
                                    setToggleAssignSeatPortal(!assignSeatPortal)}
                                }>
                                    Reassign Seat
                                </ModalLink>
                            )}
                        </ProductWrapper>
                    ))}
                    { invitedSeats.map((subscription, genericKey) => (
                        <ProductWrapper key={genericKey}>
                            <ProductContainer>
                                <Product>
                                    <ProductName>{subscription.description}</ProductName>
                                </Product>
                            </ProductContainer>
                            {subscription.owners[0] !== userInfo.email && (
                                <div style={{ color: '#8D8D8D', fontSize: '14px' }}>
                                    <div>
                                        <span>Team Manager: </span>
                                        <a href={`mailto:${subscription.owners[0]}`}>
                                            {subscription.owners[0]}
                                        </a>
                                    </div>
                                </div>
                            )}
                        </ProductWrapper>
                    ))}
                    {iAPSubscriptionSeats.map((subscription) => {
                        return (
                            <ProductWrapper key={subscription.registration_id}>
                                <ProductContainer>
                                    <Product>
                                        <ProductName>{subscription.description}</ProductName>
                                    </Product>
                                </ProductContainer>
                            </ProductWrapper>
                        )
                    })}

                    { activeSubscriptionInfo?.map((subscription, genericKey) => {
                        const subID = subscription.id;
                        
                        const subscriptionType = subscription.items.data[0].plan.interval;
                        const subscriptionQuantity = subscription.items.data[0].quantity;
                        const cancelPeriod = subscription.cancel_at_period_end;

                        if (purchasedSubscriptionObject[subID]) {
                            return (
                                <ProductWrapper key={genericKey}>
                                    <ProductContainer>
                                        <Product>
                                            <ProductName>{purchasedSubscriptionObject[subID].description}</ProductName>
                                        </Product>
                                    </ProductContainer>
                                    <>
                                        <BillingDate>
                                            {cancelPeriod ? 'Expires:' : 'Next Billing Date:'}
                                            {' '}
                                            {giveMeEpochTimeAsTime(subscription.current_period_end)}
                                        </BillingDate>
                                    </> 
                                    {subscriptionQuantity === 1 && (
                                        <>
                                            { cancelPeriod ? (
                                                <AutoRenewPortal 
                                                    subscription={subscription}
                                                    toggleModal={setToggleRenewPortal}
                                                    autoRenewPortal={autoRenewPortal}
                                                />
                                                ) : (
                                                    <ModalLink onClick={() => {
                                                        setCurrentSubscription(subscription)
                                                        setSubscriptionType(subscriptionType)
                                                        setToggleSubscriptionMenuPortal(!subscriptionMenuPortal)
                                                        toggleMenu(!subscriptionMenu)
                                                        setTeamIdtoEdit(purchasedSubscriptionObject[subID].id)
                                                    }}>
                                                        Modify Subscription
                                                    </ModalLink>
                                            )}
                                            <span>
                                                <ModalLink onClick={() => {
                                                    setTeamIdtoEdit(purchasedSubscriptionObject[subID].id)
                                                    setToggleAssignSeatPortal(!assignSeatPortal)}
                                                }>
                                                    Reassign Seat
                                                </ModalLink>
                                            </span>
                                        </>
                                    )}
                                </ProductWrapper>
                        
                            )}
                        })}                 
                </SeatsHolder>                                        
            )}
            {ressignSeatModal}
            {modifySubscription}
        </HoldingBox>
    );
};

export default Seats;