import * as React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { List } from 'react-content-loader';
import { getSubscription } from '../utilities/getSubscription';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import Stripe from 'stripe';
import { ActionType } from '../store/types';

const License = styled.li`
    font-size: 14px;
    list-style-type: none;
    line-height: 1.3em;
    padding-left: 20px;
    border-left: 2px solid #404040;
    margin: 0 10px 20px 0;
`;

const RenewalDate = styled.div`
    font-size: 14px;
    margin-bottom: 10px;
`;

const SeatInfo = styled.div`
    font-size: 14px;
`;

interface ManagedSubscriptionI {
    customerID: string;
    key: React.Key;
    accountsToken: string;
    license: OmniAccounts.LicenseObject;
}

const ManagedSubscription: React.FC<ManagedSubscriptionI> = (props: ManagedSubscriptionI) => {
    const { useEffect, useState } = React;
    const dispatch = useDispatch();
    const [subscriptionInfo, setSubscriptionInfo] = useState<null | Stripe.Subscription>(null);
    const purchaseapiURL = useSelector((state: OmniAccounts.GlobalReduxState) => state.purchaseapiURL);
    const teamManager = useSelector((state: OmniAccounts.GlobalReduxState) => state.teamManager);

    useEffect(() => {
        getSubscription({
            accountsToken: props.accountsToken,
            customer_id: props.customerID,
            purchaseapiURL: purchaseapiURL,
            subscription_id: props.license.subscription_id,
        }).then((result) => {
            if (result.subscription) {
                setSubscriptionInfo(result.subscription);
            }
        });
    }, [
        props.accountsToken,
        props.customerID,
        props.license.subscription_id,
        purchaseapiURL,
    ]);

    if (subscriptionInfo === null) {
        return <List />;
    }

    return (
        <License key={props.license.id}>
            <h3>{props.license.description.split(' Subscription')[0]}</h3>
            <RenewalDate>
                {props.license.subscription_state === 'inactive' ||
                props.license.cancel_at_period_end === true
                    ? 'Expires '
                    : 'Renews '}
                {dayjs(props.license.expiration_date).format('MMMM DD, YYYY')}
            </RenewalDate>
            <SeatInfo>
                Total seats:{' '}
                {
                    subscriptionInfo.items.data[0].quantity !== undefined && subscriptionInfo.items.data[0].quantity > props.license.total_seats
                    ? 
                    subscriptionInfo.items.data[0].quantity
                    : 
                    props.license.total_seats
                }
            </SeatInfo>
            <SeatInfo>Unused seats: {props.license.available_seats}</SeatInfo>
            {teamManager && (
                <Link
                    onClick={() => {
                        sessionStorage.setItem('teameditid', props.license.id.toString());
                        dispatch({ type: ActionType.SET_TEAM_ID_TO_EDIT, value: props.license.id.toString() });
                    }}
                    style={{
                        marginRight: 'auto',
                    }}
                    to={{
                        pathname: '/manage-team',
                    }}
                >
                    Manage Subscription
                </Link>
            )}
        </License>
    );
};

export default ManagedSubscription;
