import Stripe from 'stripe';

interface GetSubscriptionProps {
    accountsToken: string;
    customer_id: string;
    purchaseapiURL: string;
    subscription_id: string;
}

interface GetSubscriptionResponseI {
    subscription: Stripe.Subscription | undefined;
    errors: string | undefined;
}

export const getSubscription = async ({
    customer_id,
    purchaseapiURL,
    accountsToken,
    subscription_id,
}: GetSubscriptionProps): Promise<GetSubscriptionResponseI> => {
    return await new Promise((resolve, reject) => {
        fetch(
            `${purchaseapiURL}/api/v2/customer/${customer_id}/subscription/${subscription_id}`,
            {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${accountsToken}`,
                    'Content-Type': 'application/json',
                },
                method: 'GET',
                mode: 'cors',
            }
        )
            .then((response) => {
                return Promise.resolve(response.json()).then((res) => {
                    if (!res.errors) {
                        resolve({ errors: undefined, subscription: res });
                    } else if (res.errors) {
                        console.log(res.errors);
                        return reject(true);
                    }
                    return reject(true);
                });
            })
            .catch((err) => {
                console.log('err', err);
                return reject();
            });
    });
};
