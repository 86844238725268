export interface TeamSeatPostI {
    accountsApiURL: string;
    accountsToken: string;
    currentPlanID: number;
    release?: string;
    renew?: string;
}

interface TeamSeatPostReturnI {
    status: string;
    seats: Array<{
        id: string;
        username: string;
        email: string;
        omni_id: string;
        status: string;
    }>;
}

const postTeamSeatModification = async (
    props: TeamSeatPostI
): Promise<TeamSeatPostReturnI> => {
    return await new Promise((resolve, reject) => {
        fetch(
            `${props.accountsApiURL}/api/1.1/registration_seats/${props.currentPlanID}/`,
            {
                body: JSON.stringify({
                    release: props.release,
                    renew: props.renew,
                }),
                headers: {
                    Authorization: 'Bearer ' + props.accountsToken,
                    'Content-Type': 'application/json; charset=utf-8',
                },
                method: 'POST',
                mode: 'cors',
            }
        )
            .then((response) => {
                return resolve(response.json());
            })
            .catch((err) => {
                return reject(err);
            });
    });
};

export default postTeamSeatModification;
