import * as React from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';

import { ActionType } from '../store/types';

const LogOutElement = styled.div`
    text-align: left;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 20px;
    color: #fff;

    @media (max-width: 600px) {
        margin: auto;
        width: initial;
    }
`;

const AccountName = styled.div`
    margin: 0 20px;
`;

const Signout = styled.a`
    margin: 0 5px 0 20px;
    color: #fff;
    cursor: pointer;
    text-decoration: underline;
    min-width: fit-content;
`;

const Admin = styled.a`
    margin: 0 20px;
    color: #fff;
    cursor: pointer;
    text-decoration: underline;
`;

const Logout: React.FC = () => {
    const dispatch = useDispatch();

    const accountsApiURL = useSelector(
        (state: OmniAccounts.GlobalReduxState) => state.accountsApiURL
    );
    const userInfo = useSelector((state: OmniAccounts.GlobalReduxState) => state.userInfo);
    const accountsToken = useSelector(
        (state: OmniAccounts.GlobalReduxState) => state.accountsToken
    );
    const signedInUserName = useSelector(
        (state: OmniAccounts.GlobalReduxState) => state.signedInUserName
    );

    const handleSignout = async () => {
        await fetch(`${accountsApiURL}/api/1.1/signout/`, {
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + accountsToken,
                'Content-Type': 'application/json',
            },
            method: 'GET',
            mode: 'cors',
        })
            .then((response) => {
                return response.json();
            })
            .catch((err) => {
                console.log('err', err);
                return err;
            });

        return;
    };

    const handleSubmit = async (e: React.SyntheticEvent) => {
        e.preventDefault();

        await handleSignout().then(() => {
            dispatch({ type: ActionType.USER_LOGGED_OUT });
            localStorage.clear();
            window.location.href = '/';
        });
    };

    return (
        <LogOutElement>
            <AccountName>{signedInUserName}</AccountName>
            <span>|</span>
            {userInfo !== null && userInfo.staff && (
                <React.Fragment>
                    <Admin href={`${accountsApiURL}/admin/`}>Admin</Admin>
                    <span>|</span>
                </React.Fragment>
            )}
            <Signout onClick={(e) => handleSubmit(e)}>Sign out</Signout>
        </LogOutElement>
    );
};

export default Logout;
