import React, { useState } from 'react';
import { List } from 'react-content-loader';
import { useSelector, useDispatch } from 'react-redux';
import Stripe from 'stripe';
import styled from 'styled-components';

import { ActionType } from '../../store/types';
import { useTheme, useThemeUpdate } from './ThemeContext';
import { Button } from '../../reusable-components/Button';

import getSubscriptionData from '../../utilities/getSubscriptionData';
import { giveMeEpochTimeAsTime } from '../../utilities/givemetime';
import { postUpdateSubscription } from '../../utilities/postUpdateSubscription';

const ConfirmCancel = styled.p`
    padding-left: 0;
    font-size: 13px;
    width: 500px;
`;

const Buttons = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-weight: 400;
`;

const ModalLink = styled.a`
    background-color: transparent;
    border: none;
    outline: none;
    text-decoration: underline;
    font-size: 14px;
    cursor: pointer;
    padding-right: 10px;

    font-style: italic;
    color: rgb(58, 159, 242); /* light blue */
`;

interface AutoRenewalProps {
    subscription: Stripe.Subscription;
    toggleModal: (active: boolean) => void;
    autoRenewPortal: boolean;
}

const AutoRenewPortal: React.FC<AutoRenewalProps> = (props) => {
    const dispatch = useDispatch();
    const { currentSubscription } = useTheme();
    const { setError, setCurrentSubscription } = useThemeUpdate();

    const [loading, setLoading] = useState<boolean>(false);
    const accountsToken = useSelector((state: OmniAccounts.GlobalReduxState) => state.accountsToken);
    const customerInfo = useSelector((state: OmniAccounts.GlobalReduxState) => state.customerInfo);
    const paymentMethodsOnFile = useSelector((state: OmniAccounts.GlobalReduxState) => state.paymentMethodsOnFile);
    const purchaseapiURL = useSelector((state: OmniAccounts.GlobalReduxState) => state.purchaseapiURL);

    const resurrectDeadSubscription = async (subscriptionID: string) => {
        setLoading(true);
        if (!customerInfo) return;

        await postUpdateSubscription({
            accountsToken,
            cancel_at_period_end: false,
            customerID: customerInfo.id,
            purchaseapiURL,
            subscriptionID,
        })
        .then(() => {
            getSubscriptionData({
                accountsToken,
                customerID: customerInfo.id,
                purchaseapiURL,
            })
            .then((response) => {
                dispatch({
                    subscriptions: response,
                type: ActionType.SAVE_JUST_SUBSCRIPTION_INFO,
            })
        })
            .catch((error) => {
                console.error(error);
                setError('Unable to turn on auto-renewal for this subscription. Please contact sales.')
            })
        });
        setLoading(false);
        props.toggleModal(false);
    }

    if (loading) {
        return <List/>
    }

    const autoRenewPopup = (
        <React.Fragment>
            <ConfirmCancel>
                Are you sure you want to turn on auto-renewal?{' '}
                { paymentMethodsOnFile.length > 0 ? (
                    <span>
                        Your credit card will be automatically
                        charged on{' '}
                        {giveMeEpochTimeAsTime(props.subscription.current_period_end)}.
                    </span>
                ) : (
                    <span>
                        You’ll need to add another card in the
                        Payment section.
                    </span>
                )}
            </ConfirmCancel>
            <Buttons>
                <Button
                    someMargin={true}
                    onClick={() => props.toggleModal(false)}
                >
                    Cancel
                </Button>
                <Button
                    someMargin={true}
                    onClick={() => resurrectDeadSubscription(props.subscription.id)}
                >
                    Confirm!
                </Button>
            </Buttons>
        </React.Fragment>
    );
    const subscriptionProductID = props.subscription.items.data[0].id;
    const currentSubProductID = currentSubscription?.items.data[0].id;
    const currentProduct = currentSubProductID === subscriptionProductID;

    return (
        <div>
            { props.autoRenewPortal && currentProduct ? (
                <div>{autoRenewPopup}</div>
            ) : (
                <ModalLink
                    onClick={() => {
                        setCurrentSubscription(props.subscription);
                        props.toggleModal(true)
                    }}
                >
                    Turn on Auto-Renewal
                </ModalLink>
            )}
        </div>
    )
};

export default AutoRenewPortal;
