import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { List } from 'react-content-loader';
import styled from 'styled-components';

import UpgradeSuccess from './UpgradeSuccess';
import EditUpgrades from './EditUpgrades';

import postActivateUpgrades from '../../utilities/postActivateUpgrades';

const Wrapper = styled.div`
    padding: 20px;
`;

const Content = styled.div`
    color: rgb(64, 64, 64);

    > p {
        border-top: 1px solid rgb(212, 207, 199);
        padding: 20px 0;
    }

    > span {
        font-size: 12px;
    }
`;

const Header= styled.h3`
    font-size: 36px;
    font-weight: 700;
`;

const BottomPortion = styled.div`
    font-size: 14px;
    padding: 10px 0;
`;

const RemainingUpgrades = styled.div`
    display: flex;
    flex-direction: row;

    > div {
        padding: 10px 0;
    }

    > a {
        padding: 10px 20px;
    }
`;

const ButtonContainer = styled.div`
    float: right;
    margin: 30px 0;

    .upgrade {
        border-color: none;
        background-color: black;
        color: white;

        &:disabled {
            background-color: rgb(212, 207, 199);
        }
    }
`;

const SeatSelectionErrorMessage = styled.div`
    color: #ff0000;
    text-align: right;
    padding: 10px 8px;
`;


const Button = styled.button`
    padding: 8px 30px;
    margin: 0 10px;
    background: none;
    border: 1px solid rgb(212, 207, 199);
    border-radius: 8px;

    &:hover {
        cursor: pointer;
    }
`;

interface LocationState {
    from:  OmniAccounts.RegistrationObj;
}

const ManageUpgrade: React.FC = () => {  
    const navigate = useNavigate();
    
    const location = useLocation();
    const location_state = location.state as LocationState;

    const [numberOfSeatsSelected, setNumberOfSeatsSelected] = React.useState<number>(0);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [error, setError] = React.useState<null | string>(null);
    const [postUpgradeMessage, setUpgradeMessage] = React.useState<null | string>(null);
    const [selectedSeats, setSelectedSeats] = React.useState<Array<OmniAccounts.SeatRegistrationInfo>>([]);
    const [unassignedSeats, setUnassignedSeats] = React.useState<number>(0);

    const accountsApiURL = useSelector((state: OmniAccounts.GlobalReduxState) => state.accountsApiURL );
    const accountsToken = useSelector((state: OmniAccounts.GlobalReduxState) => state.accountsToken);

    React.useEffect(() => {
        if (location_state === null) {
            navigate("/")
        }
    }, [location_state])

    if (location_state === null) {
        return null;
    }

    const { 
        registration_id,
        upgrade_name,
        upgrades_available,
    } = location_state.from;

    const disableUpgrades = (numberOfSeatsSelected + unassignedSeats) !== upgrades_available;
    
    const upgradeSelectedSeats = () => {
        setLoading(true);
        const seats = selectedSeats.map((seat) => seat.id);

        postActivateUpgrades({
            accountsApiURL, 
            accountsToken,
            registration_id,
            seats,
            unassigned: unassignedSeats,
        })
            .then((response) => {
                if (response.status === 'success') {
                    const totalSeats = response.seats_moved! + response.unassigned!;
                    setUpgradeMessage(`All ${totalSeats} seats have been successfully upgraded.`);
                    setLoading(false);
                    return;
                } else if (response.status === 'error') {
                    setError('There was an error upgrading seats.')
                    setLoading(false);
                    return;
                }
                return;
            })
            .catch(error => {
                setError(error);
                console.log('Error upgrading selected seats: ', error)
                setLoading(false);
                return;
            });
        setLoading(false);
    }

    React.useEffect(() => {
        if (error || postUpgradeMessage) {
            setLoading(false);
        }
    })
    
    if (error !== null || postUpgradeMessage !== null) {
        return (
            <UpgradeSuccess
            errorMessage={error}
            successfulUpgradeMessage={postUpgradeMessage}
            productInfo={location_state.from}
            />
        )
    }

    if (loading) {
        return <List/>
    } 

    return (
        <Wrapper>
            <Helmet>
                <title>Manage Your Omni Upgrades - The Omni Group</title>
            </Helmet>
            <Content>
                <Header>Select seats to upgrade to {upgrade_name} </Header>
                <span>Upgrades available: {upgrades_available}</span>
                
                <p>
                    Selected seats will be moved to a different team for the upgraded license.{" "}
                    Your licenses will unlock the major version it was purchased for and all prior major versions of the app that support Omni Accounts so users 
                    do not need to immediately upgrade their software.
                </p>
                <EditUpgrades
                    selectedSeats={selectedSeats}
                    setNumberOfSeatsSelected={setNumberOfSeatsSelected}
                    setNumberOfUnassignedSeats={setUnassignedSeats}
                    setSelectedSeats={setSelectedSeats}
                    unassignedSeats={unassignedSeats}
                    upgradeInformation={location_state.from}
                />
                <BottomPortion>
                    <RemainingUpgrades>
                        <div>Remaining upgrades available: {upgrades_available - numberOfSeatsSelected - unassignedSeats}</div>
                        <a href={`https://store.omnigroup.com/`}>Purchase more upgrades</a>
                    </RemainingUpgrades>
                    <ButtonContainer>
                        {numberOfSeatsSelected + unassignedSeats > upgrades_available && (
                            <SeatSelectionErrorMessage>Too many seats selected.</SeatSelectionErrorMessage>
                        )}
                        <Link to="/">
                            <Button>Cancel</Button>
                        </Link>
                        <Button 
                            className="upgrade" 
                            disabled={disableUpgrades}
                            onClick={upgradeSelectedSeats}
                        >
                            Upgrade
                        </Button>
                    </ButtonContainer>
                </BottomPortion> 
            </Content> 
        </Wrapper>
    )
}

export default ManageUpgrade;