import styled from 'styled-components';
import { Helmet } from 'react-helmet';

const FourHolder = styled.div`
    max-width: 800px;
    margin: 30px auto;
    padding: 0 10px;
    text-align: center;
`;

const FourOhFour: React.FC = () => {
    return (
        <FourHolder>
            <Helmet>
                <title>404 - Omni Accounts</title>
            </Helmet>

            <h1>404</h1>
            <h2>Page Not Found</h2>
            <p>
                <a href='/'>Back to Accounts.</a>
            </p>
        </FourHolder>
    );
};

export default FourOhFour;
