import React, { useEffect, useRef } from 'react';
import * as ReactDOM from 'react-dom';

interface PortalProps {
    children: JSX.Element;
    assignSeat?: boolean;
    modifySubscription?: boolean;
}

const portalRoot: HTMLElement | null = document.getElementById('portal-root');

const Portal: React.FC<PortalProps> = ({ assignSeat, modifySubscription, children }) => {
    const rootElement = useRef(document.createElement('div'));

    if (assignSeat){
        rootElement.current.classList.add('assign-seat');
    }

    if (modifySubscription) {
        rootElement.current.classList.add('modify-subscription');
    }

    useEffect(() => {
        const currentElement = rootElement.current;
        portalRoot?.appendChild(currentElement);

        return () => {
            portalRoot?.removeChild(currentElement);
        }
    }, []);

    return ReactDOM.createPortal(children, rootElement.current)
};

export default Portal;