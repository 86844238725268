export interface SeatListReturnType {
    status: string;
    seats: Array<{
        username: null | string;
        email: string;
        short_url: string;
        status: string;
    }>;
}

const grabListOfSeatsForID = async (
    accountsApiURL: string,
    currentPlanID: number,
    accountsToken: string
): Promise<SeatListReturnType> => {
    return await fetch(
        `${accountsApiURL}/api/1.1/registration_seats/${currentPlanID}/`,
        {
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + accountsToken,
                'Content-Type': 'application/json',
            },
            method: 'GET',
            mode: 'cors',
        }
    )
        .then((response) => {
            return response.json();
        })
        .then((response) => {
            return response;
        })
        .catch((err) => {
            return err;
        });
};

export default grabListOfSeatsForID;
