import * as React from 'react';
import styled from 'styled-components';

const FooterWrap = styled.div`
    display: flex;
    padding-top: 100px;
    text-align: center;
`;

const Footer = styled.div`
    max-width: 640px;
    margin: auto;
    padding: 0 20px 0 20px;

    > div:nth-child(n) {
        margin: 20px auto;
    }
`;

const FooterLinks = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 200px;
    margin: auto;

    > a {
        color: #404040;
    }
`;

const Copyright = styled.div``;

const OmniLogo = styled.div`
    width: 100px;
    margin: auto;
`;

const FooterWrapper: React.FC = () => {
    return (
        <FooterWrap>
            <Footer>
                <OmniLogo>
                    <a href='https://www.omnigroup.com/'>
                        <svg
                            id='omnibw'
                            xmlns='http://www.w3.org/2000/svg'
                            viewBox='0 0 583.08 161.25'
                        >
                            <title>The Omni Group Logo</title>
                            <path
                                fill='#4a4a49'
                                d='M106.33,30.84a80.33,80.33,0,1,0,80.33,80.33A80.33,80.33,0,0,0,106.33,30.84Zm222.23,0H224.33a18.58,18.58,0,0,0-18.53,21.09V171a18.58,18.58,0,0,0,18.53,21.09H328.56a18.7,18.7,0,0,0,18.7-18.7V49.15h0A18.69,18.69,0,0,0,328.56,30.84Zm168.59,0h-97.3a9.48,9.48,0,0,0-6.35,16.53L499.32,153.19a9.48,9.48,0,0,0,16.53-6.35V48h-0.08A18.69,18.69,0,0,0,497.15,30.84Zm93.24,0H560.73A18.7,18.7,0,0,0,542,49.54V173.4a18.7,18.7,0,0,0,18.7,18.7h29.66a18.7,18.7,0,0,0,18.7-18.7V49.54A18.7,18.7,0,0,0,590.39,30.84ZM496.1,175.56L390.28,69.74a9.48,9.48,0,0,0-16.53,6.35V175h0.08a18.69,18.69,0,0,0,18.62,17.12h97.3A9.48,9.48,0,0,0,496.1,175.56Z'
                                transform='translate(-26 -30.84)'
                            ></path>
                        </svg>
                    </a>
                </OmniLogo>
                <FooterLinks>
                    <a href='mailto:accounts@omnigroup.com'>Contact</a>
                    <a
                        href='https://www.omnigroup.com/legal/'
                        title='The Omni Group legal page.'
                    >
                        Legal
                    </a>
                </FooterLinks>
                <Copyright>
                    <span>© 1994–2024 The&nbsp;Omni&nbsp;Group</span>
                </Copyright>
            </Footer>
        </FooterWrap>
    );
};

export default FooterWrapper;
