import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Stripe from 'stripe';
import styled from 'styled-components';

import { ActionType } from '../../store/types';
import { useTheme, useThemeUpdate } from './ThemeContext';
import { ButtonNeuComp } from '../../reusable-components/Button';

import { giveMeEpochTimeAsTime } from '../../utilities/givemetime';
import { detachPaymentMethod } from '../../utilities/detachPaymentMethod';
import getSubscriptionData from '../../utilities/getSubscriptionData';

const ModalWrapper = styled.div`
    height: 100vh;
    width: 100vw;
    top: 0;
    position: fixed;
    background-color: rgba(0,0,0,0.35);
    overflow: hidden;
`;

const Modal = styled.div`
    position: absolute;
    inset: 50% auto auto 50%;
    border: 0px;
    background: rgb(255, 255, 255);
    overflow: auto;
    border-radius: 6px;
    outline: none;
    padding: 30px;
    box-shadow: rgb(0 0 0 / 27%) 2px 2px 5px;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    max-width: 550px;
    min-width: 300px;
`;

const DeletePaymentCheckbox = styled.div`
    margin-bottom: 10px;

     > input {
        padding: 0 10px;
        width: auto;
        cursor: pointer;
    }
    > label {
        margin: 0 20px;
        font-weight: 600;
        font-size: 16px;
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-weight: 400; 
    
    > button {
        white-space: nowrap;
        cursor: pointer;
        :last-child {
            background-color: rgba(219, 77, 64, 0.95);
            color: #fff;
            border: none;
        }
    }
`;

interface CancelSubscriptionProps {
    subscription: Stripe.Subscription;
    toggleModal: (toggle: boolean) => void;
}

const CancelSubscriptionModal: React.FC<CancelSubscriptionProps> = (props) => {
    const dispatch = useDispatch();
    const { deleteCards } = useTheme();
    const { 
        toggleCancelModal,
        toggleMenu,
        setDeleteCards,
    } = useThemeUpdate();
    
    const [loading, setLoading] = useState<boolean>(false);

    const accountsToken = useSelector((state: OmniAccounts.GlobalReduxState) => state.accountsToken);
    const customerInfo = useSelector((state: OmniAccounts.GlobalReduxState) => state.customerInfo);
    const paymentMethodsOnFile = useSelector((state: OmniAccounts.GlobalReduxState) => state.paymentMethodsOnFile);
    const purchaseapiURL = useSelector((state: OmniAccounts.GlobalReduxState) => state.purchaseapiURL);

    const cancelSubscription = async (currentPlanID: string) => {
        setLoading(true);
        if (!customerInfo) return;

        const customerID = customerInfo.id;
        const cancellationURL = `${purchaseapiURL}/api/v2/customer/${customerInfo.id}/subscription/${currentPlanID}`;
        const cancellationJSON = await fetch(cancellationURL, 
            {
                body: JSON.stringify({ cancel_at_period_end: true }),
                headers: {
                    Accept:'application/json',
                    Authorization: 'Bearer ' + accountsToken,
                    'Content-Type': 'application/json',
                },
                method: 'POST',
                mode: 'cors',
            }
        )
        .then((response) => response.json())
        .catch((error) => console.error(error));

        if (deleteCards) {
            paymentMethodsOnFile.map((card) => {
                detachPaymentMethod(purchaseapiURL, customerID, card.id, accountsToken)
                    .then(() => dispatch({ type: ActionType.UPDATING_CARD }))
                    .catch((error) => console.error(error));
                return true;
            });
        }

        const refreshSubscriptions = await getSubscriptionData({ accountsToken, customerID, purchaseapiURL });

        dispatch({
            subscriptions: refreshSubscriptions,
            type: ActionType.SAVE_JUST_SUBSCRIPTION_INFO
        });
        toggleCancelModal(false);
        setLoading(false);
        props.toggleModal(false);
        return cancellationJSON;
    }
    
    const currentEndPeriod = props.subscription.current_period_end;
    const subscriptionID = props.subscription.id;

    return (
        <ModalWrapper>
            <Modal>
                <h2>Confirm Cancellation</h2>
                <p>
                    Are you sure you want to turn off auto-renewal?{' '}
                    You will not be billed again, but you will
                    continue to have access until{' '}
                    {giveMeEpochTimeAsTime(currentEndPeriod)}.
                </p>
                { paymentMethodsOnFile.length > 0 && (
                    <DeletePaymentCheckbox>
                        <input type="checkbox" onChange={() => setDeleteCards(true)} />
                        <label>Delete saved credit cards.</label>
                    </DeletePaymentCheckbox>
                )}
                <ButtonContainer>
                    <ButtonNeuComp 
                        primary 
                        onClick={() => {
                            toggleCancelModal(false)
                            toggleMenu(true)
                        }}
                    >
                        Don&apos;t Cancel Subscription
                    </ButtonNeuComp>
                    <ButtonNeuComp 
                        showLoading={loading}
                        primary={false}
                        onClick={() => cancelSubscription(subscriptionID)}
                    >
                        Cancel Subscription
                    </ButtonNeuComp>
                </ButtonContainer>
            </Modal>
        </ModalWrapper>
    ) 
}

export default CancelSubscriptionModal;