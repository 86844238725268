import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { ActionType } from '../../store/types';
import grabListOfSeatsForID from '../../utilities/grabListOfSeatsForID';

import TableRow from './TableRow';
import addListOfEmails from './utilities/addEmails';

const Table = styled.div``;

const EmailInput = styled.input`
    grid-column-start: 2;
    grid-column-end: 4;

    border: 1px solid #909090;
    border-radius: 4px;
    margin: 0;
    font-size: 14px;
    width: 75%;
    padding: 4px 8px;
    font-style: italic;
`;

const AddEmail = styled.form`
    display: grid;
    grid-template-columns: 50px 150px 300px auto;
    padding: 5px 0;
    font-size: 15px;
    width: 100%;
    opacity: 0.5;
    align-items: center;
    &:hover {
        background-color: rgba(211, 230, 249, 1);
        opacity: 1;
    }
`;

const EmptyTable = styled.div`
    font-size: 20px;
    margin: 20px;
    background-color: #f2f2f2;
    padding: 20px;
    text-align: center;
    border-radius: 4px;

    > p {
        max-width: 500px;
        margin: auto;
    }
`;

const UserHeader = styled.h4`
    grid-column-start: 2;
    grid-column-end: 3;
    margin: 5px 0;
`;

const EmailHeader = styled.h4`
    grid-column-start: 3;
    grid-column-end: 4;
    margin: 5px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const StatusHeader = styled.h4`
    grid-column-start: 4;
    grid-column-end: 5;
    margin: 5px 0;
    text-align: left;
`;

const TableHeader = styled.div`
    margin: 0;
    display: grid;
    grid-template-columns: 50px 150px 300px auto;
    align-items: center;
`;

const TableRows = styled.div`
    display: flex;
    flex-flow: column;
    align-items: center;
    margin: 0;
    padding: 5px 0;
    border: 1px solid rgba(212, 207, 199, 1);
    justify-content: space-between;
`;

const FilterBox = styled.input`
    margin: 0 auto 0 4px;
    width: 100px;
    padding: 2px 8px;
    border-radius: 0;
    font-size: 12px;
    border: 1px solid rgba(0, 0, 0, 0);
    transition: width 0.5s;

    &::placeholder {
        text-decoration: underline;
    }

    &:focus {
        border: 1px solid #909090;
        width: 200px;

        &::placeholder {
            text-decoration: none;
        }
    }
`;

interface SeatTableI {
    seatList: Array<OmniAccounts.SeatObject>;
    currentObject: OmniAccounts.LicenseObject;
    currentlyEditingID: number;
    subscription: boolean;
}

const SeatTable: React.FC<SeatTableI> = (props: SeatTableI) => {
    const dispatch = useDispatch();

    const [email, setEmail] = React.useState<string>('');
    const [emailErrorString, setEmailErrorString] = React.useState<string>('');
    const [selectEveryRow, setSelectEveryRow] = React.useState<boolean>(false);
    const [rowFilter, setRowFilter] = React.useState<string>('');
    const accountsApiURL = useSelector(
        (state: OmniAccounts.GlobalReduxState) => state.accountsApiURL
    );
    const accountsToken = useSelector(
        (state: OmniAccounts.GlobalReduxState) => state.accountsToken
    );

    const teamPageIDToEdit = useSelector(
        (state: OmniAccounts.GlobalReduxState) => state.teamPageIDToEdit
    );

    const checkedSeatsForDeletion = useSelector(
        (state: OmniAccounts.GlobalReduxState) => state.checkedSeatsForDeletion
    );

    React.useEffect(() => {
        if (checkedSeatsForDeletion.length === 0) {
            setSelectEveryRow(false);
        }
    }, [checkedSeatsForDeletion]);

    React.useEffect(() => {
        if (props.seatList === undefined) return;
        if (selectEveryRow) {
            props.seatList.map((oneSeat: OmniAccounts.SeatObject) => {
                dispatch({
                    type: ActionType.ADD_SEAT_FOR_REMOVAL,
                    value: oneSeat,
                });
                return true;
            });
        } else if (!selectEveryRow && props.seatList !== undefined) {
            props.seatList.map((oneSeat: OmniAccounts.SeatObject) => {
                dispatch({
                    type: ActionType.REMOVE_SEAT_FROM_REMOVAL,
                    value: oneSeat,
                });
                return true;
            });
        }
    }, [selectEveryRow]);

    if (teamPageIDToEdit === null) {
        return null;
    }

    if (
        (props.seatList !== undefined && props.seatList.length === 0) ||
        props.seatList === undefined
    ) {
        return (
            <EmptyTable>
                <p>
                    There aren’t currently team members using any seats. You can
                    begin adding members above.
                </p>
            </EmptyTable>
        );
    }

    return (
        <Table>
            <TableHeader>
                <input
                    type='checkbox'
                    aria-label={'Select all seat checkboxes'}
                    onChange={() => setSelectEveryRow(!selectEveryRow)}
                    checked={selectEveryRow}
                    style={{
                        gridColumnEnd: '2',
                        gridColumnStart: '1',
                        margin: 'auto',
                        width: '20px',
                    }}
                />
                <UserHeader>User</UserHeader>
                <EmailHeader>
                    Email
                    <FilterBox
                        placeholder='Filter'
                        onChange={(e) => setRowFilter(e.target.value)}
                        value={rowFilter}
                        aria-label={'Input field to filter email addresses.'}
                    />
                </EmailHeader>
                <StatusHeader>Status</StatusHeader>
            </TableHeader>
            <TableRows>
                {props.seatList !== undefined &&
                    props.seatList.map((seat, generatedKey: number) => (
                        <TableRow
                            rowFilter={rowFilter}
                            rowStyle={
                                generatedKey % 2
                                    ? 'rgba(240, 240, 241, 0.5)'
                                    : '#fff'
                            }
                            user={seat}
                            key={generatedKey}
                            subscription={props.subscription}
                            currentObject={props.currentObject}
                            currentlyEditingID={props.currentObject.id}
                        />
                    ))}
                {props.currentObject.available_seats -
                    props.currentObject.invited_seats >=
                    1 &&
                    !true && (
                        <AddEmail
                            onSubmit={(e) => {
                                e.preventDefault();
                                addListOfEmails(
                                    accountsToken,
                                    accountsApiURL,
                                    props.currentObject.id,
                                    email
                                )
                                    .then((result) => {
                                        if (result.status === 'success') {
                                            setEmail('');
                                            return true;
                                        } else {
                                            return result.message;
                                        }
                                    })
                                    .then(async (result) => {
                                        if (result === true) {
                                            const updatedListOfSeats =
                                                await grabListOfSeatsForID(
                                                    accountsApiURL,
                                                    props.currentObject.id,
                                                    accountsToken
                                                );
                                            dispatch({
                                                type: ActionType.ADD_SEAT_LIST_TO_ARRAY,
                                                value: {
                                                    id: teamPageIDToEdit,
                                                    seats: updatedListOfSeats.seats.map(
                                                        (seat) => seat
                                                    ),
                                                },
                                            });
                                        } else if (typeof result === 'string') {
                                            setEmailErrorString(result);
                                        }
                                    });
                            }}
                        >
                            <label
                                style={{
                                    margin: '0 10px',
                                    textAlign: 'center',
                                }}
                                htmlFor='invite'
                            >
                                ✚
                            </label>
                            <EmailInput
                                id='singleEmail'
                                value={email}
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                }}
                                placeholder='Invite by email, hit return'
                            />
                            {emailErrorString.length > 0 && (
                                <span>{emailErrorString}</span>
                            )}
                        </AddEmail>
                    )}
                {/* the above is a more-fleshed-out row invite system */}
            </TableRows>
        </Table>
    );
};

export default SeatTable;
