interface PostConfirmEmailParams {
    accountEmail: string;
    accountsApiURL: string;
    confirmKey: string | undefined;
    signupAccessToken: string;
}


export const postConfirmEmail = async ({
    accountsApiURL,
    confirmKey,
    accountEmail,
    signupAccessToken,
}: PostConfirmEmailParams): Promise<OmniAccounts.ConfirmEmailTokenObject | undefined> => {
    const response = await fetch(`${accountsApiURL}/api/1.1/user/verify_email/`, {
        body: JSON.stringify({
            confirm_key: confirmKey,
            email_address: accountEmail,
        }),
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + signupAccessToken,
            'Content-Type': 'application/json',
        },
        method: 'POST',
        mode: 'cors',
    });
    
    try {
        return await response.json();
    } catch (error) {
        return undefined;
    }
};
