import { Fragment, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { detachPaymentMethod } from '../utilities/detachPaymentMethod';
import CheckoutForm from './CheckoutForm';
import Note from '../reusable-components/Note';
import Stripe from 'stripe';
import {ActionType } from '../store/types';
import { List } from 'react-content-loader';

const CardHolder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: flex-start;
`;

const Card = styled.li`
    display: inline-block;
    margin: 10px 0;
    transition: all 0.2s ease-in;
`;

const UpdateCard = styled.div`
    width: auto;
`;

const Cards = styled.div`
    margin: 20px 0;
`;

const DeleteMe = styled.a`
    color: red;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    margin: auto 0 auto 10px;
`;

interface CardDisplayProps {
    accountsAccessToken: string;
    customerID: string;
    editMode: boolean;
    listOfPaymentMethods: Array<Stripe.PaymentMethod>;
    purchaseAPI: string;
}

const CardDisplay: React.FC<CardDisplayProps> = (props: CardDisplayProps) => {
    const dispatch = useDispatch();

    const [deleting, setDeleting] = useState<boolean>(false);

    const cardRed = useRef(props.listOfPaymentMethods.length);

    const deleteCardHandler = (card: string) => {
        setDeleting(true);
        detachPaymentMethod(
            props.purchaseAPI,
            props.customerID,
            card,
            props.accountsAccessToken
        )
            .then(() => {
                dispatch({
                    type: ActionType.UPDATING_CARD,
                });
            })
            .then(() => {
                dispatch({ type: ActionType.UPDATING_COUNTER });
            })
            .catch((error) => console.log(error));
    };

    useEffect(() => {
        if (cardRed.current !== props.listOfPaymentMethods.length) {
            setDeleting(false);
        }
        cardRed.current = props.listOfPaymentMethods.length;
    });

    const uppercaseCard = (s: string) => {
        return s.charAt(0).toUpperCase() + s.slice(1);
    };

    const stripeKey = useSelector(
        (state: OmniAccounts.GlobalReduxState) => state.stripeKey
    );

    if (stripeKey.length === 0) {
        return <UpdateCard>Talking to Stripe...</UpdateCard>;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [stripePromise, setStripePromise] = useState(() =>
        loadStripe(stripeKey)
    );

    if (props.listOfPaymentMethods === undefined) return null;

    if (deleting) {
        return (
            <UpdateCard>
                <List />
            </UpdateCard>
        );
    }
    if (props.editMode) {
        return (
            <UpdateCard>
                {props.listOfPaymentMethods.length > 0 && (
                    <Cards>
                        <h3>Delete a Card</h3>
                        {props.listOfPaymentMethods.map((card) => {
                            return (
                                <CardHolder key={card.id}>
                                    <Card>
                                        {card.card !== undefined &&
                                            uppercaseCard(card.card.brand)}{' '}
                                        ending in{' '}
                                        {card.card !== undefined &&
                                            card.card.last4}
                                    </Card>
                                    <DeleteMe
                                        onClick={() =>
                                            deleteCardHandler(card.id)
                                        }
                                    >
                                        Delete
                                    </DeleteMe>
                                </CardHolder>
                            );
                        })}
                    </Cards>
                )}
                <h3>Add A New Payment Method</h3>
                {props.listOfPaymentMethods.length === 0 && (
                    <Note passionate>
                        Your subscription won’t be renewable without an active
                        credit card.
                    </Note>
                )}
                <Elements stripe={stripePromise}>
                    <CheckoutForm customerID={props.customerID} />
                </Elements>
            </UpdateCard>
        );
    }

    if (props.listOfPaymentMethods.length === 0) {
        return (
            <Note>
                You do not currently have any methods of payment on file.
            </Note>
        );
    }
    return (
        <Fragment>
            {props.listOfPaymentMethods.map((card) => {
                return (
                    <CardHolder key={card.id.toString()}>
                        <Card>
                            {uppercaseCard(
                                card.card !== undefined ? card.card.brand : ''
                            )}{' '}
                            ending in{' '}
                            {card.card !== undefined ? card.card.last4 : ''}
                        </Card>
                    </CardHolder>
                );
            })}
        </Fragment>
    );
};

export default CardDisplay;
