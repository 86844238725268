import styled from 'styled-components';

const TermsHolder = styled.div`
    max-width: 600px;
    margin: 20px auto;
    background-color: rgba(232, 232, 232, 1);
    height: 300px;
    padding: 20px;
    overflow: scroll;

    p {
        margin: 10px auto;
        max-width: initial;
        overflow-wrap: break-word;
        hyphens: auto;
        word-wrap: break-word;
    }
`;

const OmniAccountTerms: React.FC = () => {
    return (
        <TermsHolder>
            <p>IMPORTANT. PLEASE READ CAREFULLY.</p>

            <p>
                The document that follows this paragraph represent the Terms of
                Service for use of the Omni Sync Server. We think you will find
                it quite reasonable. Obviously, if you disagree, don’t create an
                account. Please don’t just stop there, though. Let us know. Send
                some email to{' '}
                <a href='mailto:info@omnigroup.com'>info@omnigroup.com</a>{' '}
                telling us what you find unacceptable. We can’t promise to
                change anything, but we will read and consider your concerns,
                and we will respond to your email.
            </p>
            <p>TERMS AND CONDITIONS OF USE</p>
            <p>
                THIS TERMS AND CONDITIONS OF USE AGREEMENT (“Agreement”) IS A
                LEGAL AGREEMENT between you (either an individual or an entity)
                and Omni Development, Inc. (“the Omni Group”). By using or
                benefiting from the Omni Sync Server (the “Service”), you are
                agreeing to be bound by the terms and conditions of this
                Agreement, and agree that you are responsible for compliance
                with any applicable laws. If you do not agree with any of these
                terms, do not use the Service in any manner.
            </p>
            <p>1. Use License; Conditions.</p>
            <p>
                Any license granted to you herein is conditioned on your
                compliance with the terms and conditions contained in this
                Agreement. This license shall automatically terminate if you
                violate any of these restrictions and may be terminated by the
                Omni Group at any time for any reason.
            </p>
            <p>
                The Omni Group grants to you a non-exclusive, limited right to
                use and access the Server, solely for your own internal business
                purposes.
            </p>
            <p>2. Disclaimer</p>
            <p>
                The Service is provided “as is.” You are responsible for
                maintaining your own backups of any data you store on the
                Service. The Omni Group makes no warranties, expressed or
                implied, and hereby disclaims and negates all other warranties,
                including without limitation, implied warranties or conditions
                of merchantability, fitness for a particular purpose, or
                non-infringement of intellectual property or other violation of
                rights. Further, the Omni Group does not warrant or make any
                representations concerning the accuracy, likely results, or
                reliability of the use of the materials on the Service or
                otherwise relating to such materials or on any sites linked to
                this site.
            </p>
            <p>3. Limitations</p>
            <p>
                In no event shall the Omni Group be liable for any damages
                (including, without limitation, damages for loss of data or
                profit, or due to business interruption,) arising out of the use
                or inability to use the materials on the Service, even if the
                Omni Group or an Omni Group authorized representative has been
                notified orally or in writing of the possibility of such damage.
                Because some jurisdictions do not allow limitations on implied
                warranties, or limitations of liability for consequential or
                incidental damages, these limitations may not apply to you.
            </p>

            <p>4. Terms of Service; Security; Availability.</p>
            <p>
                (a) Your privacy is very important to us. Accordingly, we have
                developed a Privacy Policy, posted at{' '}
                <a href='http://www.omnigroup.com/legal/privacy/'>
                    http://www.omnigroup.com/legal/privacy/
                </a>
                , in order for you to understand how we collect, use,
                communicate and disclose and make use of personal information.
            </p>
            <p>
                (b) The Omni Group reserves the right to refuse service to any
                person or organization for any reason, and reserves the right to
                terminate the service of any existing client for any reason.
            </p>
            <p>5. Termination.</p>
            <p>
                You may terminate this Agreement at any time by sending email to
                sync@omnigroup.com. Upon termination or expiration of any
                subscription, The Omni Group will endeavor to keep your existing
                data and data files of yours available for you to retrieve for a
                period not to exceed sixty (60) days from such termination or
                expiration. Following such period, you agree that the Omni Group
                may permanently dispose of or delete such data and data files
                without notice or any obligation or liability to you.
            </p>
            <p>6. Venue; Miscellaneous.</p>
            <p>
                In any suit or other action to enforce any right or remedy under
                or arising out of this Agreement, the prevailing party shall be
                entitled reasonable attorneys’ fees together with expenses and
                costs that such prevailing party incurs. Venue for any action
                under this Agreement shall be limited to the federal and state
                courts located in Seattle, Washington. The failure by the Omni
                Group to enforce any provision of this Agreement shall in no way
                be construed to be a present or future waiver of such provision
                nor in any way affect our right to enforce such provision
                thereafter. All waivers by us must be in writing to be
                effective.
            </p>
        </TermsHolder>
    );
};

export default OmniAccountTerms;
