export interface GetCustomerPostI {
    purchaseapiURL: string;
    userEmail: string;
    customerOmniID: string;
    signedInUserName: string;
    accountsToken: string;
}

export interface GetCustomerPostResponseI {
    message?: string;
    errors?: Array<string>;
    customer_id: string;
}

const getCustomer = async (
    props: GetCustomerPostI
): Promise<GetCustomerPostResponseI> => {
    return await new Promise((resolve, reject) => {
        fetch(`${props.purchaseapiURL}/api/v2/customer`, {
            body: JSON.stringify({
                email: props.userEmail,
                omni_id: props.customerOmniID,
                omni_username: props.signedInUserName,
            }),
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + props.accountsToken,
                'Content-Type': 'application/json',
            },
            method: 'POST',
        })
            .then((response) => response.json())
            .then((response) => {
                return resolve(response);
            })
            .catch((err) => {
                return reject(err);
            });
    });
};

export default getCustomer;
