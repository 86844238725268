export interface PostCancelSubscriptionI {
    purchaseapiURL: string;
    customerID: string;
    currentPlanID: string;
    accountsToken: string;
    cancelSubscription: boolean;
}

export interface UpdateSubscriptionReturn {
    message?: string;
    errors?: Array<{ code: number; message: string }>;
}

const cancelSubscription = async (
    props: PostCancelSubscriptionI
): Promise<UpdateSubscriptionReturn> => {
    const cancellationJSON = await fetch(
        `${props.purchaseapiURL}/api/v2/customer/${props.customerID}/subscription/${props.currentPlanID}`,
        {
            body: JSON.stringify({
                cancel_at_period_end: props.cancelSubscription,
            }),
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + props.accountsToken,
                'Content-Type': 'application/json',
            },
            method: 'POST',
            mode: 'cors',
        }
    )
        .then((response) => {
            return response.json();
        })
        .catch((err) => console.log(err));

    return cancellationJSON;
};

export default cancelSubscription;
