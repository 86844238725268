import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ActionType } from '../store/types';

import { Button } from '../reusable-components/Button';
import ErrorBox from '../reusable-components/ErrorBox';

interface StyledProps {
    confirm?: boolean;
    lowercase?: boolean;
    success?: boolean;
}

const Buttons = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

const Success = styled.div`
    max-width: 450px;
    line-height: 1.3em;
    margin: 0 auto 40px;
`;

const DeleteAccount = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 500px;
    padding: 0 10px;
`;

const Title = styled.h1<StyledProps>`
    text-align: center;
    text-transform: ${(props) => (props.lowercase ? 'initial' : 'uppercase')};
    font-weight: 800;
    margin: 40px 10px 10px;
    color: ${(props) => (props.success ? '#404040' : 'red')};

    &::before {
        content: '⚠';
        top: 9px;
        position: relative;
        padding-right: 10px;
        font-size: 55px;
        display: inline;
        color: #202020;
        font-weight: 500;
        display: ${(props) => (props.confirm ? 'inline' : 'none')};
    }

    &::after {
        content: '⚠';
        top: 9px;
        position: relative;
        padding-left: 10px;
        font-size: 55px;
        display: inline;
        color: #202020;
        font-weight: 500;
        display: ${(props) => (props.confirm ? 'inline' : 'none')};
    }

    @media (max-width: 600px) {
        &::before {
            display: none;
        }
        &::after {
            display: none;
        }
    }
`;

const SubTitle = styled.p`
    text-align: center;
    margin-top: 30px;
`;

const DoubleCheck = styled.h3`
    text-transform: uppercase;
    font-size: 20px;
    text-align: center;
    margin: 20px 0 10px;
`;

const DeleteOptions = styled.ul<StyledProps>`
    max-width: 400px;
    line-height: 1.3em;
    margin: 20px auto 20px;
    padding: 20px;
    background: ${(props) => (props.confirm ? '#202020' : '#fff')};
    color: ${(props) => (props.confirm ? '#fff' : '#202020')};

    > li {
        margin: 8px auto;
    }
`;

const Reset: React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(false);
    const [confirmAccountDeletion, setConfirmAccountDeletion] = useState<boolean>(false);
    const [errorOnReset, setErrorOnReset] = useState<boolean>(false);
    const [goHome, sendHome] = useState<boolean>(false);
    const [successfulReset, setSuccessReset] = useState<boolean>(false);

    const accountsApiURL = useSelector((state: OmniAccounts.GlobalReduxState) => state.accountsApiURL);
    const accountsToken = useSelector((state: OmniAccounts.GlobalReduxState) => state.accountsToken);
    const signedIn = useSelector((state: OmniAccounts.GlobalReduxState) => state.signedIn);
    const signedInUserName = useSelector((state: OmniAccounts.GlobalReduxState) => state.signedInUserName);

    useEffect(() => {
        if (signedIn === false) {
            navigate('/');
        }
    }, [signedIn, navigate]);

    useEffect(() => {
        if (goHome === true) {
            dispatch({ type: ActionType.DONE_EDITING_ACCOUNTS });
            navigate('/');
        }
    }, [goHome, navigate]);

    const resetUserData = async () => {
        await fetch(`${accountsApiURL}/api/1.1/user/dav/reset/`, {
            body: JSON.stringify({}),
            headers: {
                Authorization: 'Bearer ' + accountsToken,
                'Content-Type': 'application/json; charset=utf-8',
            },
            method: 'POST',
            mode: 'cors',
        })            
        .then((response) => {
            return response.json();
        })
        .then((response) => {
            if (response.status === 'success') {
                setLoading(false);
                setConfirmAccountDeletion(false);
                setErrorOnReset(false);
                setSuccessReset(true);
            }
        })
        .catch(() => {
            setLoading(false);
            setConfirmAccountDeletion(false);
            setErrorOnReset(true);
            setSuccessReset(false);
        });
    };

    return (
        <DeleteAccount>
            {!successfulReset ? (
                <div>
                    <Title
                        confirm={confirmAccountDeletion}
                        success={successfulReset}
                    >
                        Reset Server Data
                    </Title>
                    {errorOnReset && (
                        <ErrorBox>
                            We encountered an error during our attempt to reset
                            your data. Please try again, but if it fails a
                            second time, please{' '}
                            <a href='mailto:support@omnigroup.com'>
                                email our support team.
                            </a>
                        </ErrorBox>
                    )}
                    <SubTitle>
                        The account you’re resetting is:{' '}
                        <strong>{signedInUserName}</strong>
                    </SubTitle>
                    <p>
                        This will remove all of the files that currently exist
                        on Omni’s servers. This process cannot be undone.
                    </p>
                </div>
            ) : (
                <Title success={true} lowercase={true}>
                    Reset Successful
                </Title>
            )}
            {successfulReset ? (
                <Success>
                    <p>The request to reset your Sync data has succeeded.</p>
                    <p>
                        Any files which were stored on our server have been
                        deleted. This may include OmniFocus, OmniGraffle,
                        OmniPlan, OmniOutliner and OmniPresence data.
                    </p>
                    <p>
                        The reset will <em>not</em> prevent devices configured
                        to sync with this account from re-uploading files or
                        data in the future.
                    </p>
                    <p>
                        If you continue to use OmniFocus, the next Mac or iOS
                        device which syncs will re-upload the data you see in
                        the app at the time. Please note that OmniFocus for the
                        Web will not be available until this happens.
                    </p>
                    <p style={{ marginBottom: '30px' }}>
                        There is no ‘undo’ for this reset.
                    </p>
                    <Button center={true} onClick={() => sendHome(true)}>
                        Back to Accounts
                    </Button>
                </Success>
            ) : (
                <React.Fragment>
                    <DeleteOptions confirm={confirmAccountDeletion}>
                        <h3>The reset will:</h3>
                        <ul>
                            <li>
                                Delete the files that currently exist on Omni’s
                                servers. This may include OmniFocus,
                                OmniGraffle, OmniPlan, OmniOutliner and
                                OmniPresence data.
                            </li>
                        </ul>

                        <h3>
                            It will <em>not</em>:
                        </h3>
                        <ul>
                            <li>Deactivate your account</li>
                            <li>
                                Prevent devices from re-uploading files or
                                OmniFocus data in the future.
                            </li>
                        </ul>
                    </DeleteOptions>
                    {confirmAccountDeletion && (
                        <DoubleCheck>
                            Are you sure you want to proceed?
                        </DoubleCheck>
                    )}
                    {confirmAccountDeletion ? (
                        <Buttons>
                            <Button
                                onClick={() => setConfirmAccountDeletion(false)}
                            >
                                Cancel
                            </Button>
                            <Button
                                showLoading={loading}
                                danger={true}
                                onClick={() => {
                                    setLoading(true);
                                    resetUserData();
                                }}
                                someMargin={true}
                            >
                                Reset Data
                            </Button>
                        </Buttons>
                    ) : (
                        <Buttons>
                            <Link style={{ textDecoration: 'none' }} to={'/'}>
                                <Button someMargin={true}>Cancel</Button>
                            </Link>
                            <Button
                                showLoading={loading ? loading : undefined}
                                danger={false}
                                onClick={() => setConfirmAccountDeletion(true)}
                                someMargin={true}
                            >
                                Reset Data
                            </Button>
                        </Buttons>
                    )}
                </React.Fragment>
            )}
        </DeleteAccount>
    );
};

export default Reset;