import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Button } from '../../reusable-components/Button';
import addListOfEmails from './utilities/addEmails';
import grabListOfSeatsForID from '../../utilities/grabListOfSeatsForID';
import ErrorBox from '../../reusable-components/ErrorBox';
import { ActionType } from '../../store/types';
import { managedSubscriptionData } from '../../utilities/getUserData';

const EmailRow = styled.div`
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    margin: 20px 0;
    justify-content: space-between;
`;

const ButtonRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;

const TinierButton = styled.button`
    cursor: pointer;
    font-size: 14px;
    color: #fff;
    margin: 10px;
    min-width: initial;
    padding: 8px 12px;
    border: 0;
    border-radius: 5px;
    background-color: rgba(58, 159, 242, 1);
`;

interface AddContainerProps {
    disabled?: boolean;
    expandedHeight?: boolean;
}

const AddContainer = styled.div<AddContainerProps>`
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px 0px;
    display: flex;
    flex-direction: column;
    height: ${(props) => (props.expandedHeight ? '180px' : '34px')};
    justify-content: space-between;
    margin: 0;
    overflow-y: visible;
    padding: 0px 0 5px 0;
    position: absolute;
    transition: 0.1s all ease-out;
    width: ${(props) => (props.expandedHeight ? '350px' : '210px')};
    z-index: 301;
`;

const BulkAdd = styled.textarea<AddContainerProps>`
    width: ${(props) => (props.expandedHeight ? '350px' : '210px')};
    box-sizing: border-box;
    border: 0;
    background-color: #fff;
    -webkit-appearance: none;
    color: #404040;
    font-size: 13px;
    font-family: sans-serif;
    margin: 0;
    z-index: 299;
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'initial')};
    outline: 0;
    resize: none;
    padding: 10px 10px 6px;
    height: ${(props) => (props.expandedHeight ? '150px' : '34px')};
`;

const FormFields = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: row;
`;

const ExtraInfo = styled.div<AddContainerProps>`
    height: ${(props) => (props.expandedHeight ? '25px' : '0')};
    top: ${(props) => (props.expandedHeight ? '0' : '-10px')};
    align-items: flex-start;
    background: rgba(64, 64, 64, 0.1);
    overflow-y: visible;
    transition: 0.1s all ease-out;
    color: #404040;
    text-align: center;
    padding: 0;
    font-size: 12px;
`;

interface AddEmailProps {
    currentObject: OmniAccounts.LicenseObject;
    showDisabled: boolean;
    currentlyEditingID: number;
}

const AddEmail: React.FC<AddEmailProps> = (props: AddEmailProps) => {
    const { useState } = React;
    const { ADD_SEAT_LIST_TO_ARRAY, SAVE_USER_MANAGED_LICENSES } = ActionType;
    const dispatch = useDispatch();
    const [bulkEmailToAdd, setbulkEmailToAdd] = useState<string>('');
    const [displayInvalidEmailsError, setdisplayInvalidEmailsError] = useState(false);
    const [displayNotSentError, setdisplayNotSentError] = useState<boolean>(false);
    const [emailPrompt, setemailPrompt] = useState<boolean>(false);
    const [errorString, setErrorString] = useState<string>('');
    const [expandHeight, setexpandHeight] = useState<boolean>(false);
    const [showSubmit, setshowSubmit] = useState<boolean>();
    const [invalidEmails, setinvalidEmails] = useState< Array<string> | undefined>(undefined);
    const [emailsNotSent, setEmailsNotSent] = useState<Array<string> | undefined>(undefined);

    const accountsApiURL = useSelector((state: OmniAccounts.GlobalReduxState) => state.accountsApiURL);
    const accountsToken = useSelector((state: OmniAccounts.GlobalReduxState) => state.accountsToken);
    const teamPageIDToEdit = useSelector((state: OmniAccounts.GlobalReduxState) => state.teamPageIDToEdit);

    const promptToSendEmails = () => {
        if (teamPageIDToEdit === null) return;

        const regex = /(\s)/g; //look for any sort of whitespace javascript recognizes as horizontal
        const dualCommaRegex = /(,,)/g; //look for double-commas that the first might add.

        addListOfEmails(
            accountsToken,
            accountsApiURL,
            props.currentObject.id,
            bulkEmailToAdd
                .replace(regex, ', ')
                .replace(dualCommaRegex, ',')
                .replace(/(\r\n|\n|\r)/gm, '')
                .trim()
                .replace(/(,+$)/, '')
        )
            .then((result) => {
                if (result.invalid_emails) {
                    if (result.invalid_emails.length === 1 && result.invalid_emails[0] === '' ) {
                        setErrorString('');
                        setdisplayInvalidEmailsError(false);
                    } else {
                        setErrorString(' ');
                        setinvalidEmails(result.invalid_emails.map((email) => email));
                        setdisplayInvalidEmailsError(true);
                    }
                } else if (result.emails_not_sent) {
                    setEmailsNotSent(result.emails_not_sent.map((email) => email) );
                    setdisplayNotSentError(true);
                    setErrorString(' ');
                } else if (result.status === 'error') {
                    if (result.message !== undefined) {
                        setErrorString(result.message);
                    }
                } else {
                    setdisplayNotSentError(false);
                    setErrorString('');
                    setErrorString('');
                    setdisplayInvalidEmailsError(false);
                }
            })
            .then(() => {
                setexpandHeight(false);
                setbulkEmailToAdd('');
                setemailPrompt(false);
            })
            .then(async () => {
                const updatedListOfSeats = await grabListOfSeatsForID(
                    accountsApiURL,
                    props.currentObject.id,
                    accountsToken
                );
                dispatch({
                    type: ADD_SEAT_LIST_TO_ARRAY,
                    value: { id: teamPageIDToEdit,  seats: updatedListOfSeats.seats.map((seat) => seat) },
                });
                managedSubscriptionData({ accountsToken, accountsURL: accountsApiURL  })
                    .then((response) => {
                        if ( response.managed_team_licenses !== undefined && !response.err ) {
                            if (response.managed_team_licenses.length !== 0) {
                                dispatch({
                                    licenses: response.managed_team_licenses,
                                    type: SAVE_USER_MANAGED_LICENSES,
                                });
                            }
                    }
                });
            });
    };
    
    return (
        <EmailRow>
            <FormFields>
                <span style={{ fontWeight: 500, margin: '10px 20px auto 0' }}>
                    Invite Users:
                </span>
                <div style={{ display: 'inline-block', padding: '0' }}>
                    {!emailPrompt ? (
                        <AddContainer expandedHeight={expandHeight}>
                            <ExtraInfo expandedHeight={expandHeight}>
                                <span style={{ display: 'block', margin: '5px' }}>
                                    Enter as many emails as you’d like,
                                    separated by commas:
                                </span>
                            </ExtraInfo>
                            <BulkAdd
                                autoComplete='false'
                                disabled={props.showDisabled}
                                expandedHeight={expandHeight}
                                placeholder={ props.showDisabled ? 'All seats are in use.' : 'max.geekery@omnigroup.com'}
                                title='Enter a single email to add, or paste in a list of email addresses, separated by a comma or line breaks.'
                                aria-label={'email'}
                                value={bulkEmailToAdd}
                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                                    setbulkEmailToAdd(e.currentTarget.value);
                                    setshowSubmit(true);
                                }}
                                onFocus={() => setexpandHeight(true)}
                                onBlur={() => bulkEmailToAdd === '' ? setexpandHeight(false)  : null }
                            />
                            <div style={{
                                    display: expandHeight ? 'inline-block' : 'none',
                                    height: '65px',
                                    margin: '5px 0',
                                    width: '350px',
                                }}
                            >
                                {showSubmit && bulkEmailToAdd.length !== 0 && (
                                    <ButtonRow>
                                        <TinierButton onClick={() => setexpandHeight(false) }>
                                            Cancel
                                        </TinierButton>
                                        <TinierButton onClick={() => setemailPrompt(true)} >
                                            Add Email
                                        </TinierButton>
                                    </ButtonRow>
                                )}
                            </div>
                        </AddContainer>
                    ) : (
                        <AddContainer
                            style={{
                                border: '2px solid rgba(58, 159, 242, 1.00)',
                                height: 'auto',
                                maxHeight: '500px',
                                padding: '10px',
                                width: '450px',
                            }}
                            expandedHeight={expandHeight}
                        >
                            <h2 style={{ textAlign: 'center' }}>
                                Send Invitation?
                            </h2>
                            <p style={{ margin: '10px 20px', overflowY: 'scroll' }}>
                                This will invite {bulkEmailToAdd} to the team
                                license.
                            </p>
                            <ButtonRow>
                                <Button someMargin={true} onClick={() => setemailPrompt(false)}>
                                    Cancel
                                </Button>
                                <Button someMargin={true} onClick={() => promptToSendEmails()}>
                                    Send Invitation
                                </Button>
                            </ButtonRow>
                        </AddContainer>
                    )}
                </div>
            </FormFields>
            {errorString.length > 0 && (
                <ErrorBox textLeft errorTitle={'Error adding email(s)'}>
                    {displayNotSentError && (
                        <ul style={{ margin: '20px 0' }}>
                            <p>These emails were not sent:</p>{' '}

                            { emailsNotSent !== undefined && emailsNotSent.map((item: string, i: number) => (
                                <li key={i} style={{ margin: '5px 20px' }}>{item}</li>
                            ))}
                            <p>Make sure they’re correct, and then try again.</p>
                        </ul>
                    )}
                    { displayInvalidEmailsError && (
                        <div>
                            <p>These emails were invalid:</p>{' '}
                            <ul style={{ margin: '20px 0' }}>
                                {invalidEmails !== undefined &&
                                    invalidEmails.map((item: string, i: number) => (
                                        <li key={i} style={{ margin: '5px 20px' }}> {item}  </li>
                                    ))
                                }
                            </ul>
                            <p>
                                Double-check that they’re correct and are
                                separated by commas, and then try again.
                            </p>
                        </div>
                    )}
                    {errorString.length > 0 && (
                        <p style={{ margin: '20px' }}>{errorString}</p>
                    )}
                </ErrorBox>
            )}
        </EmailRow>
    );
};

export default AddEmail;
