import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { ActionType } from '../store/types';

import { Button } from '../reusable-components/Button';
import ErrorBox from '../reusable-components/ErrorBox';
import handleSignIn from '../utilities/handleSignIn';

const SignInForm = styled.form`
    margin: 30px auto;
    max-width: 600px;
    text-align: center;
    padding: 0 10px;

    a {
        cursor: pointer;
        display: inline-block;
        color: #404040;
    }
`;

const InputWrap = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    margin: 20px auto;
    width: 400px;

    input {
        border: 1pt #9090907a solid;
        border-radius: 4px;
        width: 50%;
        margin: auto 10px;
    }

    label {
        text-align: right;
        width: 30%;
        margin: auto 0;
    }
    @media (max-width: 600px) {
        width: initial;
        flex-direction: column;

        label {
            text-align: left;
            width: initial;
            margin: 10px 0;
        }

        input {
            margin: auto 0;
        }
    }
`;

const PasswordSuccessfulBanner = styled.h2`
    margin: 30px auto;
    max-width: 500px;
    font-size: 20px;
    text-align: center;
`;

const SignInDescription = styled.div`
    text-align: center;
    max-width: 500px;
    margin: auto;
`;

interface SignInProps {
    showSignInWithNewPassword: boolean;
}

const SignIn: React.FC<SignInProps> = (props: SignInProps) => {
    const dispatch = useDispatch();
    const accountsApiURL = useSelector( (state: OmniAccounts.GlobalReduxState) => state.accountsApiURL);

    const [loading, setLoading] = useState<boolean>(false);
    const [errorLanguage, setErrorString] = useState<string>('');
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [tryUsername, setTryUsername] = useState<boolean>(false);

    const handleSubmit = async (e: React.SyntheticEvent) => {
        e.preventDefault();
        setLoading(true);

        const signInResponse = await handleSignIn({
            accountsApiURL: accountsApiURL,
            password: password,
            username: username,
        });

        if (!signInResponse?.errors) {
            if (signInResponse?.token === undefined) {
                setLoading(false);
                setErrorString('Something went wrong when trying to sign in. It could be an issue between your computer and Omni Accounts, or a problem we’re actively trying to fix. Please try again later.');
                return;
            }
            if (signInResponse.token.access_token !== undefined) {
                const userAccountState = signInResponse.userinfo.account_state;

                if (userAccountState === 300 || userAccountState === 301) {
                    setLoading(false);
                    setErrorString('Your account has been closed. You’ll need to sign up for a new account.');
                    return;
                }

                localStorage.setItem('accountsauth',  JSON.stringify(signInResponse));
                dispatch({ accessToken: signInResponse.token.access_token,  type: ActionType.USER_LOGGED_IN });
            }
        } else {
            switch (signInResponse.code) {
                case 30003: //They need to go to accounts admin to do anything
                    console.log('this error code might no longer even be an issue.' );
                    break;
                case 3002: //they got a bad username or password
                    setErrorString(signInResponse.errors[0].message);
                    break;
                case 1002: //they have a bad token
                    setErrorString('Your session has expired. Please sign in again.' );
                    localStorage.removeItem('accountsauth');
                    dispatch({ type: ActionType.USER_LOGGED_OUT });
                    break;
                default:
                    setErrorString(signInResponse.errors[0].message);
                    break;
            }
            if (username.includes('@')) {
                setTryUsername(true);
            }
            setLoading(false);
        }
        e.defaultPrevented;
        return;
    };

    return (
        <SignInForm method='post' onSubmit={handleSubmit}>
            <SignInDescription>
                Your Omni Account is your key to services provided by The Omni
                Group. This includes the Omni Sync Server, OmniFocus for the
                Web, subscription and license management, and future new
                services.
            </SignInDescription>
            <p>
                Don’t have an account yet?{' '}
                <a href='/register' title='Sign up for an Omni Account'>
                    Sign up.
                </a>
            </p>
            {errorLanguage.length > 0 && (
                <ErrorBox errorTitle='Error signing in'>
                    <p>{errorLanguage}</p>
                    {tryUsername &&
                        'Make sure you’re using an account name, not an email address.'}
                </ErrorBox>
            )}
            {props.showSignInWithNewPassword && (
                <PasswordSuccessfulBanner>
                    Password successfully changed! Sign in with your new
                    password below.
                </PasswordSuccessfulBanner>
            )}
            <InputWrap>
                <label htmlFor='accountname'>Account Name: </label>

                <input
                    autoComplete='username'
                    id='accountname'
                    name='accountname'
                    onChange={(e) => setUsername(e.target.value)}
                    spellCheck='false'
                    type='text'
                    autoFocus
                />
            </InputWrap>
            <InputWrap>
                <label style={{ minWidth: 'initial' }} htmlFor='password'>
                    Password:
                </label>
                <input
                    autoComplete='current-password'
                    id='password'
                    name='password'
                    onChange={(e) => setPassword(e.target.value)}
                    spellCheck='false'
                    type='password'
                />
            </InputWrap>
            <Button
                showLoading={loading ? loading : undefined}
                showDisabled={username.length === 0 || password.length === 0}
                type='submit'
                value='Sign In'
                center={true}
            >
                Sign In
            </Button>
            <a href='/password-reset'>Forgot Password?</a>
        </SignInForm>
    );
};

export default SignIn;
