

export interface DeleteEmailPostInterface {
    deleted_seats?: Array<{ email: string; status: string }>;
    invalid_seats?: Array<{ email: string; status: string }>;
    status: string;
    message: string;
}

const deleteEmails = (
    emailsToDelete: Array<OmniAccounts.SeatObject>,
    accountsToken: string,
    accountsapiurl: string,
    id: number
): Promise<DeleteEmailPostInterface> => {
    const someEmailArray = emailsToDelete.map((email) => {
        return { ...email };
    });

    const productSeats = fetch(
        `${accountsapiurl}/api/1.1/registration_seats/${id}/`,
        {
            body: JSON.stringify({ seats: someEmailArray }),
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + accountsToken,
                'Content-Type': 'application/json',
            },
            method: 'DELETE',
            mode: 'cors',
        }
    )
        .then((response) => {
            setTimeout(() => {
                return;
            }, 1000);
            return response.json();
        })
        .catch((err) => {
            return err;
        });
    return productSeats;
};

export default deleteEmails;
