interface AccountCallsI {
    accountsURL: string;
    accountsToken: string;
}

interface UserPostReturn {
    user?: OmniAccounts.UserObject;
    managed_team_licenses?: Array<OmniAccounts.LicenseObject>;
    errors?: Array<{ status: string; message: string; code: number | string }>;
}

const userJSON = async (props: AccountCallsI): Promise<UserPostReturn> => {
    return await new Promise((resolve, reject) => {
        fetch(`${props.accountsURL}/api/1.1/user/`, {
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + props.accountsToken,
                'Content-Type': 'application/json',
            },
            method: 'POST',
            mode: 'cors',
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === undefined) {
                    return resolve({ user: response.userinfo });
                } else {
                    return resolve({ errors: response });
                }
            })
            .catch((err) => reject({ err: err, error: true }));
    });
};

interface ManagedSubscriptionReturnI {
    err?: string;
    managed_team_licenses?: Array<OmniAccounts.LicenseObject>;
}

const managedSubscriptionData = async (props: AccountCallsI): Promise<ManagedSubscriptionReturnI> => {
    return await new Promise((resolve, reject) => {
        fetch(`${props.accountsURL}/api/1.1/managed_team_licenses/`, {
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + props.accountsToken,
                'Content-Type': 'application/json',
            },
            method: 'POST',
            mode: 'cors',
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.err) {
                    return resolve({ err: response });
                } else {
                    return resolve({ managed_team_licenses: response.managed_team_licenses });
                }
            })
            .catch((err) => reject({ err: err, error: true }));
    });
};

interface PostPurchasesResponse {
    purchases?: Array<OmniAccounts.LicenseObject>;
}

const purchasesData = async (props: AccountCallsI): Promise<PostPurchasesResponse> => {
    return await fetch(`${props.accountsURL}/api/1.1/purchases/`, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + props.accountsToken,
            'Content-Type': 'application/json',
        },
        method: 'POST',
        mode: 'cors',
    })
        .then((response) => response.json())
        .catch((err) => err);
};

interface PostRegistrationsResponse {
    registrations?: {
        purchased_product_ids: Array<string>;
        registrations: Array<OmniAccounts.RegistrationObject>;
        subscriptions: Array<OmniAccounts.LicenseObject>;
        userinfo: OmniAccounts.UserObject;
    };
    status?: string;
    err?: string;
    error?: boolean;
}

const registeredData = async (props: AccountCallsI): Promise<PostRegistrationsResponse> => {
    return await fetch(`${props.accountsURL}/api/1.1/registrations/`, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + props.accountsToken,
            'Content-Type': 'application/json',
        },
        method: 'POST',
        mode: 'cors',
    })
        .then((response) => response.json())
        .then((response) => ({ registrations: response }))
        .catch((err) => ({ err: err, error: true }));
};

export { managedSubscriptionData, purchasesData, registeredData, userJSON };