import * as React from 'react';
import styled from 'styled-components';

interface BoxProps {
    animate?: boolean;
    disableUpperBorder?: boolean;
}

const Box = styled.div<BoxProps>`
    border-top: ${(props) =>
        props.disableUpperBorder ? '0' : '2px solid #F0F0F0'};
    display: flex;
    margin: 0 auto;
    flex-flow: row;
    background-color: ${(props) =>
        props.animate ? 'rgba(255, 255, 188, 1.00)' : '#fff'};
    padding: 20px 10px;
    transition: background 1s;

    @media (max-width: 600px) {
        flex-flow: column;
        padding: 20px 0;
    }
`;

const Subtext = styled.div`
    padding-right: 10px;

    p {
        font-size: 0.8em;
        color: #404040;
        margin: 10px 0;
    }
`;

const Left = styled.div`
    padding: 10px;
    width: 200px;
    flex-shrink: 0;

    @media (max-width: 600px) {
        padding: 10px;
        text-align: left;
        width: 100%;
        p {
            margin: 10px;
        }
    }
`;

const SectionTitleContainer = styled.div`
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;

    @media (max-width: 600px) {
        display: inline-flex;
        text-align: left;
        padding: 0 10px;
    }
`;

const Action = styled.span`
    color: #404040;
    cursor: pointer;
    font-size: 14px;
    text-decoration: underline;

    @media (max-width: 600px) {
        text-align: left;
        padding: 0;
        width: 100%;
    }
`;

const Right = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px;
    flex-grow: 1;

    @media (max-width: 600px) {
        margin: 0;
        padding: 0 20px;
    }
    /* //remove some margin from first child */
    p:first-child {
        margin-top: 5px;
    }
`;

declare const voidPromise: (e: React.SyntheticEvent) => Promise<void>;
declare const voidVoid: () => void;
declare const reduxType: () => { type: string };
interface HoldingBoxProps {
    actionTitle?: string;
    activeWatcher?: boolean;
    animate?: boolean;
    cancelAction?: typeof voidPromise | typeof voidVoid | typeof reduxType;
    children: React.ReactNode;
    clickAction?: typeof voidPromise | typeof voidVoid | typeof reduxType;
    disableUpperBorder?: boolean;
    doNotShowEditSpacer?: boolean;
    sectionTitle: string;
    subtext?: React.ReactNode;
}
const HoldingBox: React.FC<HoldingBoxProps> = ({
    children,
    ...props
}: HoldingBoxProps) => {
    return (
        <Box
            animate={props.animate}
            disableUpperBorder={props.disableUpperBorder}
        >
            <Left>
                <SectionTitleContainer>
                    {props.sectionTitle}
                </SectionTitleContainer>
                {!props.activeWatcher ? (
                    <Action onClick={props.clickAction}>
                        {props.actionTitle}
                    </Action>
                ) : (
                    <Action onClick={props.cancelAction}>Cancel</Action>
                )}
                {props.subtext && <Subtext>{props.subtext}</Subtext>}
            </Left>
            <Right>{children}</Right>
        </Box>
    );
};

export default HoldingBox;
