import * as React from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { Button } from '../reusable-components/Button';
import { useSelector, useDispatch } from 'react-redux';
import { postSetupIntent } from '../utilities/postSetupIntent';
import { postDefaultPaymentMethod } from '../utilities/postDefaultPaymentMethod';
import ErrorBox from '../reusable-components/ErrorBox';
import getCustomer from '../utilities/getCustomer';
import { ActionType } from '../store/types';

interface CheckoutFormProps {
    customerID: string;
}
const CheckoutForm: React.FC<CheckoutFormProps> = (
    props: CheckoutFormProps
) => {
    const stripe = useStripe();
    const elements = useElements();
    const dispatch = useDispatch();

    const purchaseapiURL = useSelector(
        (state: OmniAccounts.GlobalReduxState) => state.purchaseapiURL
    );
    const accountsToken = useSelector(
        (state: OmniAccounts.GlobalReduxState) => state.accountsToken
    );
    const customerInfo = useSelector(
        (state: OmniAccounts.GlobalReduxState) => state.customerInfo
    );
    const signedInUserName = useSelector(
        (state: OmniAccounts.GlobalReduxState) => state.signedInUserName
    );
    const customerOmniID = props.customerID;
    const userEmail = useSelector(
        (state: OmniAccounts.GlobalReduxState) => state.userEmail
    );
    const [newCustomerID, setJustCreatedCustomerId] = React.useState('');
    const [error, setError] = React.useState('');
    const [loading, setLoading] = React.useState<boolean>(false);

    const [setupIntentClientSecretInLocalState, setSIClientSecret] =
        React.useState<{ cs: string; id: string } | null>(null);

    React.useEffect(() => {
        if (props.customerID === undefined) {
            return;
        }

        const createAndSaveInitialSetupIntent = (customer_id: string) => {
            postSetupIntent({
                accountsToken: accountsToken,
                customer_id: customer_id,
                purchaseapiURL: purchaseapiURL,
                usage: 'off_session',
            }).then((response) =>
                setSIClientSecret({
                    cs: response.cs,
                    id: response.id,
                })
            );
        };

        const createAndRefreshCustomerID = () => {
            if (
                userEmail !== undefined &&
                customerOmniID !== undefined &&
                signedInUserName !== undefined &&
                customerInfo !== undefined
            ) {
                getCustomer({
                    accountsToken: accountsToken,
                    customerOmniID: customerOmniID,
                    purchaseapiURL: purchaseapiURL,
                    signedInUserName: signedInUserName,
                    userEmail: userEmail,
                })
                    .then((resp) => {
                        setJustCreatedCustomerId(resp.customer_id);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        };

        if (customerOmniID !== undefined) {
            createAndSaveInitialSetupIntent(customerOmniID);
        } else if (newCustomerID.length !== 0) {
            createAndSaveInitialSetupIntent(newCustomerID);
        }
        if (props.customerID === undefined) {
            createAndRefreshCustomerID();
        }
    }, [
        props.customerID,
        newCustomerID,
        customerOmniID,
        accountsToken,
        purchaseapiURL,
        userEmail,
        signedInUserName,
        customerInfo,
    ]);

    const handleSubmit = async (event: React.FormEvent) => {
        setLoading(true);
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        const cardElement = elements.getElement(CardElement);

        if (
            setupIntentClientSecretInLocalState !== null &&
            cardElement !== null
        ) {
            const { error, setupIntent } = await stripe.confirmCardSetup(
                setupIntentClientSecretInLocalState.cs,
                {
                    payment_method: {
                        card: cardElement,
                    },
                }
            );
            if (error) {
                console.log('[error]', error);
                if (error.type === 'invalid_request_error') {
                    const setupIntentClientSecret = await postSetupIntent({
                        accountsToken: accountsToken,
                        customer_id: props.customerID,
                        purchaseapiURL: purchaseapiURL,
                        usage: 'off_session',
                    });
                    setSIClientSecret(setupIntentClientSecret);
                }
                setError(
                    'There was a problem adding this card to your account. Make sure the number is correct and funds are available, and try again.'
                );
            }
            if (setupIntent !== undefined) {
                if (setupIntent.payment_method !== null) {
                    postDefaultPaymentMethod({
                        accountsToken: accountsToken,
                        customer_id: props.customerID,
                        payment_method_id: setupIntent.payment_method as string,
                        purchaseapiURL: purchaseapiURL,
                    });
                    setError('');
                    setLoading(false);
                    dispatch({
                        type: ActionType.UPDATING_CARD,
                    });
                } else {
                    console.log(
                        'Some Kind of Unable to Setup Setup Intent error'
                    );
                    console.log(
                        '[SetupIntentWithPaymentMethodConfirmed]',
                        setupIntent
                    );
                    setError(
                        'There was a problem adding this card to your account. Make sure the number is correct and funds are available, and try again.'
                    );
                    setLoading(false);
                }
            }
        } else {
            console.log('Some Kind of Unable to Setup Setup Intent error');
            setLoading(false);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            {error.length > 0 && <ErrorBox>{error}</ErrorBox>}
            <CardElement />
            <Button showLoading={loading} type='submit' showDisabled={!stripe}>
                <span>Add Card</span>
            </Button>
        </form>
    );
};

export default CheckoutForm;
