import styled from 'styled-components';

// eslint-disable-next-line
interface StyledNoteI {
    passionate?: boolean;
    ratherInline?: boolean;
}
const StyledNote = styled.div<StyledNoteI>`
    margin: 10px auto;
    width: 100%;
    text-align: left;

    ${(props) =>
        props.ratherInline &&
        `
        width: 100%;
        max-width: initial;
        text-align: left;
        margin: 20px auto;
        font-size: 14px;
        padding: 5px 0;
    `};
    ${(props) =>
        props.passionate &&
        `
            padding: 10px 15px;
            background-color: #ffff0085;
            border-radius: 8px;
    `};
`;

const Note: React.FC<StyledNoteI> = ({ ...props }) => {
    return <StyledNote {...props} />;
};

export default Note;
