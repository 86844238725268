import Stripe from 'stripe';

interface PostInvoicePreviewProps {
    customer_id: string;
    purchaseapiURL: string;
    accountsToken: string;
    subscription_id: string;
    postBody: {
        default_tax_rates: string | undefined;
        plan: string;
        quantity: number;
        subscription_item_id: string;
    };
}

export const postRetrieveInvoicePreview = async ({
    customer_id,
    purchaseapiURL,
    accountsToken,
    subscription_id,
    postBody,
}: PostInvoicePreviewProps): Promise<Stripe.Invoice> => {
    return await new Promise((resolve, reject) => {
        fetch(
            `${purchaseapiURL}/api/v2/customer/${customer_id}/invoice/${subscription_id}/preview`,
            {
                body: JSON.stringify(postBody),
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${accountsToken}`,
                    'Content-Type': 'application/json',
                },
                method: 'POST',
                mode: 'cors',
            }
        )
            .then((response) => {
                return Promise.resolve(response.json()).then((res) => {
                    if (!res.errors) {
                        resolve(res);
                    } else if (res.errors) {
                        console.log(res.errors);
                        return reject(true);
                    }
                    return reject(true);
                });
            })
            .catch((err) => {
                console.log('err', err);
                return reject();
            });
    });
};
