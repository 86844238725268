import React, { useState, useEffect, createRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import qs, { ParsedQs } from 'qs';
import { ActionType } from './store/types';

import './App.css';

import ActiveUpgrades from './components/licenseUpgrades/ActivateUpgrade';
import Account from './components/Account';
import { Button } from './reusable-components/Button';
import ErrorBox from './reusable-components/ErrorBox';
import TeamMangement from './components/TeamManagement';
import Password from './components/Password';
import Payment from './components/Payment';
import RegisteredLicenseKeys from './components/RegisteredLicenseKeys';
import Seats from './components/Seats';
import SignIn from './components/SignIn';
import ThemeContextProvider from './components/modifySubscription/ThemeContext';

import { getCustomerSeats } from './utilities/getCustomerSeats';
import { postAcceptInvite } from './utilities/postAcceptInvite';
import refreshThisToken from './utilities/refreshThisToken';
import { userJSON } from './utilities/getUserData';
import  getUnactivatedUpgrades from './utilities/getActivateUpgrades';

const AccountIndex = styled.div``;

const AcceptInvite = styled.div``;

const SignUpForLicense = styled.div`
    border: 1px solid rgba(58, 159, 242, 1);
    padding: 20px 30px;
    border-radius: 8px;
`;

const AccountContent = styled.div`
    max-width: 700px;
    margin: 20px auto;
`;

const AccountContentError = styled.div`
    background-color: rgba(0, 0, 0, 0.04);
    border: 2px solid rgba(48, 140, 255, 1);
    border-radius: 8px;
    box-shadow: 0 3px 0px rgba(48, 140, 255, 1);
    padding: 1px 10px 15px;
    text-align: center;
`;

interface AppProps {
    newFlow?: boolean;
    teamSubscription?: boolean;
}

const App: React.FC<AppProps> = (props: AppProps): React.ReactElement => {
    const dispatch = useDispatch();
    const parsedQuery = qs.parse(window.location.search);
    const [showSignInWithNewPassword, setShowSignInWithPassword] = useState<boolean>(false);
    const [attachSuccess, setAttachSuccess] = useState<boolean>(false);
    const [acceptWarning, setAcceptWarning] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [signUpRef, setSignUpRef] = useState<string | ParsedQs | string[] | ParsedQs[]>(parsedQuery['?ref'] ?? '');
    // const [showSeatArea, setShowSeatArea] = React.useState<boolean>(false);
    
    const signedIn = useSelector((state: OmniAccounts.GlobalReduxState) => state.signedIn);
    const unactivatedSeatRegistrations = useSelector((state: OmniAccounts.GlobalReduxState) => state.unactivatedSeatUpgrades?.registrations);

    useEffect(() => {
        const temporaryQuery = parsedQuery['?ref'] !== undefined ? parsedQuery['?ref'].toString() : '';

        if (temporaryQuery !== undefined) {
            if (temporaryQuery.length > 10) {
                localStorage.setItem('signUpRef', signUpRef.toString());
                if (signedIn === false) {
                    setSignUpRef(temporaryQuery);
                } else if (signedIn === true) {
                    setSignUpRef(temporaryQuery);
                }
            } else {
                setAcceptWarning(true);
            }
        } else {
            // do something else
        }
    }, [ signedIn, parsedQuery, signUpRef ]);

    const teamRef = createRef<HTMLDivElement>();
    const subscriptionsRef = createRef<HTMLDivElement>();
    const seatsRef = createRef<HTMLDivElement>();

    const userInfo = useSelector((state: OmniAccounts.GlobalReduxState) => state.userInfo);
    const accountsApiURL = useSelector((state: OmniAccounts.GlobalReduxState) => state.accountsApiURL );
    const accountsToken = useSelector((state: OmniAccounts.GlobalReduxState) => state.accountsToken);
    const customerInfo = useSelector((state: OmniAccounts.GlobalReduxState) => state.customerInfo);
    const refreshToken = useSelector((state: OmniAccounts.GlobalReduxState) => state.refreshToken );
    const tokenExpiresAt = useSelector((state: OmniAccounts.GlobalReduxState) => state.tokenExpiresAt);

    const location = useLocation();
    const navigate = useNavigate();

    const [flashYellow, setFlashYellow] = useState(false);

    useEffect(() => {
        setShowSignInWithPassword(
            window.location.search.includes('password-success')
        );
    }, []);

    useEffect(() => {
        if (location.search) {
            const searchquery = qs.parse(window.location.search);

            if (searchquery['?section'] === 'team' && !flashYellow) {
                setFlashYellow(true);
                if (teamRef !== null && teamRef.current !== null) {
                    window.scrollTo(0, teamRef.current.offsetTop);
                }
            }
        }
    }, [location.search, flashYellow, teamRef]);

    const acceptLicense = async () => {
        const attachmentResults = await postAcceptInvite(
            accountsApiURL,
            accountsToken,
            signUpRef.toString()
        );

        if (attachmentResults.status === 'success') {
            setAttachSuccess(true);
            setErrorMessage('');

            localStorage.removeItem('signUpRef');

            // scrollToTeamSubscriptionSection();
            //TODO scroll to team subscription section
            navigate('/');
        } else if (attachmentResults.status === 'error') {
            setErrorMessage(attachmentResults.message);
        }
    };

    useEffect(() => {
        if (location.hash.includes('#subscriptions') && subscriptionsRef && subscriptionsRef.current) {
            window.scrollTo(0, subscriptionsRef.current.offsetTop);
        }
    }, [location.hash, subscriptionsRef]);

    useEffect(() => {
        if (tokenExpiresAt === 0) return;
        // wow, this doesn't even do anything
        async function startTokenRefresh() {
            const tokenRefreshAttempt = await refreshThisToken({
                accountsApiURL,
                refreshToken,
            });

            if (tokenRefreshAttempt.errors) {
                //TODO clear accounts tokens in redux
                localStorage.clear();
            }
        }

        if (tokenExpiresAt > Date.now()) {
            startTokenRefresh();
        }
        return;
    }, [accountsApiURL, refreshToken, tokenExpiresAt]);

    useEffect(() => {
        if (userInfo && userInfo.seats) {
            if (userInfo.seats.length >= 1) {
                // setShowSeatArea(true);
                return;
            }
        }
        async function refreshUserCalls() {
            const userCall = await userJSON({
                accountsToken,
                accountsURL: accountsApiURL,
            });
            const seatData = await getCustomerSeats(
                accountsApiURL,
                accountsToken
            );
            dispatch({ type: ActionType.SAVE_TEAM_SEATS, value: seatData.seats });
            if (!userCall.errors) {
                dispatch({
                    type: ActionType.SAVE_USER_INFO,
                    userinfo: userCall.user,
                });
            }
        }

        if (attachSuccess && accountsApiURL !== '') {
            refreshUserCalls();
        }
    }, [userInfo, attachSuccess, accountsApiURL, accountsToken, dispatch]);

    useEffect(() => {
        if (signedIn && accountsToken) {
            getUnactivatedUpgrades({accountsApiURL, accountsToken})
                .then((registrations) =>  dispatch({
                        type: ActionType.UNACTIVATED_UPGRADES,
                        registrations: registrations
                    })
                )
                .catch(error => console.log('unactivated upgrades seat error', error))
        }
    }, [ signedIn, accountsToken])

    return (
        <AccountIndex>
            {errorMessage !== '' && location.pathname !== '/confirm-invite/' && (
                <AccountContentError>
                    <h1>Error!</h1>
                    <p>{errorMessage}</p>
                </AccountContentError>
            )}
            {props.newFlow && signUpRef !== '' && (
                <div
                    style={{
                        margin: '10px auto',
                        textAlign: 'center',
                        width: '600px',
                    }}
                >
                    <SignUpForLicense>
                        <h2>Welcome!</h2>
                        {errorMessage.length > 0 && (
                            <ErrorBox
                                style={{ margin: '10px auto' }}
                                errorTitle='Invalid Invitation Link'
                            >
                                <p>
                                    {errorMessage} Contact the Team Subscription
                                    manager for a new invitation.
                                </p>
                            </ErrorBox>
                        )}
                        {!signedIn ? (
                            <React.Fragment>
                                {acceptWarning && (
                                    <ErrorBox errorTitle='Invalid Invitation Link'>
                                        <p>
                                            The original seat invitation has
                                            been used. Ask your team manager for
                                            a new invitation.
                                        </p>
                                    </ErrorBox>
                                )}

                                <p>
                                    You’ve been invited to join a team. If you
                                    already have an Omni Account (or an Omni
                                    Sync Server account), you can sign in below.
                                    If you don’t yet have an account,{' '}
                                    <a
                                        href='/register'
                                        title='Sign up for an Omni Account'
                                    >
                                        sign up.
                                    </a>
                                </p>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                {acceptWarning && (
                                    <ErrorBox errorTitle='Invalid Invitation Link'>
                                        <p>
                                            The original seat invitation has
                                            been used. Ask your team manager for
                                            a new invitation.
                                        </p>
                                    </ErrorBox>
                                )}
                                {!acceptWarning && (
                                    <React.Fragment>
                                        <p>
                                            You’ve been invited to join a team.
                                            If you don’t want to join using this
                                            account, sign out, and then sign in
                                            again.
                                        </p>
                                        <AcceptInvite>
                                            <Button
                                                center={true}
                                                onClick={() => acceptLicense()}
                                            >
                                                <span>
                                                    Accept Team Invitation
                                                </span>
                                            </Button>
                                        </AcceptInvite>
                                    </React.Fragment>
                                )}
                            </React.Fragment>
                        )}
                    </SignUpForLicense>
                </div>
            )}
            {accountsToken !== '' ? (
                <AccountContent>
                    {unactivatedSeatRegistrations && unactivatedSeatRegistrations.length > 0 && (
                        <ActiveUpgrades/>
                    )} 
                    <Account />
                    <Password />
                    {customerInfo !== undefined && <Payment />}
                    <div ref={subscriptionsRef} />
                    <div ref={seatsRef} />
                    <ThemeContextProvider>
                        <Seats />
                    </ThemeContextProvider>
                    <div ref={teamRef} />
                    <TeamMangement flashYellow={flashYellow} /> 
                    <RegisteredLicenseKeys />
                </AccountContent>
            ) : (
                <SignIn showSignInWithNewPassword={showSignInWithNewPassword} />
            )}
        </AccountIndex>
    );
};

export default App;
