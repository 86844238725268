import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { List } from 'react-content-loader';
import { ActionType } from '../store/types';


import HoldingBox from '../reusable-components/HoldingBox';
import ErrorBox from '../reusable-components/ErrorBox';
import { Button, SideBySide } from '../reusable-components/Button';
import Note from '../reusable-components/Note';
import LicenseEntry from './LicenseEntry';

import postRegisterLicenseKey from '../utilities/postRegisterLicenseKey';
import { purchasesData } from '../utilities/getUserData';


const LicenseHolder = styled.div``;

const SectionSubtext = styled.div`
    padding-bottom: 20px;
`;

const FakeForm = styled.form`
    display: flex;
    flex-direction: column;

    label {
        margin-top: 0;
    }
`;

const LicenseHeader = styled.div`
    font-size: 16px;
    margin: 5px 0 20px 0;
    font-weight: bold;
`;

const RegisteredLicenseKeys: React.FC = () => {
    const dispatch = useDispatch();
    // New license keys are stored in react state
    const [licenseKey, setKey] = useState<string>('');
    const [licenseOwner, setOwner] = useState<string>('');

    const managedLicenses = useSelector((state: OmniAccounts.GlobalReduxState) => state.managedLicenses);
    const accountsToken = useSelector((state: OmniAccounts.GlobalReduxState) => state.accountsToken);
    const accountsApiUrl = useSelector((state: OmniAccounts.GlobalReduxState) => state.accountsApiURL);
    const purchasedData = useSelector((state: OmniAccounts.GlobalReduxState) => state.purchasesData);


    const [addingKey, setAddingKey] = useState<boolean>(false);
    const [error, setError] = useState<string | null>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);

    const holdingBoxSubtext = (
        <p>
            Have a legacy license key? Register it to your 
            account to unlock upgrade pricing or gain access 
            via this account for versions that support 
            unlocking via Omni Account.
        </p>
    );
    const editingRef = useRef(false);

    useEffect(() => {
        if (addingKey !== editingRef.current) {
            // setError(false);
            setSuccess(false);
        }
        editingRef.current = addingKey;
    }, [addingKey]);

    const updatePurchasesData = async () => {
        const updatePurchases = await purchasesData({
            accountsToken,
            accountsURL: accountsApiUrl,
        });

        if (updatePurchases.purchases) {
            dispatch({
                type: ActionType.SAVE_PURCHASES_DATA,
                value: updatePurchases.purchases,
            });
        }
    }

    const registerLicenseKey = async (e: React.FormEvent) => {
        setLoading(true);
        e.preventDefault();
        
        const registerKey = await postRegisterLicenseKey({
            accountsApiUrl,
            accountsToken,
            license_key: licenseKey,
            license_owner: licenseOwner,
        });

        if (registerKey) {
            if (registerKey.status === 'success') {
                setSuccess(true);
                //refresh if license key registered successfully
                updatePurchasesData();
            } else if (registerKey.status === 'error') {
                if (registerKey.messages) {
                    switch(registerKey.messages[0].code) {
                        case 10204:
                            setError(
                                'This license is not eligible for registration. If you believe this is an error, please contact us at sales@omnigroup.com.'
                            );
                            break;
                        case 10201:
                            setError(
                                'This license key is registered to another account. Please email sales@omnigroup.com if you believe this is an error.'
                            );
                            break;
                        default:
                            setError(
                                'Unable to add this license key. Double-check its accuracy and try again, or email sales@omnigroup.com.'
                            );
                            break;
                    }
                } else {
                    setError(
                        'Unable to add this license key. Double-check its accuracy and try again, or email sales@omnigroup.com.'
                    );
                }
            } else {
                setError(
                    'Unable to add this license key. Double-check its accuracy and try again, or email sales@omnigroup.com.'
                );
            }
        }
        setLoading(false);
    }

    if (!purchasedData){
        return null;
    } 

    if (!managedLicenses) {
        return <List />;
    }

    if (loading) {
        return <List/>;
    }

    const licenses = purchasedData.filter((license) => 'key' in license);

    const addKeyForm = addingKey ? (
        <LicenseHolder>
            <LicenseHeader>Add License</LicenseHeader>
            { error && (
                <ErrorBox
                    errorTitle='Error adding license'
                    textLeft
                >
                    <p>{error}</p>
                </ErrorBox>
            )}
            <FakeForm onSubmit={(e: React.FormEvent) => registerLicenseKey(e)}>
                <label htmlFor='owner'>Owner</label>
                <input 
                    id='owner' 
                    type='text' 
                    onChange={(e) => setOwner(e.target.value)}
                />
                <label htmlFor='key'>License Key</label>
                <input
                    id='key'
                    onChange={(e) => setKey(e.target.value)}
                    type='text'
                />
                <SideBySide justify='flex-end'>
                    <a href='https://legacy.store.omnigroup.com/lost' style={{ margin: '0 20px 0 ' }}>
                        Lost your license key?
                    </a>
                    <Button minWidth='100px'>Save</Button>
                </SideBySide>
            </FakeForm>
        </LicenseHolder>
    ) : null;

    return (
        <div>
            <HoldingBox
                sectionTitle='Legacy License Keys'
                cancelAction={() => setAddingKey(false)}
                activeWatcher={addingKey}
                actionTitle='Add License'
                clickAction={() => setAddingKey(true)}
                subtext={holdingBoxSubtext}
            >
                { managedLicenses && (
                    <React.Fragment>
                        { success && (
                            <Note>
                                Successfully registered your license key!
                            </Note>
                        )}
                        { addKeyForm }
                    </React.Fragment>
                )}
                { licenses && licenses.length < 1 && (
                    <>
                        <SectionSubtext>
                            You do not currently have any registered
                            license keys.
                        </SectionSubtext>
                        <SideBySide justify='space-between'>
                            {/* <a href='https://store.omnigroup.com'>
                                Buy New License
                            </a> */}
                            <Button onClick={() => setAddingKey(true)}>
                                Add License Key
                            </Button>
                        </SideBySide>
                    </>
                ) }
                { licenses.length > 0 && (
                    <div>
                        { licenses.map((item: OmniAccounts.LicenseObject, fakeKey: number) => (
                            <LicenseEntry
                                key={fakeKey}
                                licenseItem={item}
                                correspondingManagedLicenseID={
                                    managedLicenses?.filter((license) => license.registration_id === item.registration_id)[0]?.id
                                }
                            />
                        ))}
                    </div>
                ) }
            </HoldingBox>
        </div>
    );
};

export default RegisteredLicenseKeys;
