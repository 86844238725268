import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { List } from 'react-content-loader';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import FourOhFour from '../FourOh';
import EditLicenseSeats from './EditLicenseSeats';
import EditSubscriptionSeats from './EditSubscriptionSeats';

const Wrapper = styled.div`
    display: flex;
    flex-flow: row;
    padding: 20px;

    @media (max-width: 900px) {
        flex-flow: column;
    }
`;

const Content = styled.div`
    display: flex;
    flex-flow: column;
    width: 100%;
    padding: 0;
    text-align: left;

    h1 {
        text-align: center;
        font-size: 28px;
    }
    @media (max-width: 900px) {
        width: 99%;
    }
`;

interface ManageTeamProps {
    subscriptionOrLicenseToEdit?: Array<OmniAccounts.LicenseObject>;
}

const ManageTeam: React.FC<ManageTeamProps> = () => {
    const navigate = useNavigate();

    const managedLicenses = useSelector((state: OmniAccounts.GlobalReduxState) => state.managedLicenses);
    const purchasesData = useSelector((state: OmniAccounts.GlobalReduxState) => state.purchasesData);
    const signedIn = useSelector((state: OmniAccounts.GlobalReduxState) => state.signedIn);
    const teamManager = useSelector((state: OmniAccounts.GlobalReduxState) => state.teamManager);
    const teamPageIDToEdit = useSelector((state: OmniAccounts.GlobalReduxState) => state.teamPageIDToEdit);

    useEffect(() => {
        if (signedIn === false || teamPageIDToEdit === null) {
            navigate('/');
        }
    }, [signedIn]);

    if (!managedLicenses || !purchasesData) {
        return (
            <Wrapper>
                <Helmet>
                    <title>Manage Your Omni Account - The Omni Group</title>
                </Helmet>
                <Content>
                    <List />
                </Content>
            </Wrapper>
        );
    }
    
    if (teamManager === false && signedIn === false) {
        return <FourOhFour />;
    }
    
    const subscriptionSeats = managedLicenses.filter((license: OmniAccounts.LicenseObject) => license.id.toString() === teamPageIDToEdit && 'subscription_state' in license)
    const licenseSeats = managedLicenses.filter((license: OmniAccounts.LicenseObject) => license.id.toString() === teamPageIDToEdit && 'subscription_state' in license === false);
    
    return (
        <Wrapper>
            <Helmet>
                <title>Manage Your Omni Account - The Omni Group</title>
            </Helmet>
            <Content>
                {/* Below, we’re only checking the list of licenses and subscriptions for anything that is coming from the "managed licenses" GET call */}
                { subscriptionSeats.map((license) => (
                    <EditSubscriptionSeats
                        key={license.id}
                        currentObject={license}
                    />
                ))}
                { licenseSeats.map((license) => (
                    <EditLicenseSeats
                        key={license.id}
                        currentObject={license}
                    />
                ))}
            </Content>
        </Wrapper>
    );
};

export default ManageTeam;
