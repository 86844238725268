import  { ActionType, Action } from './types';

let signedInAlready = false;
let firstAccessToken = '';
if (localStorage.getItem('accountsauth')) {
    signedInAlready = true;
}

const localStorageAuth = localStorage.getItem('accountsauth');

const stringEditIDIfNotNull = sessionStorage.getItem('teameditid');

const initialEditID = stringEditIDIfNotNull !== null && stringEditIDIfNotNull;

if (signedInAlready && typeof localStorageAuth === 'string') {
    if (JSON.parse(localStorageAuth).token.access_token) {
        firstAccessToken =
            JSON.parse(localStorageAuth).token.access_token ?? '';
    }
}

const initialAccountsAPIURL = process.env.REACT_APP_ACCOUNTS_HOST
    ?  process.env.REACT_APP_ACCOUNTS_HOST
    : window.location.host.toString().indexOf("test") != -1
    ? 'https://accounts.test.omnigroup.com'
    : window.location.host.toString().indexOf("stage") != -1
    ? 'https://accounts.stage.omnigroup.com'
    : 'https://accounts.omnigroup.com';

const initialPurchaseAPIURL = process.env.REACT_APP_PURCHASE_HOST
    ? process.env.REACT_APP_PURCHASE_HOST
    : window.location.host.toString().indexOf("test") != -1
    ? 'https://purchase.test.omnigroup.com'
    : window.location.host.toString().indexOf("stage") != -1
    ? 'https://purchase.stage.omnigroup.com'
    : 'https://purchase.omnigroup.com';


const initialState: OmniAccounts.GlobalReduxState = {
    accountsApiURL: initialAccountsAPIURL,
    accountsEditReady: false,
    accountsToken: signedInAlready ? firstAccessToken : '',
    activeSubscriptionInfo: null,
    animateUpdate: false,
    checkedSeatsForDeletion: [],
    customerInfo: undefined,
    editPasswordReady: false,
    firstElement: '',
    globalError: false,
    globalErrorOffender: '',
    globalErrorString: '',
    listOfRegistrations: undefined,
    listOfSeats: [],
    managedLicenses: undefined,
    networkError: false,
    paymentMethodsOnFile: [],
    purchaseapiURL: initialPurchaseAPIURL,
    purchasesData: null,
    readyToEditCards: false,
    readyToEditEverything: false,
    recentlyChangedEmail: '',
    refreshSubscriptionID: '',
    refreshToggle: false,
    refreshToken: '',
    refreshUserLicenses: false,
    resetPassword: false,
    signedIn: signedInAlready,
    signedInUserName: '',
    storeURL: 'https://subscribe.store.omnigroup.com',
    stripeKey: '',
    subscriptionsEditReady: false,
    supportedProductVersions: {
        'OmniFocus': 3,
        'OmniGraffle': 7,
        'OmniOutliner': 5,
        'OmniPlan': 4,
    },
    teamManager: false,
    teamPageIDToEdit: initialEditID ? initialEditID : null,
    teamSeats: null,
    tokenExpiresAt: 0,
    unactivatedSeatUpgrades: null,
    updateCounter: 0,
    updateUserCall: false,
    userEmail: '',
    userInfo: null,
};

const reducer = (state = initialState, action: Action): OmniAccounts.GlobalReduxState => {
    const newState: OmniAccounts.GlobalReduxState = { ...state };
    if (typeof state === 'undefined') {
        return { ...initialState };
    }
    if (action === undefined) {
        return { ...newState };
    }

    switch (action.type) {
        case ActionType.ADD_STRIPE_KEY:
            return {
                ...newState,
                stripeKey: action.publishable_key,
            };
        case ActionType.UPDATING_COUNTER:
            return {
                ...newState,
                updateCounter: state.updateCounter + 1,
                updateUserCall: !state.updateUserCall,
            };
        case ActionType.SET_TEAM_ID_TO_EDIT:
            return {
                ...newState,
                teamPageIDToEdit: action.value,
            };
        case ActionType.USER_LOGGED_IN:
            return {
                ...newState,
                accountsToken: action.accessToken,
                signedIn: true,
            };
        case ActionType.UPDATING_CARD:
            return {
                ...newState,
                readyToEditCards: !state.readyToEditCards,
            };
        case ActionType.EDIT_PASSWORD:
            return {
                ...newState,
                editPasswordReady: !state.editPasswordReady,
            };
        case ActionType.SAVE_TEAM_SEATS:
            return {
                ...newState,
                teamSeats: action.value ? action.value : [],
            };
        case ActionType.SAVE_PURCHASES_DATA:
            if ('error' in action.value) {
                return { ...newState, networkError: true, purchasesData: null };
            }
            return {
                ...newState,
                purchasesData: action.value,
            };
        case ActionType.SAVE_REGISTRATIONS:
            return {
                ...newState,
                listOfRegistrations: action.value,
            };
        case ActionType.SAVE_USER_INFO:
            return {
                ...newState,
                signedInUserName: action.userinfo.username,
                userEmail: action.userinfo.email,
                userInfo: action.userinfo,
            };
        case ActionType.SAVE_USER_MANAGED_LICENSES:
            return {
                ...newState,
                managedLicenses: action.licenses,
                teamManager: action.licenses.length > 0 ? true : false,
            };
        case ActionType.SAVE_CUS_AND_SUBS_INFO:
            return {
                ...newState,
                activeSubscriptionInfo: action.customer.subscriptions
                    ? action.customer.subscriptions.data
                    : null,
                customerInfo: action.customer,
            };
        case ActionType.SAVE_JUST_SUBSCRIPTION_INFO:
            return {
                ...newState,
                activeSubscriptionInfo: action.subscriptions,
            };
        case ActionType.SIGNIFY_NO_STRIPE_ID:
            return {
                ...newState,
                activeSubscriptionInfo: [],
            };
        case ActionType.ADD_PAYMENT_METHODS:
            return {
                ...newState,
                paymentMethodsOnFile: action.paymentMethods,
            };
        case ActionType.UPDATING_SUBSCRIPTIONS:
            return {
                ...newState,
                subscriptionsEditReady: !state.subscriptionsEditReady,
            };
        case ActionType.ADD_PAYMENT_METHODS:
            return {
                ...newState,
                paymentMethodsOnFile: action.paymentMethods,
            };
        case ActionType.EDIT_ACCOUNT:
            return {
                ...newState,
                accountsEditReady: true,
            };
        case ActionType.DONE_EDITING_ACCOUNTS:
            return {
                ...newState,
                accountsEditReady: false,
            };
        case ActionType.USER_LOGGED_OUT:
            return {
                ...initialState,
                accountsApiURL: state.accountsApiURL,
                accountsToken: '',
                purchaseapiURL: state.purchaseapiURL,
                signedIn: false,
                storeURL: state.storeURL,
                stripeKey: state.stripeKey,
            };
        case ActionType.DONE_EDITING_ACCOUNTS:
            return {
                ...newState,
                accountsEditReady: false,
            };
        case ActionType.ADD_API_URL:
            if (action.server === 'accounts') {
                return {
                    ...newState,
                    accountsApiURL: action.url,
                };
            } else {
                return {
                    ...newState,
                    purchaseapiURL: action.url,
                    storeURL: action.url.includes('test')
                        ? 'https://store.test.omnigroup.com/'
                        : 'https://store.omnigroup.com',
                };
            }
        case ActionType.ADD_SEAT_FOR_REMOVAL:
            return {
                ...newState,
                checkedSeatsForDeletion: [
                    ...state.checkedSeatsForDeletion,
                    action.value,
                ],
            };
        case ActionType.CLEAR_SEAT_REMOVAL_ARRAY:
            return {
                ...newState,
                checkedSeatsForDeletion: [],
            };

        case ActionType.REMOVE_SEAT_FROM_REMOVAL:
            const newArray = [...state.checkedSeatsForDeletion];
            const lookUp = action.value;
            const filtered = newArray.filter((value) => value !== lookUp);
            return {
                ...newState,
                checkedSeatsForDeletion: [...filtered],
            };
        case ActionType.ADD_SEAT_LIST_TO_ARRAY:
            const newListOfSeats = state.listOfSeats.filter(
                (subscription) => subscription.id !== action.value.id
            );

            return {
                ...newState,
                listOfSeats: [...newListOfSeats, action.value],
            };
        case ActionType.CLEAR_LIST_OF_SEATS:
            return {
                ...newState,
                listOfSeats: [],
            };
        case ActionType.REFRESH_USER_LICENSES:
            return {
                ...newState,
                refreshUserLicenses: !state.refreshUserLicenses,
            };
        case ActionType.REFRESH_THIS_SUBSCRIPTION:
            return {
                ...newState,
                refreshSubscriptionID: action.subscription,
                refreshToggle: !state.refreshToggle,
            };
        case ActionType.UNACTIVATED_UPGRADES:
            return {
                ...newState,
                unactivatedSeatUpgrades: action.registrations,
            }
        default:
            return {
                ...newState,
            };
    }
};

export default reducer;
