import * as React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const UpgradeWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid rgb(255, 204, 0);
    border-radius: 6px;
    padding: 10px;
`;

const UpgradeHeader = styled.div`
    font-size: 20px;
    font-weight: bold;
`;

const UpgradesList = styled.ul`
    align-self: flex-start;

    > li {
        padding: 10px;
    }
`;

const UpgradeLink = styled(Link)`
    text-decoration: none;
`

const ActivateUpgrades: React.FC = () => {
    const eligibleUpgrades = useSelector((state: OmniAccounts.GlobalReduxState) => state.unactivatedSeatUpgrades?.registrations);

    return (
        <UpgradeWrapper>
            <UpgradeHeader>Activate your upgrades</UpgradeHeader>
            <p>Seats need to be selected for these upgrades to be enabled.</p>
            <UpgradesList>
                {eligibleUpgrades?.map((upgrade) => {
                    return (
                        <li key={upgrade.registration_id}>{upgrade.upgrade_name} -
                            <UpgradeLink 
                                state={{from: upgrade}}
                                to="/license-upgrade"
                            >
                                Start upgrade &gt;
                            </UpgradeLink>
                        </li>
                    )
                })}
            </UpgradesList>
        </UpgradeWrapper>
    )
}

export default ActivateUpgrades;