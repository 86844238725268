interface PostSetupIntentProps {
    accountsToken: string;
    customer_id: string;
    purchaseapiURL: string;
    usage: string;
}

interface PostSetupIntentReturnI {
    cs: string;
    id: string;
}

export const postSetupIntent = async ({
    customer_id,
    usage = 'off_session',
    purchaseapiURL,
    accountsToken,
}: PostSetupIntentProps): Promise<PostSetupIntentReturnI> => {
    return await new Promise((resolve, reject) => {
        fetch(`${purchaseapiURL}/api/v2/customer/${customer_id}/setup_intent`, {
            body: JSON.stringify({
                usage: usage ? 'off_session' : null,
            }),
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${accountsToken}`,
                'Content-Type': 'application/json',
            },
            method: 'POST',
            mode: 'cors',
        })
            .then((response) => {
                return response.json();
            })
            .then((response) => {
                if (!response.errors) {
                    return resolve(response);
                } else if (response.errors) {
                    return resolve(response.errors);
                }
                return reject(true);
            })
            .catch((err) => {
                return reject(err);
            });
    });
};
