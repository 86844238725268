export interface PostActivateUpgradesProps {
    accountsApiURL: string;
    accountsToken: string;
    registration_id: string;
    seats: Array<string>;
    unassigned?: number;
}

interface ActivateUpgradesResponse {
    status: 'success' | 'error';
    //success response
    registration_id?: string;
    seats_moved?: number;
    unassigned?: number;
    //error response
    message?: string;
    attempted?: number;
    available?: number;

}

const postActivateUpgrades = async (props: PostActivateUpgradesProps): Promise<ActivateUpgradesResponse> => {
    return await new Promise((resolve, reject) => {
        fetch(`${props.accountsApiURL}/api/1.1/unactivated_registrations/`, {
            body: JSON.stringify({
                registration_id: props.registration_id,
                seats: props.seats,
                unassigned: props.unassigned,
            }),
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${props.accountsToken}`,
                'Content-Type': 'application/json',
            },
            method: 'POST',
            mode: 'cors',
        })
            .then((response) => resolve(response.json()))
            .catch((error) => {
                console.log('error activating upgrades', error);
                return reject(error);

            });  
    })  
};

export default postActivateUpgrades;