import * as React from 'react';
import styled from 'styled-components/macro';

const LeftSide = styled.div`
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    a {
        display: block;
        height: 100%;
    }
    img {
        height: 25px;
        display: flex;
    }
`;

const RightSide = styled.div`
    display: flex;
    flex-direction: row;
    margin-left: auto;
    @media (max-width: 768px) {
        display: none;
    }
`;

const DropdownIconContainer = styled.div`
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: #000;
    border: 1px solid #ffffff9e;
    border-radius: 0.25rem;
    margin-left: auto;
    width: auto;
    height: auto;
    display: none;
    flex-shrink: 0;
    cursor: pointer;

    @media (max-width: 768px) {
        display: block;
    }
`;

const DropdownIcon = styled.div`
    display: none;
    width: 30px;
    height: 30px;
    vertical-align: middle;
    content: '';
    background: no-repeat center center;
    background-size: 100% 100%;
    color: rgba(255, 255, 255, 0.5);
    border-color: rgba(255, 255, 255, 0.1);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");

    @media (max-width: 768px) {
        display: block;
    }
`;

const DropdownElements = styled.ul`
    display: flex;
    position: absolute;
    flex-direction: column;
    margin: 5px 0 0;
    z-index: 1000;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
    padding-left: 0;
    min-width: 12rem;

    li {
        margin: 5px 0;
        width: 100%;
        padding: 0;

        &:hover {
            background-color: #f8f9fa;
        }
        &:active {
            background-color: #007bff;
        }

        a {
            margin: 0 10px;
        }
    }
    a {
        text-decoration: none;
    }
`;

const TopDropdown = styled.li`
    margin-left: 15px;
    margin-right: 15px;
    list-style: none;
    box-sizing: border-box;
    position: relative;
    cursor: pointer;

    &::after {
        display: inline-block;
        margin-left: 0.255em;
        content: '';
        border-top: 0.3em solid;
        border-right: 0.3em solid transparent;
        border-bottom: 0;
        border-left: 0.3em solid transparent;
        border-top: 7px solid #3a9ff2;
        border-right: 5px solid transparent;
        border-bottom: 0 dotted;
        border-left: 5px solid transparent;
        vertical-align: 0em !important;
        color: #3a9ff2;
    }

    @media (max-width: 768px) {
        display: none;
    }
`;

interface SmallerMenuProps {
    showOrHide: boolean;
}

const SmallerMenu = styled.ul<SmallerMenuProps>`
    position: absolute;
    left: 0;
    top: 40px;
    color: #fff;
    background-color: #000;
    width: 100%;
    height: ${(props): string => (!props.showOrHide ? '0px' : '560px')};
    transition: height 0.2s ease-out;
    font-size: 18px;
    font-weight: 600;
    z-index: 2000;
    padding: 0;
    margin: 10px 0 10px 0;
    overflow: hidden;

    ul {
        padding-left: 0;
        margin: 10px 0;
        padding: 10px 20px;
        word-break: break-all;
    }

    li,
    a {
        list-style-type: none;
        color: #fff;
        font-size: 16px;
        font-weight: 500;
    }
`;

const OmniHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #000;
    border-color: #666;
    border-bottom: solid #666;
    border-width: 2px;
    color: #fff;
    font-size: 16px;
    padding: 5px 20px;
    justify-content: space-between;
    height: auto;
`;

interface OmniMenuProps {
    simple?: boolean;
}

const OmniMenu: React.FC<OmniMenuProps> = () => {
    const [productDropdown, setProductDropdown] = React.useState(false);
    const [companyDropdown, setCompanyDropdown] = React.useState(false);
    const [helpDropdown, setHelpDropdown] = React.useState(false);
    const [smallerMenu, toggleSmallerMenu] = React.useState(false);

    const node = React.useRef<HTMLDivElement>(null);
    const handleClick = (ev: MouseEvent): void => {
        const target = ev.target as HTMLElement;
        if (node !== null && node.current !== null) {
            if (node?.current.contains(target)) {
                return;
            }
            // outside click
            setProductDropdown(false);
            setCompanyDropdown(false);
            setHelpDropdown(false);
        }
    };

    const handleDropdownClick = (item: string): void => {
        switch (item) {
            case 'product':
                setCompanyDropdown(false);
                setHelpDropdown(false);
                setProductDropdown(true);
                return;
            case 'company':
                setCompanyDropdown(true);
                setHelpDropdown(false);
                setProductDropdown(false);
                return;
            case 'help':
                setHelpDropdown(true);
                setCompanyDropdown(false);
                setProductDropdown(false);
                return;
            default:
                setCompanyDropdown(false);
                setHelpDropdown(false);
                setProductDropdown(false);
                return;
        }
    };

    React.useEffect(() => {
        document.addEventListener('mousedown', handleClick);
        // return function to be called when unmounted
        return (): void => {
            document.removeEventListener('mousedown', handleClick);
        };
    }, []);

    return (
        <OmniHeader ref={node}>
            <LeftSide>
                <a href='https://www.omnigroup.com/'>
                    <img src='/logo_white.png' alt='Omni Group logo' />
                </a>

                <TopDropdown
                    onClick={(): void => handleDropdownClick('product')}
                >
                    Products
                    {productDropdown && (
                        <DropdownElements>
                            <li>
                                <a href='https://www.omnigroup.com/omnifocus'>
                                    OmniFocus
                                </a>
                            </li>
                            <li>
                                <a href='https://www.omnigroup.com/omnigraffle'>
                                    OmniGraffle
                                </a>
                            </li>
                            <li>
                                <a href='https://www.omnigroup.com/omnioutliner'>
                                    OmniOutliner
                                </a>
                            </li>
                            <li>
                                <a href='https://www.omnigroup.com/omniplan'>
                                    OmniPlan
                                </a>
                            </li>
                            <li>
                                <a href='https://www.omnigroup.com/more'>
                                    More Apps
                                </a>
                            </li>
                            <li>
                                <a href='https://www.omnigroup.com/downloads'>
                                    Downloads
                                </a>
                            </li>
                        </DropdownElements>
                    )}
                </TopDropdown>
            </LeftSide>
            <RightSide>
                <TopDropdown
                    onClick={(): void => handleDropdownClick('company')}
                    style={{ marginLeft: '0 0 0 auto' }}
                >
                    Company
                    {companyDropdown && (
                        <DropdownElements>
                            <li>
                                <a href='https://www.omnigroup.com/blog'>
                                    Blog
                                </a>
                            </li>
                            <li>
                                <a href='https://theomnishow.omnigroup.com/'>
                                    The Omni Show
                                </a>
                            </li>
                            <li>
                                <a href='https://www.omnigroup.com/letters'>
                                    Newsletter
                                </a>
                            </li>
                            <li>
                                <a href='https://www.omnigroup.com/about'>
                                    About Us
                                </a>
                            </li>
                        </DropdownElements>
                    )}
                </TopDropdown>
                <TopDropdown onClick={(): void => handleDropdownClick('help')}>
                    Help
                    {helpDropdown && (
                        <DropdownElements style={{ right: '1px' }}>
                            <li>
                                <a href='https://support.omnigroup.com/'>
                                    Support
                                </a>
                            </li>
                            <li>
                                <a href='https://stenciltown.omnigroup.com/'>
                                    Stenciltown
                                </a>
                            </li>
                            <li>
                                <a href='https://www.omnigroup.com/video'>
                                    Videos
                                </a>
                            </li>
                            <li>
                                <a href='https://inside.omnifocus.com/'>
                                    Inside OmniFocus
                                </a>
                            </li>
                            <li>
                                <a href='https://inside.omnigraffle.com/'>
                                    Inside OmniGraffle
                                </a>
                            </li>
                        </DropdownElements>
                    )}
                </TopDropdown>
            </RightSide>
            <DropdownIconContainer
                onClick={(): void => toggleSmallerMenu(!smallerMenu)}
            >
                <DropdownIcon />
            </DropdownIconContainer>
            <SmallerMenu showOrHide={smallerMenu}>
                <ul>
                    Products
                    <li>
                        <a href='https://www.omnigroup.com/omnifocus'>
                            OmniFocus
                        </a>
                    </li>
                    <li>
                        <a href='https://www.omnigroup.com/omnigraffle'>
                            OmniGraffle
                        </a>
                    </li>
                    <li>
                        <a href='https://www.omnigroup.com/omnioutliner'>
                            OmniOutliner
                        </a>
                    </li>
                    <li>
                        <a href='https://www.omnigroup.com/omniplan'>
                            OmniPlan
                        </a>
                    </li>
                    <li>
                        <a href='https://www.omnigroup.com/more'>More Apps</a>
                    </li>
                    <li>
                        <a href='https://www.omnigroup.com/downloads'>
                            Downloads
                        </a>
                    </li>
                </ul>
                <ul>
                    Company
                    <li>
                        <a href='https://www.omnigroup.com/blog'>Blog</a>
                    </li>
                    <li>
                        <a href='https://theomnishow.omnigroup.com/'>
                            The Omni Show
                        </a>
                    </li>
                    <li>
                        <a href='https://www.omnigroup.com/letters'>
                            Newsletter
                        </a>
                    </li>
                    <li>
                        <a href='https://www.omnigroup.com/about'>About Us</a>
                    </li>
                </ul>
                <ul>
                    Help
                    <li>
                        <a href='https://support.omnigroup.com/'>Support</a>
                    </li>
                    <li>
                        <a href='https://stenciltown.omnigroup.com/'>
                            Stenciltown
                        </a>
                    </li>
                    <li>
                        <a href='https://www.omnigroup.com/video'>Videos</a>
                    </li>
                    <li>
                        <a href='https://inside.omnifocus.com/'>
                            Inside OmniFocus
                        </a>
                    </li>
                    <li>
                        <a href='https://inside.omnigraffle.com/'>
                            Inside OmniGraffle
                        </a>
                    </li>
                </ul>
            </SmallerMenu>
        </OmniHeader>
    );
};

export default OmniMenu;
