import Stripe from 'stripe';

interface DetachPaymentMethodResponse {
    response: Stripe.PaymentMethod;
    errors?: boolean;
}

export const detachPaymentMethod = async (
    purchaseapiURL: string,
    customerID: string,
    pm_id: string,
    accountsToken: string
): Promise<DetachPaymentMethodResponse> => {
    return await fetch(
        `${purchaseapiURL}/api/v2/customer/${customerID}/payment_method/${pm_id}/detach`,
        {
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + accountsToken,
                'Content-Type': 'application/json',
            },
            method: 'POST',
        }
    )
        .then((response) => response.json())

        .then((response) => {
            if (response.errors) {
                return { errors: true };
            }
            return response;
        })
        .catch((err) => {
            return err;
        });
};
