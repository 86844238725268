

interface GetCustomerSeatsResponse {
    seats: Array<OmniAccounts.SeatObject>;
}

export const getCustomerSeats = async (
    accountsApiURL: string,
    accountsToken: string
): Promise<GetCustomerSeatsResponse> => {
    return await new Promise((resolve, reject) => {
        fetch(`${accountsApiURL}/api/1.1/seats/`, {
            body: JSON.stringify({ show_personal_seats: false }),
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + accountsToken,
                'Content-Type': 'application/json',
            },
            method: 'POST',
            mode: 'cors',
        })
            .then((response) => {
                return resolve(response.json());
            })
            .catch((err) => {
                console.log('err', err);
                return reject(err);
            });
    });
};
