import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { ActionType } from './store/types';

import App from './App';
import SignUp from './components/SignUp';
import FourOhFour from './components/FourOh';
import Header from './components/Header';
import FooterWrapper from './reusable-components/Footer';
import ResetPassword from './components/ResetPassword';
import Reset from './components/Reset';
import ManageTeam from './components/licensemanagement/ManageTeam';
import ManageUpgrade from './components/licenseUpgrades/ManageUpgrade';

import grabListOfSeatsForID from './utilities/grabListOfSeatsForID';
import { managedSubscriptionData, purchasesData, userJSON } from './utilities/getUserData';
import { getStripeEnvironmentConfiguration } from './utilities/getStripeEnvironmentConfiguration';
import getPaymentMethods from './utilities/getPaymentMethods';
import { getSeatData } from './utilities/postGrabSeats';
import { getStripeCustomerData } from './utilities/getStripeCustomerData';
import { getConfiguration } from './utilities/getConfiguration';


const AppRouter: React.FC = () => {
    const dispatch = useDispatch();
    const [errorLanguage, setErrorLanguage] = React.useState<string>('');
    const purchaseapiURL = useSelector((state: OmniAccounts.GlobalReduxState) => state.purchaseapiURL );
    const accountsApiURL = useSelector((state: OmniAccounts.GlobalReduxState) => state.accountsApiURL );
    const accountsToken = useSelector( (state: OmniAccounts.GlobalReduxState) => state.accountsToken );
    const customerInfo = useSelector((state: OmniAccounts.GlobalReduxState) => state.customerInfo);
    const updateUserCall = useSelector((state: OmniAccounts.GlobalReduxState) => state.updateUserCall );
    const updateCounter = useSelector((state: OmniAccounts.GlobalReduxState) => state.updateCounter);
    const managedLicenses = useSelector((state: OmniAccounts.GlobalReduxState) => state.managedLicenses);
    const readyToEditCards = useSelector((state: OmniAccounts.GlobalReduxState) => state.readyToEditCards );
    const userInfo = useSelector((state: OmniAccounts.GlobalReduxState) => state.userInfo);

    useEffect(() => {
        if (errorLanguage.length >= 1) {
            console.error('error', errorLanguage);
        }
    }, [errorLanguage]);

    useEffect(() => {
        if (accountsApiURL !== '' && accountsToken !== '') {
            getSeatData(accountsApiURL, accountsToken)
                .then((response) => dispatch({ type: ActionType.SAVE_TEAM_SEATS, value: response.seats }))
                .catch((error) => console.log('Error when saving seats', error) );

            purchasesData({ accountsToken, accountsURL: accountsApiURL })
                .then((response) => {
                    if (response.purchases) {
                        dispatch({ 
                            type:  ActionType.SAVE_PURCHASES_DATA, 
                            value: response.purchases 
                        });
                }})
                .catch((error) => console.error('Error when saving purchasesData: ', error));

            managedSubscriptionData({ accountsToken, accountsURL: accountsApiURL })
                .then((response) => {
                    if (response.managed_team_licenses) {
                        dispatch({ 
                            licenses: response.managed_team_licenses, 
                            type:  ActionType.SAVE_USER_MANAGED_LICENSES, 
                        });
                }})
                .catch((error) => console.error('Error when saving managed team licenses: ', error));

            userJSON({ accountsToken, accountsURL: accountsApiURL })
                .then((response) => {
                    if (response.errors) {
                        dispatch({ type:  ActionType.USER_LOGGED_OUT });
                        localStorage.clear();

                        switch (response.errors[0].code) {
                            case 30003: //They need to go to accounts admin to do anything
                                setErrorLanguage('Something went wrong while signing in.');
                                break;
                            case 3002: //they got a bad username or password
                                setErrorLanguage(response.errors[0].message);
                                break;
                            case 1002: //they have a bad token
                                setErrorLanguage('Your session has expired. Please sign in again.');
                                localStorage.removeItem('accountsauth');
                                dispatch({ type:  ActionType.USER_LOGGED_OUT });
                                break;
                            case 'invalid_grant':
                                setErrorLanguage('Something went wrong while signing in. Please email support.');
                                break;
                            default:
                                setErrorLanguage(response.errors[0].message);
                                break;
                        }
                        return;
                    } else if (!response.errors && response.user) {
                        dispatch({  
                            type:  ActionType.SAVE_USER_INFO, 
                            userinfo: response.user 
                        });
                    }
                })
                .catch((error) => {
                    console.log('Caught error when saving user info', error);
                    dispatch({ type:  ActionType.USER_LOGGED_OUT });
                    localStorage.clear();
                });
        }
    }, [accountsApiURL, accountsToken]);

    useEffect(() => {
        const setEnvironmentAndWrapAwait = async () => {
            const accountsAPIURL = process.env.REACT_APP_ACCOUNTS_HOST
                ?  process.env.REACT_APP_ACCOUNTS_HOST
                : window.location.host.toString().indexOf("test") != -1
                ? 'https://accounts.test.omnigroup.com'
                : window.location.host.toString().indexOf("stage") != -1
                ? 'https://accounts.stage.omnigroup.com'
                : 'https://accounts.omnigroup.com';

            const purchaseAPIURL = process.env.REACT_APP_PURCHASE_HOST
                    ? process.env.REACT_APP_PURCHASE_HOST
                    : window.location.host.toString().indexOf("test") != -1
                    ? 'https://purchase.test.omnigroup.com'
                    : window.location.host.toString().indexOf("stage") != -1
                    ? 'https://purchase.stage.omnigroup.com'
                    : 'https://purchase.omnigroup.com';


            dispatch({
                server: 'accounts',
                type:  ActionType.ADD_API_URL,
                url: accountsAPIURL,
            });
            dispatch({
                server: 'purchase',
                type:  ActionType.ADD_API_URL,
                url: purchaseAPIURL,
            });

            if (purchaseAPIURL !== undefined) {
                const stripeKey = await getStripeEnvironmentConfiguration(
                    purchaseAPIURL
                );
                dispatch({
                    publishable_key: stripeKey.publishable_key,
                    type:  ActionType.ADD_STRIPE_KEY,
                });
            }
            getConfiguration().then((result) =>
                console.log('Revision: ', result.revision)
            );
            console.log('Purchase: ', purchaseAPIURL);
            console.log('Accounts: ', accountsAPIURL);
        };

        setEnvironmentAndWrapAwait();
    }, [dispatch]);

    useEffect(() => {
        if (userInfo === null) return;
        if (userInfo.customer_id !== undefined && accountsToken !== '' && purchaseapiURL !== '' ) {
            getStripeCustomerData( accountsToken, userInfo.customer_id[0], purchaseapiURL)
                .then((response) => {
                    if (response.errors === undefined) {
                        dispatch({ 
                            customer: response.customer, 
                            type:  ActionType.SAVE_CUS_AND_SUBS_INFO,  
                        });
                    } else if (response.errors[0].code !== undefined) {
                        setErrorLanguage(response.errors[0].message);
                    }
                })
                .catch((error) => console.log(error));
        } else {
            dispatch({type:  ActionType.SIGNIFY_NO_STRIPE_ID });
        }
    }, [dispatch, userInfo, purchaseapiURL, accountsToken, updateUserCall]);

    useEffect(() => {
        if (managedLicenses !== undefined && accountsToken !== '') {
            managedLicenses.map(async (license: OmniAccounts.LicenseObject) => {
                const listOfSeats = await grabListOfSeatsForID(accountsApiURL, license.id, accountsToken );

                if (listOfSeats.seats !== undefined) {
                    dispatch({ 
                        type:  ActionType.ADD_SEAT_LIST_TO_ARRAY, 
                        value: { id: license.id, seats: listOfSeats.seats.map((seat) => seat) }, 
                    });
                }
            });
        }
        return;
    }, [accountsApiURL, accountsToken, dispatch, managedLicenses]);

    useEffect(() => {
        if (updateCounter !== 0) {
            managedSubscriptionData({ accountsToken, accountsURL: accountsApiURL})
                .then((response) => {
                    if ( response.managed_team_licenses !== undefined && !response.err ) {
                        if (response.managed_team_licenses.length !== 0) {
                            dispatch({
                                licenses: response.managed_team_licenses,
                                type:  ActionType.SAVE_USER_MANAGED_LICENSES, 
                            });
                        }
                    }
                });

            purchasesData({accountsToken,  accountsURL: accountsApiURL})
                .then((response) => {
                    if (response.purchases !== undefined) {
                        dispatch({
                            type:  ActionType.SAVE_PURCHASES_DATA,
                            value: response.purchases,
                        });
                    }
                });

            {
                /*             registeredData({
                accountsToken,
                accountsURL: accountsApiURL,
            }).then((response) => {
                if (
                    response.status === undefined &&
                    response.error === undefined
                ) {
                    dispatch({
                        type: SAVE_REGISTRATIONS,
                        value: response.registrations,
                    });
                }
            }); */
            }
        }
    }, [updateCounter, updateUserCall]);

    useEffect(() => {
        if (customerInfo !== undefined) {
            getPaymentMethods(purchaseapiURL, customerInfo.id, accountsToken)
                .then((response) => {
                    if (response.errors === undefined) {
                        dispatch({
                            paymentMethods: response.paymentMethods,
                            type:  ActionType.ADD_PAYMENT_METHODS,
                        });
                    } else {
                        console.log(
                            'Something went wrong. We had an error retrieving a card: ',
                            response.errors[0].message
                        );
                    }
                })
                .catch((error) => console.log(error));
        } else {
            return;
        }
    }, [
        readyToEditCards,
        purchaseapiURL,
        customerInfo,
        accountsToken,
        dispatch,
        updateCounter,
    ]);

    const refreshToggle = useSelector((state: OmniAccounts.GlobalReduxState) => state.refreshToggle );
    const refreshSubscriptionID = useSelector((state: OmniAccounts.GlobalReduxState) => state.refreshSubscriptionID );

    useEffect(() => {
        if (refreshSubscriptionID === '') return;
        if (managedLicenses !== undefined && accountsToken !== '') {
            managedLicenses.map(async (license: OmniAccounts.LicenseObject) => {
                const listOfSeats = await grabListOfSeatsForID(accountsApiURL, license.id, accountsToken);

                if (listOfSeats.seats !== undefined) {
                    dispatch({
                        type:  ActionType.ADD_SEAT_LIST_TO_ARRAY,
                        value: { id: license.id, seats: listOfSeats.seats.map((seat) => seat) },
                    });
                }
            });

            managedSubscriptionData({ accountsToken, accountsURL: accountsApiURL })
                .then((response) => {
                    if ( response.managed_team_licenses !== undefined && !response.err ) {
                        if (response.managed_team_licenses.length !== 0) {
                            dispatch({
                                licenses: response.managed_team_licenses,
                                type:  ActionType.SAVE_USER_MANAGED_LICENSES,
                            });
                        }
                    }
                });
        }
    }, [refreshToggle, refreshSubscriptionID]);

    return (
        <Router>
            <Helmet>
                <title>Manage Your Omni Account - The Omni Group</title>
            </Helmet>
            <Header />
            <Routes>
                <Route path='/' element={<App />} />
                <Route path='/confirm-invite/' element={<App newFlow={true} />} />
                <Route path='/team-subscription/' element={<App teamSubscription />} />
                <Route path='/manage-team' element={<ManageTeam />} />
                <Route path='/license-upgrade' element={<ManageUpgrade />} />
                <Route path='/register' element={<SignUp />} />
                <Route path='/reset' element={<Reset />} />
                <Route path='/four-oh' element={<FourOhFour />} />
                <Route path='/password-reset' element={<ResetPassword />} />
                <Route element={<FourOhFour />} />
            </Routes>
            <FooterWrapper />
        </Router>
    );
};

export default AppRouter;
