import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import AddEmail from './AddEmail';
import SeatTable from './SeatTable';
import { Button, ButtonNeuComp } from '../../reusable-components/Button';
import deleteEmails from './utilities/deleteEmails';
import ErrorBox from '../../reusable-components/ErrorBox';
import Modal from 'react-modal';

import { modalStyles } from './modalStyles';
import { ActionType } from '../../store/types';

const Meta = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0;
    width: auto;
    color: #404040;
`;

const SeatsInfo = styled.div`
    display: flex;
    flex-direction: row;
    padding: 0;
    margin: 0;
    font-size: 16px;
    align-items: center;
    font-weight: 500;
    justify-content: space-between;
    border-bottom: 1px solid rgba(212, 207, 199, 1);
`;

const TotalSeats = styled.div`
    margin: 10px 40px 20px 0;
`;

const UsedSeats = styled.div`
    margin: 10px 10px 20px 0;
`;

const SubscriptionHeader = styled.div`
    h3 {
        font-size: 36px;
        font-weight: 700;
        text-transform: initial;
    }

    border-bottom: 1px solid rgba(212, 207, 199, 1);
    padding: 10px 0;
    margin-bottom: 10px;
`;

const Buttons = styled.div`
    display: flex;
    flex-direction: row;
    margin: 10px 0;
    justify-content: center;
`;

interface EditLicenseSeatsProps {
    currentObject: OmniAccounts.LicenseObject;
}

const EditLicenseSeats: React.FC<EditLicenseSeatsProps> = (props: EditLicenseSeatsProps) => {
    Modal.setAppElement('#root');
    const dispatch = useDispatch();

    const [error, setError] = useState<string>('');
    const [emailError, setEmailError] = useState<string>('');
    const [positive, setIAmPositive] = useState<boolean>(false);

    const [listOfEmails, setListOfEmails] = useState<Array<{ email: string; status: string }> | undefined>();

    const accountsApiURL = useSelector((state: OmniAccounts.GlobalReduxState) => state.accountsApiURL);
    const accountsToken = useSelector((state: OmniAccounts.GlobalReduxState) => state.accountsToken);
    const checkedSeatsForDeletion = useSelector((state: OmniAccounts.GlobalReduxState) => state.checkedSeatsForDeletion);
    const listOfSeats = useSelector((state: OmniAccounts.GlobalReduxState) => state.listOfSeats);

    const teamPageIDToEdit = useSelector((state: OmniAccounts.GlobalReduxState) => state.teamPageIDToEdit);
    const userInfo = useSelector((state: OmniAccounts.GlobalReduxState) => state.userInfo);

    const interimDeleteAgree = () => {
        setIAmPositive(false);
        if (!teamPageIDToEdit) return;

        deleteEmails(
            checkedSeatsForDeletion,
            accountsToken,
            accountsApiURL,
            props.currentObject.id
        )
            .then((result) => {
                if ('invalid_seats' in result) {
                    setEmailError(result.message);
                    setListOfEmails(result.invalid_seats?.map((seat) => seat));
                    return { errorString: true };
                }
                return false;
            })
            .then(() => dispatch({ type: ActionType.CLEAR_SEAT_REMOVAL_ARRAY }))
            .then(() => dispatch({ type: ActionType.CLEAR_LIST_OF_SEATS }))
            .then(() =>
                dispatch({
                    subscription: teamPageIDToEdit,
                    type: ActionType.REFRESH_THIS_SUBSCRIPTION,
                })
            );
    };

    if (!teamPageIDToEdit || !props.currentObject || !userInfo) {
        return null;
    }

    const emailErrorModal = emailError ? (
        <ErrorBox errorTitle={'Error removing seats'}>
            <span
                style={{
                    margin: '0 10px',
                    textDecoration: 'underline',
                }}
                onClick={() => {
                    setError('');
                    setListOfEmails(undefined);
                }}
            >
                Close
            </span>
            <p style={{ margin: '1em' }}>{error}</p>
            <ul>
                {listOfEmails && listOfEmails.map((item) => (
                    <li
                        key={item.email}
                        style={{ margin: '5px 20px' }}
                    >
                        {item.email}
                    </li>
                ))}
            </ul>
        </ErrorBox>
    ) : null;

    const seatList = listOfSeats.filter((seat) => seat.id === props.currentObject.id).map((seat) => seat.seats)[0];

    return (
        <React.Fragment key={props.currentObject.id}>
            <Meta>
                <SubscriptionHeader>
                    <h3>
                        { props.currentObject.description === 'OmniOutliner 5 for Mac'
                            ? 'OmniOutliner 5 Essentials for Mac'
                            : props.currentObject.description }
                    </h3>
                    { props.currentObject.subscription_id && (
                        <span>Includes {props.currentObject.products}</span>
                    )}
                </SubscriptionHeader>
                <SeatsInfo>
                    <TotalSeats>
                        <strong>Total Seats</strong>:{' '}
                        {props.currentObject.total_seats}
                    </TotalSeats>
                    <UsedSeats>
                        <strong>Used Seats:</strong>{' '}
                        {props.currentObject.used_seats}
                    </UsedSeats>
                </SeatsInfo>
            </Meta>

            <AddEmail
                currentlyEditingID={props.currentObject.id}
                currentObject={props.currentObject}
                showDisabled={props.currentObject.available_seats <= 0}
            />
            { emailErrorModal }
            <SeatTable
                currentObject={props.currentObject}
                currentlyEditingID={props.currentObject.id}
                seatList={seatList}
                subscription={false}
            />
            <div>
                <Button
                    onClick={() => setIAmPositive(true)}
                    showDisabled={checkedSeatsForDeletion.length === 0 ? true : false}
                >
                    Remove User(s)
                </Button>
            </div>

            <Modal isOpen={positive} style={modalStyles}>
                <h2>Confirm Removal</h2>
                <p>
                    Are you <em>sure</em> you want to remove{' '}
                    {checkedSeatsForDeletion.length >= 2
                        ? 'these users'
                        : 'this user'}{' '}
                    from the team?
                </p>
                <Buttons>
                    <ButtonNeuComp
                        primary={false}
                        onClick={() => setIAmPositive(false)}
                    >
                        No!
                    </ButtonNeuComp>
                    <ButtonNeuComp primary onClick={() => interimDeleteAgree()}>
                        Yes
                    </ButtonNeuComp>
                </Buttons>
            </Modal>
        </React.Fragment>
    );
};

export default EditLicenseSeats;
