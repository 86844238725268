import * as React from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { List } from 'react-content-loader';

import HoldingBox from '../reusable-components/HoldingBox';
import CardDisplay from './CardDisplay';
import { Button } from '../reusable-components/Button';
import { getStripeSession } from '../utilities/getStripeSession';
import { ActionType } from '../store/types';

const Invoices = styled.div`
    margin: 20px 0;

    button {
        margin: 20px 0;
    }
`;

const Header = styled.div`
    font-weight: bold;
    padding-bottom: 20px;
`;

const Subtext = styled.div`
    padding-bottom: 20px;
`; 

const Payment: React.FC = () => {
    const dispatch = useDispatch();

    const listOfPurchases = useSelector((state: OmniAccounts.GlobalReduxState) => state.purchasesData);
    const paymentMethodsOnFile = useSelector((state: OmniAccounts.GlobalReduxState) => state.paymentMethodsOnFile);
    const accountsToken = useSelector((state: OmniAccounts.GlobalReduxState) => state.accountsToken);
    const purchaseapiURL = useSelector((state: OmniAccounts.GlobalReduxState) => state.purchaseapiURL);
    const customerInfo = useSelector((state: OmniAccounts.GlobalReduxState) => state.customerInfo);
    const readyToEditCards = useSelector((state: OmniAccounts.GlobalReduxState) => state.readyToEditCards);
    const [loading, setLoading] = React.useState<boolean>(false);

    const customerPortalClick = async (e: React.SyntheticEvent) => {
        e.preventDefault();
        setLoading(true);

        if (customerInfo === undefined) return;

        await getStripeSession(
            accountsToken,
            customerInfo.id,
            purchaseapiURL
        ).then((call) => {
            if (call.session !== undefined) {
                window.location.href = call.session.url;
            }
        });
        setLoading(false);
    };

    return (
        <HoldingBox
            sectionTitle='Payment'
            actionTitle='Edit'
            clickAction={() => dispatch({ type: ActionType.UPDATING_CARD })}
            cancelAction={() => dispatch({ type: ActionType.UPDATING_CARD })}
            activeWatcher={readyToEditCards}
        >
            <Header>Payment Methods</Header>
            <Subtext>Credit cards that are linked to invoices or subscriptions.</Subtext>

            {!listOfPurchases || !customerInfo ? (
                <List />
            ) : (
                <div>
                    <CardDisplay
                        listOfPaymentMethods={paymentMethodsOnFile}
                        editMode={readyToEditCards}
                        purchaseAPI={purchaseapiURL}
                        customerID={customerInfo.id}
                        accountsAccessToken={accountsToken}
                    />
                    {listOfPurchases.filter(
                        (purchase: OmniAccounts.LicenseObject) =>
                            purchase.type === 'renewable'
                    ).length >= 1 && (
                        <Invoices>
                            <Header>Invoices</Header>
                            <Subtext>
                                View and download past invoices for
                                subscriptions.
                            </Subtext>
                            <Button
                                onClick={(e: React.SyntheticEvent) =>
                                    customerPortalClick(e)
                                }
                                type='submit'
                                showLoading={loading}
                            >
                                View Invoices
                            </Button>
                        </Invoices>
                    )}
                </div>
            )}
        </HoldingBox>
    );
};

export default Payment;
