import React from 'react';
import Stripe from 'stripe';

import { useTheme } from './ThemeContext';
import CancelSubscription from './CancelSubscription';
import ChangeSubscription from './ChangeSubscription';
import SubscriptionMenu from './SubscriptionMenu';
import Portal from '../../reusable-components/Portal';

interface ModifySubscriptionProps {
    toggleModal: (toggle: boolean) => void;
    subscription: Stripe.Subscription;
    subscriptionType: 'month' | 'year' | null;
    teamId: null | number;
}

const ModifySubscriptionPortal: React.FC<ModifySubscriptionProps> = (props) => {
    const { 
        cancelModal, 
        changeModal, 
        subscriptionMenu, 
        currentSubscription,
    } = useTheme();

    const subscriptionMenuPortal = (subscriptionMenu && !cancelModal && !changeModal) && (
        <Portal modifySubscription={true}>
            <SubscriptionMenu
                subscription={currentSubscription as Stripe.Subscription}
                subscriptionType={props.subscriptionType as 'month' || 'year'}
                toggleModal={props.toggleModal}
                teamId={props.teamId}
            />
        </Portal>
    );

    const changeSubscriptionPortal = (changeModal && !subscriptionMenu && !cancelModal) && (
        <Portal modifySubscription={true}>
            <ChangeSubscription 
                subscription={currentSubscription as Stripe.Subscription}
                subscriptionType={props.subscriptionType as 'month' || 'year'}
                toggleEditModal={props.toggleModal}
            />
        </Portal>
    );

    const cancelSubscriptionPortal = (cancelModal && !subscriptionMenu && !changeModal) && (
        <Portal modifySubscription={true}>
            <CancelSubscription
                subscription={currentSubscription as Stripe.Subscription}
                toggleModal={props.toggleModal}
            />
        </Portal>
    )

    return (
        <div>
            { subscriptionMenuPortal }
            { changeSubscriptionPortal }
            { cancelSubscriptionPortal }
        </div>
    )
}

export default ModifySubscriptionPortal;

