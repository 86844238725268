export interface PostUpdateSubscriptionI {
    accountsToken: string;
    billing_cycle_anchor?: number;
    cancel_at_period_end?: boolean;
    default_tax_rates?: Array<string>;
    plan?: string;
    proration_behavior?: 'create_prorations' | 'none' | 'always_invoice';
    quantity?: number;
    customerID: string;
    purchaseapiURL: string;
    subscriptionID: string;
}

export interface PostUpdateSubscriptionReturnI {
    messsage: string;
}

export const postUpdateSubscription = async (
    props: PostUpdateSubscriptionI
): Promise<PostUpdateSubscriptionReturnI> =>
    new Promise((resolve, reject) => {
        fetch(
            `${props.purchaseapiURL}/api/v2/customer/${props.customerID}/subscription/${props.subscriptionID}`,
            {
                body: JSON.stringify({
                    cancel_at_period_end: props.cancel_at_period_end,
                    default_tax_rates: props.default_tax_rates,
                    plan: props.plan,
                    proration_behavior: props.proration_behavior,
                    quantity: props.quantity,
                }),
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${props.accountsToken}`,
                    'Content-Type': 'application/json',
                },
                method: 'POST',
                mode: 'cors',
            }
        )
            .then((response) =>
                Promise.resolve(response.json()).then((res) => {
                    if (!res.errors) {
                        resolve(res);
                    } else if (res.errors) {
                        return reject(new Error(res.errors));
                    }
                    return reject(new Error(res));
                })
            )
            .catch((err) => {
                // global error dispatch?
                reject(new Error(err));
            });
    });
