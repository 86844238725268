import React, { useState, useReducer } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { Button } from '../reusable-components/Button';

const PasswordResetContainer = styled.div`
    max-width: 400px;
    margin: 30px auto;
`;

const ErrorBox = styled.p`
    text-align: center;
    max-width: 300px;
    padding: 10px 20px;
    margin: 10px auto;
    background-color: #ffff0085;
    border-radius: 8px;
`;

const ReturnToSignIn = styled.a`
    color: #404040;
`;

interface State {
    errorLanguage: string;
    loading: boolean;
    success: boolean;
}

interface Action {
    type: 'reset_password_loading' | 'reset_password_success' | 'errorCode_3027' | 'other_errorCodes';
}

const reducerFunc = (state: State, action: Action) => {
    switch (action.type) {
        case 'reset_password_loading':
            return { ...state, loading: true }
        case 'reset_password_success':
            return {
                errorLanguage: '',
                loading: false,
                success: true,
            }
        case 'errorCode_3027':
            return {
                errorLanguage: 'Sorry, no account exists with that address.',
                loading: false,
                success: false,
            };
        case 'other_errorCodes':
            return {
                errorLanguage: 'Sorry, we were unable to properly send a Password Reset email. Double-check the email address is correct, then try again.',
                loading: false,
                success: false,
            }
        default: 
            return state;
    }
}

const ResetPassword: React.FC = () => {
    const [ state, dispatch ] = useReducer(reducerFunc, { 
        errorLanguage: '',
        loading: false,
        success: false,
    })
    const [accountEmail, setAccountEmail] = useState<string>('');
    const accountsApiURL = useSelector((state: OmniAccounts.GlobalReduxState) => state.accountsApiURL);

    const postResetPassword = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        dispatch({ type: 'reset_password_loading' });

        const passwordReset = await fetch(`${accountsApiURL}/api/1.1/password_reset/`, {
            body: JSON.stringify({
                email_address: accountEmail,
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            method: 'POST',
            mode: 'cors',
        });
        
        
        try {
            const passwordResetResponse = await passwordReset.json();
            
            if (passwordResetResponse.results === 'success') {
                dispatch({ type: 'reset_password_success' })
            } else if (passwordResetResponse.errors) {
                const errorCode = passwordResetResponse.errors[0].code;

                if (errorCode === 3027) {
                    dispatch({ type: 'errorCode_3027' });
                } else {
                    dispatch({ type: 'other_errorCodes' });
                }
            } else {
                dispatch({ type: 'other_errorCodes' });
            }
        } catch (error) {
            console.log(error);
            dispatch({ type: 'other_errorCodes' });
        }
    };

    return (
        <PasswordResetContainer>
            <h1>Password Reset</h1>
            {state.success ? (
                <React.Fragment>
                    <h2>Email sent!</h2>
                    <p>
                        Check for a new email from Omni Accounts to reset your
                        password.
                    </p>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <p>
                        Resetting your Omni account password will not change
                        your OmniFocus encryption passphrase—and can lock you
                        out of your data. If you use OmniFocus, please{' '}
                        <a href='https://support.omnigroup.com/of-unknown-passphrase/'>
                            see this support article
                        </a>
                        .
                    </p>
                    <p>
                        If you still need help, please contact us at{' '}
                        <a href='mailto:accounts@omnigroup.com'>
                            accounts@omnigroup.com
                        </a>
                        .
                    </p>
                </React.Fragment>
            )}
            {state.errorLanguage.length !== 0 && <ErrorBox>{state.errorLanguage}</ErrorBox>}

            {!state.success && (
                <form onSubmit={(e) => postResetPassword(e)}>
                    <label htmlFor='accountEmail'>Email address:</label>
                    <input
                        autoComplete='email'
                        name='accountEmail'
                        id='accountEmail'
                        placeholder=''
                        onChange={(e) => setAccountEmail(e.target.value)}
                        spellCheck='false'
                        type='text'
                        autoFocus
                    />
                    <Button
                        type='submit'
                        value='Sign In'
                        showDisabled={accountEmail.length < 1}
                        showLoading={state.loading ? state.loading : undefined}
                    >
                        Reset Password
                    </Button>
                </form>
            )}
            <ReturnToSignIn title='Return to main accounts sign in page.' href='/'>
                Back to sign in
            </ReturnToSignIn>
        </PasswordResetContainer>
    );
};

export default ResetPassword;
