import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-modal';
import { List } from 'react-content-loader';

import { postUpdateSubscription } from '../../utilities/postUpdateSubscription';
import { postRetrieveInvoicePreview } from '../../utilities/postRetrieveInvoicePreview';
import { postCreateInvoice } from '../../utilities/postCreateInvoice';
import { postFinalizeInvoice } from '../../utilities/postFinalizeInvoice';

import { Button, ButtonNeuComp } from '../../reusable-components/Button';
import { getSubscription } from '../../utilities/getSubscription';
import ErrorBox from '../../reusable-components/ErrorBox';
import { modalStyles } from './modalStyles';
import Stripe from 'stripe';
import dayjs from 'dayjs';
import { ActionType } from '../../store/types';
import PickSeatsToExpireOrRenewSeats from './PickSeatsToExpire';
import { giveMeLongTimeStampAsTime } from '../../utilities/givemetime';

const Buttons = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: auto;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    p {
        font-size: 16px;
    }
`;

const Quantity = styled.input`
    width: 65px;
    padding: 4px 8px;
    margin: 10px 20px 10px auto;
    font-size: 16px;
    line-height: 20px;
    text-align: right;
    height: unset;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    &[type='number'] {
        -moz-appearance: textfield;
    }
`;

const AddSeatStepOne = styled.div`
    display: flex;
    flex-direction: column;
    margin: auto;

    h2 {
        font-size: 16px;
    }
`;

const ShortReceipt = styled.div`
    font-size: 14px;
    padding: 10px;

    h2 {
        text-align: center;
        margin-bottom: 20px;
    }

    p {
        margin: 10px 0;
        font-size: 16px;
    }
`;

interface AddSeatsI {
    licenseInQuestion: OmniAccounts.LicenseObject;
    userID: string;
    currentlyEditingID: number;
    seatList: Array<OmniAccounts.SeatObject>;
    disableAtWill: boolean;
}
export const AddSeats: React.FC<AddSeatsI> = (props: AddSeatsI) => {
    const dispatch = useDispatch();

    Modal.setAppElement('#root');
    const [quantityError, setQuantityError] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [loadingEstimatedRefund, setLoadingRefund] = useState<boolean>(false);
    const [updateQuantity, setUpdateQuantityToggle] = useState<boolean>(false);
    const [quantity, setQuantity] = useState<number>(props.licenseInQuestion.total_seats);
    const [confirmQuantity, setConfirmQuantity] = useState<boolean>(false);
    // middle step: does the customer need to 'release' seats? should they be reminded to mark seats to renew?
    const [middleStep, toggleMiddleStep] = useState<boolean>(false);
    const [estimatedNowPayment, setEstimatedNowPayment] = useState<number>(0);
    const [refreshTrigger, setRefreshTrigger] = useState<boolean>(false);
    // const [taxRate, setTaxRate] = useState<number>(0);
    const [taxID, setTaxID] = useState<string>('');
    const [loading, setloading] = useState(false);
    const [subInf, setSubInfo] = useState<null | Stripe.Subscription>(null);
    const [showTryBackNextBillingPeriod, setShowTryBackNextBillingPeriod] = useState<boolean>(false);

    const purchaseapiURL = useSelector((state: OmniAccounts.GlobalReduxState) => state.purchaseapiURL);
    const accountsToken = useSelector((state: OmniAccounts.GlobalReduxState) => state.accountsToken);

    const updateCounter = useSelector((state: OmniAccounts.GlobalReduxState) => state.updateCounter);

    const postUpdatedSubscriptionSeatChangeWithImmediateCharge = async () => {
        setloading(true);
        if (subInf === null || subInf.items.data[0].quantity === undefined)
            return;
        await postUpdateSubscription({
            accountsToken,
            customerID: props.userID,
            default_tax_rates: taxID.length > 0 ? [taxID] : undefined,
            proration_behavior: subInf.items.data[0].quantity < quantity ? 'create_prorations' : 'none',
            purchaseapiURL,
            quantity: quantity,
            subscriptionID: props.licenseInQuestion.subscription_id,
        })
            .then(() => {
                if (subInf.items.data[0].quantity !== undefined) {
                    if (subInf.items.data[0].quantity < quantity) {
                        postCreateInvoice({
                            accountsToken,
                            customer_id: props.userID,
                            purchaseapiURL,
                            subscription_id: props.licenseInQuestion.subscription_id,
                        })
                            .then((result) => {
                                if (result.invoice) {
                                    postFinalizeInvoice({
                                        accountsToken,
                                        invoice_id: result.invoice.id,
                                        purchaseapiURL,
                                    });
                                    toggleMiddleStep(false);
                                    setUpdateQuantityToggle(false);
                                    setConfirmQuantity(false);
                                    setloading(false);
                                    setEstimatedNowPayment(0);
                                } else {
                                    //some error
                                    console.log(result.errors);
                                    setError(true);
                                }
                            })
                            .catch((err) => {
                                setError(true);
                                console.log(err);
                            });
                    } else {
                        // the new quantity is not greater than the old quantity, so we do NOT have an invoice to finalize
                        toggleMiddleStep(false);
                        setUpdateQuantityToggle(false);
                        setConfirmQuantity(false);
                        setloading(false);
                    }
                }
            })
            .then(() => {
                setTimeout(() => {
                    setRefreshTrigger(!refreshTrigger);
                    return;
                }, 3000);
            });
    };

    useEffect(() => {
        dispatch({ type: ActionType.UPDATING_COUNTER });
    }, [refreshTrigger]);

    useEffect(() => {
        getSubscription({
            accountsToken,
            customer_id: props.userID,
            purchaseapiURL,
            subscription_id: props.licenseInQuestion.subscription_id,
        }).then((result) => {
            if (result.subscription === undefined) return;
            setSubInfo(result.subscription);
            if ( !result.errors && result.subscription !== undefined && result.subscription !== null ) {
                if (result.subscription.items.data[0].tax_rates !== null) {
                    if (result.subscription.items.data[0].tax_rates[0] !== undefined) {
                        setTaxID(result.subscription.items.data[0].tax_rates[0].id);
                    }
                } else {
                    console.log('no tax');
                }
            }
        });
    }, [accountsToken, purchaseapiURL, updateCounter]);

    const checkQuantity = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (subInf === null) return;

        const regex = /^[0-9\b]+$/;
        if (e.target.value === '' || (e.target.value !== '' && regex.test(e.target.value)) ) {
            setQuantity(parseInt(e.target.value, 10));
        } else {
            setQuantity(subInf.items.data[0].quantity !== undefined ? subInf.items.data[0].quantity : 0 );
            setQuantityError(true);
        }
    };

    useEffect(() => {
        if (subInf === null) return;
        if (subInf.items.data[0].quantity === undefined) return;
        if (confirmQuantity && estimatedNowPayment === 0) {
            setLoadingRefund(true);
            postRetrieveInvoicePreview({
                accountsToken,
                customer_id: props.userID,
                postBody: {
                    default_tax_rates: taxID.length > 0 ? taxID : undefined,
                    plan: subInf.items.data[0].plan.id,
                    quantity: quantity,
                    subscription_item_id: subInf.items.data[0].id,
                },
                purchaseapiURL,
                subscription_id: subInf.id,
            })
                .then((result: Stripe.Invoice) => {
                    setLoadingRefund(false);
                    setEstimatedNowPayment(result.lines.data[1].amount ? result.lines.data[1].amount + result.lines.data[0].amount : 0 )
                })
                .catch((error) => {
                    setLoadingRefund(false);
                    console.log(error);
                });
        }
        // eslint-disable-next-line
    }, [confirmQuantity]);

    if (subInf === null) {
        return null;
    }

    return (
        <div style={{ margin: '0 0 0 auto' }} >
            {/* In the future this will be gate-kept by whether or not it's a license or subscription */}
            <Button
                style={{ width: '200px' }}
                onClick={() => {
                    props.licenseInQuestion.renewing_quantity < props.licenseInQuestion.seats
                    ? 
                    setShowTryBackNextBillingPeriod(true)
                    : 
                    setUpdateQuantityToggle(true);
                }}
                showDisabled={
                    subInf.status !== 'active' ||
                    subInf.cancel_at_period_end === true ||
                    props.disableAtWill
                }
            >
                Change Seats
            </Button>

            <Modal isOpen={showTryBackNextBillingPeriod} style={modalStyles}>
                <h2>Seat Quantity Changes Disabled</h2>
                <p>
                    To prevent overcharging for seats already in use, the
                    ability to modify this subscription has been disabled. After
                    the next renewal date (
                    {giveMeLongTimeStampAsTime(
                        props.licenseInQuestion.expires_date
                    )}
                    ), this subscription can be changed.
                </p>
                <p>
                    To make seat changes before that date,{' '}
                    <a href='mailto:sales@omnigroup.com'>contact sales</a> with
                    your account name and subscription.
                </p>
                <ButtonNeuComp
                    alignRight
                    onClick={() => setShowTryBackNextBillingPeriod(false)}
                    primary
                >
                    Okay
                </ButtonNeuComp>
            </Modal>

            <Modal isOpen={updateQuantity} style={modalStyles}>
                {updateQuantity && middleStep && !confirmQuantity && (
                    <PickSeatsToExpireOrRenewSeats
                        interimState={
                            quantity <
                            props.licenseInQuestion.used_seats -
                                props.licenseInQuestion.invited_seats
                                ? 'decrease_seats'
                                : props.seatList.filter(
                                      (seat) => seat.status === 'expiring'
                                  ).length > 0
                                ? 'set_renewing_reminder'
                                : 'no_action_needed'
                        }
                        currentObjectID={props.currentlyEditingID}
                        renewal_date={props.licenseInQuestion.expiration_date}
                        users={props.seatList}
                        current_seats={props.licenseInQuestion.total_seats}
                        future_seats={quantity}
                        cancelSeats={() => toggleMiddleStep(false)}
                        proceed={() => setConfirmQuantity(true)}
                    />
                )}
                {updateQuantity &&
                    !confirmQuantity &&
                    !middleStep &&
                    subInf.items.data[0].quantity !== undefined && (
                        <AddSeatStepOne>
                            <h2>Change Quantity of Subscription</h2>
                            {quantityError && (
                                <ErrorBox>
                                    Double-check that the new quantity makes
                                    sense!
                                </ErrorBox>
                            )}
                            <Row>
                                <div style={{ margin: '10px 0' }}>
                                    Current # of seats:{' '}
                                </div>
                                <div
                                    style={{
                                        margin: '0 28px 0 auto',
                                        textAlign: 'right',
                                        width: '65px',
                                    }}
                                >
                                    {subInf.items.data[0].quantity}
                                </div>
                            </Row>
                            <Row style={{
                                    borderBottom: '1px solid #404040',
                                    paddingBottom: '15px',
                                }}
                            >
                                <label
                                    style={{ margin: 'auto 0' }}
                                    htmlFor='quantity'
                                >
                                    New quantity:{' '}
                                </label>
                                <Quantity
                                    min={1}
                                    id='quantity'
                                    name='quantity'
                                    pattern='[0-9]*'
                                    placeholder={subInf.items.data[0].quantity.toString()}
                                    type='number'
                                    onChange={(e) => checkQuantity(e)}
                                />
                            </Row>
                            <Row>
                                <p style={{ maxWidth: '400px' }}>
                                    On checkout you will only be charged the
                                    difference in price for the remainder of
                                    your current subscription period.
                                </p>
                            </Row>
                            <Buttons>
                                <ButtonNeuComp
                                    primary={false}
                                    onClick={() => {
                                        setUpdateQuantityToggle(false);
                                        toggleMiddleStep(false);
                                    }}
                                >
                                    <span>Cancel</span>
                                </ButtonNeuComp>
                                <ButtonNeuComp
                                    primary
                                    showDisabled={
                                        isNaN(quantity) ||
                                        quantity < 1 ||
                                        quantity > 2200 ||
                                        quantity ===
                                            subInf.items.data[0].quantity
                                    }
                                    onClick={() => toggleMiddleStep(true)}
                                >
                                    <span>Confirm Quantity</span>
                                </ButtonNeuComp>
                            </Buttons>
                        </AddSeatStepOne>
                    )}
                {updateQuantity &&
                    confirmQuantity &&
                    subInf.items.data[0].quantity !== undefined && (
                        <div>
                            {loadingEstimatedRefund ? (
                                <List />
                            ) : (
                                <ShortReceipt>
                                    <h2>Review and Checkout</h2>
                                    <p style={{ maxWidth: '600px' }}>
                                        This will change the number of seats on
                                        your{' '}
                                        {props.licenseInQuestion.description} to{' '}
                                        {quantity}. The current{' '}
                                        {subInf.items.data[0].plan.id.includes(
                                            'ANNUAL'
                                        )
                                            ? 'yearly'
                                            : 'monthly'}{' '}
                                        subscription rate for {quantity} seats
                                        is $
                                        {subInf.items.data[0].plan.amount !==
                                            null &&
                                            (
                                                (quantity *
                                                    subInf.items.data[0].plan
                                                        .amount) /
                                                100
                                            ).toFixed(2)}
                                        . The current subscription period ends
                                        on{' '}
                                        {dayjs
                                            .unix(subInf.current_period_end)
                                            .format('MMMM D, YYYY')}
                                        .
                                    </p>
                                    {quantity <
                                    subInf.items.data[0].quantity ? (
                                        <p style={{ maxWidth: '600px' }}>
                                            The current number of seats (
                                            {
                                                props.licenseInQuestion
                                                    .total_seats
                                            }
                                            ) will remain available until the
                                            next billing period (
                                            {dayjs
                                                .unix(subInf.current_period_end)
                                                .format('MMMM D, YYYY')}
                                            ). At that point, you’ll be charged
                                            the above amount.
                                        </p>
                                    ) : (
                                        <p style={{ maxWidth: '600px' }}>
                                            The amount due now is based on the
                                            time remaining in the current
                                            subscription period, and includes a
                                            credit for the unused portion of the
                                            original subscription. Taxes are not
                                            included, but will be charged if
                                            applicable.
                                        </p>
                                    )}{' '}
                                    {error && (
                                        <ErrorBox
                                            errorTitle='Invoicing Error'
                                            style={{
                                                margin: '20px auto',
                                                maxWidth: '600px',
                                            }}
                                        >
                                            <p>
                                                Whoops, something went wrong.
                                                There might be an issue with
                                                your account, or the
                                                subscription — it might be best
                                                to{' '}
                                                <a href='mailto:sales@omnigroup.com'>
                                                    email sales
                                                </a>
                                                .
                                            </p>
                                        </ErrorBox>
                                    )}
                                    <Buttons>
                                        <ButtonNeuComp
                                            primary={false}
                                            onClick={() => {
                                                setUpdateQuantityToggle(false);
                                                setConfirmQuantity(false);
                                                toggleMiddleStep(false);
                                                setQuantity(
                                                    subInf.items.data[0]
                                                        .quantity
                                                        ? subInf.items.data[0]
                                                              .quantity
                                                        : 0
                                                );
                                                setEstimatedNowPayment(0);
                                                setError(false);
                                            }}
                                        >
                                            <span>Cancel</span>
                                        </ButtonNeuComp>

                                        <ButtonNeuComp
                                            primary={true}
                                            onClick={() =>
                                                postUpdatedSubscriptionSeatChangeWithImmediateCharge()
                                            }
                                            showLoading={loading}
                                        >
                                            {quantity >
                                            subInf.items.data[0].quantity ? (
                                                <span>
                                                    Pay{' '}
                                                    {estimatedNowPayment !==
                                                        null && (
                                                        <span>
                                                            $
                                                            {(
                                                                estimatedNowPayment /
                                                                100
                                                            ).toFixed(2)}
                                                        </span>
                                                    )}
                                                    {' + tax (if any)'}
                                                </span>
                                            ) : (
                                                <span>
                                                    Finalize Seat Change
                                                </span>
                                            )}
                                        </ButtonNeuComp>
                                    </Buttons>
                                </ShortReceipt>
                            )}
                        </div>
                    )}
            </Modal>
        </div>
    );
};
