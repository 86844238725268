interface PostAcceptInviteResponse {
    status: 'success' | 'error';
    message: string;
}

export const postAcceptInvite = async (
    accountsApiURL: string,
    accountsToken: string,
    signUpRef: string
): Promise<PostAcceptInviteResponse> => {
    const registrationCode = new URLSearchParams();
    registrationCode.set('reference_id', signUpRef ? signUpRef.toString() : '');

    return await fetch(`${accountsApiURL}/api/1.1/user/accept_invite/new/`, {
        body: registrationCode,
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + accountsToken,
            'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
        },
        method: 'POST',
        mode: 'cors',
    })
        .then((response) => {
            return response.json();
        })
        .catch((err) => {
            return err;
        });
};
