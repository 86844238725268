import * as React from 'react';
import styled from 'styled-components';

const UpgradeResponseWrapper = styled.div`
    text-align: center;
    padding: 20px;
    font-size: 20px;
`;

interface UpgradeSuccessProps {
    errorMessage: null | string;
    productInfo: OmniAccounts.RegistrationObj;
    successfulUpgradeMessage: null | string;
}

const SuccessfulUpgrade: React.FC<UpgradeSuccessProps> = (props: UpgradeSuccessProps) => {
    const { 
        errorMessage, 
        productInfo,
        successfulUpgradeMessage 
    } = props;
    
    return (
        <UpgradeResponseWrapper>
            {errorMessage ? (
                <div>
                    <div>{errorMessage}</div>
                    <div>Please try upgrading your {productInfo.from_name} seats again.</div>
                </div>
            ) : (
                <div>
                    <div>You now have access to {productInfo.upgrade_name}</div>
                    <div>{successfulUpgradeMessage}</div>
                </div>
            )}
        </UpgradeResponseWrapper>
    )
}

export default SuccessfulUpgrade;