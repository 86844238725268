import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import HoldingBox from '../reusable-components/HoldingBox';
import LicenseEntry from './LicenseEntry';
import ManagedSubscription from '../reusable-components/ManagedSubscriptions';
import ManagedLicense from '../reusable-components/ManagedLicense';

const Header = styled.div`
    font-weight: bold;
    padding-bottom: 20px;
`;

const Subtext = styled.div`
    padding-bottom: 20px;
`;

interface ManageSegmentProps {
    flashYellow: boolean;
}

const ManageSegment: React.FC<ManageSegmentProps> = (props: ManageSegmentProps) => {
    const accountsToken = useSelector((state: OmniAccounts.GlobalReduxState) => state.accountsToken);
    const managedLicenses = useSelector((state: OmniAccounts.GlobalReduxState) => state.managedLicenses);
    const purchasesData = useSelector((state: OmniAccounts.GlobalReduxState) => state.purchasesData);
    const teamSeats = useSelector((state: OmniAccounts.GlobalReduxState) => state.teamSeats);
    const listOfSeats = useSelector((state: OmniAccounts.GlobalReduxState) => state.listOfSeats);
    const userInfo = useSelector((state: OmniAccounts.GlobalReduxState) => state.userInfo);
    const supportedProductVersions = useSelector((state: OmniAccounts.GlobalReduxState) => state.supportedProductVersions);
    
    const initialValue: {[key: string]: Array<OmniAccounts.SeatObject>} = {}
    const listOfSeatsByID = listOfSeats.reduce((acc, val) => {
        initialValue[val.id] = val.seats;
        return initialValue;
    } , initialValue)

    const teamManagementSubtext = (
        <p>
            These are the purchases made by this account. Invite others to join
            your team. Assign yourself to a seat to gain access.
        </p>
    );

    useEffect(() => {
        if (props.flashYellow) {
            window.history.replaceState({}, document.title, '/');
        }
    }, [props.flashYellow]);

    if (managedLicenses === undefined) {
        return null;
    }
    if (!teamSeats || !userInfo || !purchasesData) {
        return null;
    }
    
    const purchasedSeats = managedLicenses.filter((license) => !license.product_id?.includes('TRIAL'));
    const teamPurchasedSeats = purchasedSeats.filter((team) => {
        const { total_seats, available_seats, invited_seats } = team;
        const teamID = team.id;
        const seats = listOfSeatsByID[teamID];
        const differentUser = listOfSeatsByID[teamID]?.length > 0 ? userInfo.email !== seats[0].email : false;
        // returns team purchases, one seat team unassigned, onse seat pending, and one seat team assigned to someone else
        return total_seats > 1 || (total_seats === 1 && available_seats === 1) || total_seats === 1 && invited_seats === 1 || (total_seats === 1 && differentUser)
    });

    const licenseTeamSeats = teamPurchasedSeats.filter((license) => !license.subscription_id);
    const licensedSeatsFiltered = licenseTeamSeats.filter((license) => !license.product_id.includes('_LEGACY') && !license.product_id.includes('TRIAL'));
    const subscriptionTeamSeats = teamPurchasedSeats.filter((subscription) => subscription.subscription_id);

    const customerIdExists = userInfo.customer_id !== undefined;

    const nonLegacyLicenses = purchasesData.filter((license) => {
        const productIDArray = license.description.split(' ');
        const productName = productIDArray[0];
        const productType =  parseInt(productIDArray[1]);
        return productType >= supportedProductVersions[productName] && license.seats > 1 && license.upgrades_remaining === 0;
    });


    if (managedLicenses.length === 0 || purchasedSeats.length === 0) {
        return null;
    }

    if (licensedSeatsFiltered.length === 0 && subscriptionTeamSeats.length === 0) {
        return null;
    }

    return (
        <HoldingBox
            sectionTitle='Team Management'
            animate={props.flashYellow}
            subtext={teamManagementSubtext}
        >
            { purchasedSeats && customerIdExists && subscriptionTeamSeats.length > 0 && (
                <React.Fragment>
                    <Header>Subscriptions</Header>
                    <p>
                        { subscriptionTeamSeats.length >= 1 && (
                            <span>
                                You’re in charge of distributing seats.{' '}
                                <a href='https://support.omnigroup.com/team-subscriptions/'>
                                    Learn More
                                </a>
                            </span>
                        )}
                    </p>
                    <ul style={{ margin: '10px 0', padding: '0'}}>
                        { subscriptionTeamSeats.map((subscription) => (
                            <ManagedSubscription
                                customerID={userInfo.customer_id[0]}
                                key={subscription.id}
                                accountsToken={accountsToken}
                                license={subscription}
                            />
                        ))}
                    </ul>
                </React.Fragment>
            )}
            { purchasedSeats && licensedSeatsFiltered.length > 0 && (
                <React.Fragment>
                    <Header>Licenses</Header>
                    { licensedSeatsFiltered.length > 0 ? (
                        <div>
                            <Subtext>
                                Perpetual licenses you’ve purchased to share with teammates. 
                                You&apos;re in charge of distributing seats. {' '}
                                <a href="https://support.omnigroup.com/team-subscriptions/">Learn More</a>
                            </Subtext>
                            <ul style={{ margin: '10px 0', padding: '0' }}>
                                { licensedSeatsFiltered.map((license) => (
                                    <ManagedLicense
                                        license={license}
                                        key={license.id}
                                    />
                                ))}{" "}
                            </ul>
                        </div>
                    ) : (
                        <Subtext>
                            You do not currently have any perpetual licenses.
                        </Subtext>
                    )}
                </React.Fragment>
            )}
            { nonLegacyLicenses.length > 0 && (
                <div>
                    <Header>Archived Licenses</Header>
                    { nonLegacyLicenses.map((item: OmniAccounts.LicenseObject, fakeKey: number) => (
                        <LicenseEntry
                            key={fakeKey}
                            licenseItem={item}
                        />
                    ))}
                </div> 
            )}
        </HoldingBox>
    );
};

export default ManageSegment;
