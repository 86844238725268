interface PostDefaultPaymentMethodParams {
    accountsToken: string;
    customer_id: string;
    payment_method_id: string;
    purchaseapiURL: string;
}

export const postDefaultPaymentMethod = async ({
    accountsToken,
    customer_id,
    payment_method_id,
    purchaseapiURL,
}: PostDefaultPaymentMethodParams): Promise<boolean> => {
    return await new Promise((resolve, reject) => {
        fetch(
            `${purchaseapiURL}/api/v2/customer/${customer_id}/payment_method/${payment_method_id}/default`,
            {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${accountsToken}`,
                    'Content-Type': 'application/json',
                },
                method: 'POST',
                mode: 'cors',
            }
        )
            .then((response) => {
                return Promise.resolve(response.json()).then((res) => {
                    if (!res.errors) {
                        resolve(true);
                    } else if (res.errors) {
                        console.log(res.errors);
                        reject(true);
                    }
                    return reject(true);
                });
            })
            .catch((err) => {
                console.log('err', err);
                return reject();
            });
    });
};
