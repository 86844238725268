import Stripe from 'stripe';

export interface GetSessionResponse {
    session?: Stripe.BillingPortal.Session;
    errors?: Array<{ message: string }>;
}

export const getStripeSession = async (
    accountsToken: string,
    cus_number: string,
    purchaseURL: string
): Promise<GetSessionResponse> => {
    return await new Promise((resolve, reject) => {
        fetch(`${purchaseURL}/api/v2/customer/` + cus_number + '/portal', {
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + accountsToken,
                'Content-Type': 'application/json',
            },
            method: 'POST',
            mode: 'cors',
        })
            .then((response) => {
                return response.json();
            })
            .then((response) => {
                if (response.url) {
                    return resolve({ session: response });
                } else {
                    return resolve({ errors: response });
                }
            })
            .catch((err) => {
                return reject(err);
            });
    });
};
