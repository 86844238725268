import Stripe from 'stripe';

interface GetPaymentMethodsResponse {
    paymentMethods: Array<Stripe.PaymentMethod>;
    errors?: Array<{ message: string }>;
}

const getPaymentMethods = async (
    purchaseapiURL: string,
    cus_number: string,
    accountsToken: string
): Promise<GetPaymentMethodsResponse> => {
    return await fetch(
        `${purchaseapiURL}/api/v2/customer/${cus_number}/payment_methods`,
        {
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + accountsToken,
                'Content-Type': 'application/json',
            },
            method: 'GET',
            mode: 'cors',
        }
    )
        .then((response) => response.json())
        .then((response) => {
            if (!response.errors) {
                return { paymentMethods: response };
            } else {
                return { errors: response };
            }
        })
        .catch((err) => {
            console.log('err', err);
            return err;
        });
};

export default getPaymentMethods;
