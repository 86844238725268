import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import addListOfEmails from './utilities/addEmails';
import Modal from 'react-modal';
import { modalStyles } from './modalStyles';
import { ButtonNeuComp, SideBySide } from '../../reusable-components/Button';
import postTeamSeatModification from '../../utilities/postTeamSeatModification';
import { ActionType } from '../../store/types';
import { giveMeLongTimeStampAsTime } from '../../utilities/givemetime';

const UserName = styled.div`
    grid-column-start: 2;
    grid-column-end: 3;
    margin: auto 0;
`;

const Email = styled.div`
    grid-column-start: 3;
    grid-column-end: 4;
    margin: auto 0;
`;

const ModalH3 = styled.h3`
    text-transform: none;
    font-size: 22px;
`;

const Renewing = styled.div`
    grid-column-start: 4;
    grid-column-end: 5;
    margin: auto 0;
`;

const InviteStatus = styled.div`
    grid-column-start: 4;
    grid-column-end: 5;
    margin: auto 0;
    text-align: center;
`;

const FakeLinkRed = styled.a`
    text-decoration: underline;
    cursor: pointer;
    color: red;
`;
interface SingleRowProps {
    rowStyle: string;
}
export const SingleRow = styled.div<SingleRowProps>`
    display: grid;
    grid-template-columns: 50px 150px 300px auto;
    padding: 5px 0;
    font-size: 15px;
    width: 100%;
    background-color: ${(props) => props.rowStyle};

    &:hover {
        background-color: rgba(211, 230, 249, 1);
    }
`;

export const SimpleRow = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
    > * {
        text-align: left;
        margin: 0 20px 0 0;
    }

    @media (max-width: 800px) {
        flex-direction: column;

        > * {
            text-align: left;
            margin: 0 0 10px;
        }
    }
`;

const SimpleInput = styled.input`
    border-radius: 4px;
    font-size: 13px;
    margin: 10px auto 10px 0;
    padding: 5px;
    width: 300px;
`;

interface TableUserProps {
    currentObject: OmniAccounts.LicenseObject;
    rowFilter: string;
    rowStyle: string;
    user: OmniAccounts.SeatObject;
    subscription: boolean;
    currentlyEditingID: number;
}

const TableRow: React.FC<TableUserProps> = (props: TableUserProps) => {
    const dispatch = useDispatch();

    const [renewing, setRenewing] = React.useState<boolean>(
        props.user.status === 'active' ? true : false
    );
    const [checked, setChecked] = React.useState<boolean>(false);
    const [refreshing, setRefreshing] = React.useState<boolean>(false);
    const [reactivateSeat, toggleReactivateSeat] =
        React.useState<boolean>(false);
    const [checkForEnoughSpace, setCheckForEnoughSpace] =
        React.useState<boolean>(false);

    const [youNeedMoreSeats, toggleYouNeedMoreSeats] =
        React.useState<boolean>(false);
    const [showCopyLink, setShowCopyLink] = React.useState<boolean>(false);
    const [showReInvitedText, setShowReInvitedText] =
        React.useState<boolean>(false);

    const accountsToken = useSelector(
        (state: OmniAccounts.GlobalReduxState) => state.accountsToken
    );
    const accountsapiURL = useSelector(
        (state: OmniAccounts.GlobalReduxState) => state.accountsApiURL
    );
    const checkedSeatsForDeletion = useSelector(
        (state: OmniAccounts.GlobalReduxState) => state.checkedSeatsForDeletion
    );
    const userInfo = useSelector((state: OmniAccounts.GlobalReduxState) => state.userInfo);

    const deleteSeatCheckbox = async () => {
        if (checked) {
            dispatch({
                type: ActionType.REMOVE_SEAT_FROM_REMOVAL,
                value: props.user,
            });
            setChecked(false);
        } else {
            dispatch({
                type: ActionType.ADD_SEAT_FOR_REMOVAL,
                value: props.user,
            });
            setChecked(false);
        }
    };

    React.useEffect(() => {
        if (
            props.currentObject.renewing_quantity <
                props.currentObject.used_seats &&
            checkForEnoughSpace === true
        ) {
            toggleYouNeedMoreSeats(true);
            setCheckForEnoughSpace(false);
        } else if (
            props.currentObject.total_seats - props.currentObject.used_seats >
                props.currentObject.renewing_quantity &&
            checkForEnoughSpace === true
        ) {
            toggleYouNeedMoreSeats(true);
            setCheckForEnoughSpace(false);
        } else if (checkForEnoughSpace === true) {
            setCheckForEnoughSpace(false);
            toggleReactivateSeat(true);
        }
    }, [checkForEnoughSpace]);

    const renewOrReleaseSeat = async () => {
        if (userInfo === null) return;
        setRefreshing(true);
        setRenewing(!renewing);
        // let’s POST to accounts to set this to release
        postTeamSeatModification({
            accountsApiURL: accountsapiURL,
            accountsToken: accountsToken,
            currentPlanID: props.currentObject.id,
            release: props.user.status === 'active' ? props.user.id : undefined,
            renew: props.user.status === 'expiring' ? props.user.id : undefined,
        })
            .then(() =>
                dispatch({
                    subscription: props.currentlyEditingID,
                    type: ActionType.REFRESH_THIS_SUBSCRIPTION,
                })
            )
            .then(() => {
                setRefreshing(false);
                toggleReactivateSeat(false);
            });
    };

    React.useEffect(() => {
        if (checkedSeatsForDeletion.length === 0) {
            setChecked(false);
        }
        checkedSeatsForDeletion.filter((item) => {
            if (item.email === props.user.email) {
                setChecked(true);
            }
            return item;
        });
    }, [checkedSeatsForDeletion, props.user.email]);

    React.useEffect(() => {
        if (showReInvitedText) {
            setTimeout(() => {
                setShowReInvitedText(false);
            }, 2000);
        }
    }, [showReInvitedText]);

    const clipRef = React.useRef<HTMLInputElement>(null);

    const copyLinkToClipboard = () => {
        if (clipRef !== null && clipRef.current !== null) {
            clipRef.current.select();
            document.execCommand('copy');
            clipRef.current.focus();
        } else {
        }
    };

    if (props.user.email !== null && props.user.email !== undefined) {
        if (!props.user.email.includes(props.rowFilter)) {
            return null;
        }
    } else if (props.rowFilter.length >= 1 && props.user.email === null) {
        return null;
    }

    return (
        <SingleRow rowStyle={props.rowStyle} key={props.user.email}>
            <input
                type='checkbox'
                onChange={() => deleteSeatCheckbox()}
                style={{ margin: 'auto', width: '20px' }}
                checked={checked}
            />
            <UserName>
                {props.user.username ? (
                    props.user.username
                ) : (
                    <em>Invitation pending</em>
                )}
            </UserName>
            <Email>{props.user.email}</Email>

            <InviteStatus>
                {!props.user.username && !showReInvitedText && !showCopyLink ? (
                    <SimpleRow>
                        <div
                            style={{
                                cursor: 'pointer',
                                textDecoration: 'underline',
                            }}
                            onClick={() =>
                                addListOfEmails(
                                    accountsToken,
                                    accountsapiURL,
                                    props.currentlyEditingID,
                                    props.user.email
                                )
                                    .then(() =>
                                        dispatch({
                                            subscription:
                                                props.currentlyEditingID,
                                            type: ActionType.REFRESH_THIS_SUBSCRIPTION,
                                        })
                                    )
                                    .then(() => setShowReInvitedText(true))
                            }
                        >
                            Re-send
                        </div>
                        <div
                            style={{
                                cursor: 'pointer',
                                textDecoration: 'underline',
                            }}
                            onClick={() => setShowCopyLink(true)}
                        >
                            Share Invite URL
                        </div>
                    </SimpleRow>
                ) : !props.user.username && !showCopyLink ? (
                    <SimpleRow>
                        <span>Re-sent</span>
                    </SimpleRow>
                ) : (
                    !showCopyLink &&
                    props.user.username !== null && (
                        <SimpleRow>
                            {props.subscription &&
                                props.user.status === 'active' && (
                                    <Renewing>Active</Renewing>
                                )}
                            {props.subscription &&
                                props.user.status === 'expiring' && (
                                    <Renewing>
                                        {!refreshing ? (
                                            <FakeLinkRed
                                                type='checkbox'
                                                onClick={() =>
                                                    setCheckForEnoughSpace(true)
                                                }
                                            >
                                                Expiring
                                            </FakeLinkRed>
                                        ) : (
                                            <span>🙃</span>
                                        )}
                                    </Renewing>
                                )}
                        </SimpleRow>
                    )
                )}
            </InviteStatus>
            {showCopyLink && (
                <Modal isOpen={showCopyLink} style={modalStyles}>
                    <ModalH3>Copy Shareable URL</ModalH3>
                    <p>A short URL to pass along to a teammate.</p>
                    <div>
                        <SimpleInput
                            type='text'
                            value={props.user.short_url}
                            readOnly
                            ref={clipRef}
                        />
                        <span
                            onClick={() => copyLinkToClipboard()}
                            style={{ cursor: 'pointer', margin: '0 10px' }}
                        >
                            📋
                        </span>
                    </div>
                    <ButtonNeuComp
                        alignRight
                        primary
                        onClick={() => setShowCopyLink(false)}
                    >
                        Done
                    </ButtonNeuComp>
                </Modal>
            )}
            {youNeedMoreSeats && (
                <Modal isOpen={youNeedMoreSeats} style={modalStyles}>
                    <ModalH3>
                        Your subscription does not have enough seats available.
                    </ModalH3>
                    <p>
                        The seat used by{' '}
                        {props.user.username ? props.user.username : null}
                        {props.user.email && `(${props.user.email})`} is
                        currently scheduled to be removed when your subscription
                        renews on{' '}
                        {giveMeLongTimeStampAsTime(
                            props.currentObject.expiration_date
                        )}
                        . To keep this user, you must add a seat to your
                        subscription renewal. Please contact{' '}
                        <a href='mailto:sales@omnigroup.com'>
                            sales@omnigroup.com
                        </a>{' '}
                        to make this change.
                    </p>
                    <SideBySide justify={'flex-end'}>
                        <ButtonNeuComp
                            primary
                            onClick={() => toggleYouNeedMoreSeats(false)}
                        >
                            Okay
                        </ButtonNeuComp>
                    </SideBySide>
                </Modal>
            )}
            {reactivateSeat && (
                <Modal isOpen={reactivateSeat} style={modalStyles}>
                    <ModalH3>
                        Mark {props.user.username ? props.user.username : null}{' '}
                        ({props.user.email}) as renewing?
                    </ModalH3>
                    <p>
                        This user is currently scheduled to be removed from your
                        subscription when it renews on{' '}
                        {giveMeLongTimeStampAsTime(
                            props.currentObject.expiration_date
                        )}
                        . If you mark them as renewing, they will not be
                        removed.
                    </p>
                    <SideBySide justify='flex-end'>
                        <ButtonNeuComp
                            primary={false}
                            onClick={() => toggleReactivateSeat(false)}
                        >
                            Cancel
                        </ButtonNeuComp>
                        <ButtonNeuComp
                            primary
                            onClick={() => renewOrReleaseSeat()}
                        >
                            Mark as Renewing
                        </ButtonNeuComp>
                    </SideBySide>
                </Modal>
            )}
        </SingleRow>
    );
};

export default TableRow;
