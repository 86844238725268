import React , { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Stripe from 'stripe';

import { ActionType } from '../../store/types';
import { useTheme, useThemeUpdate } from './ThemeContext';

import { ButtonNeuComp, SideBySide } from '../../reusable-components/Button';

import getSubscriptionData from '../../utilities/getSubscriptionData';
import { postUpdateSubscription } from '../../utilities/postUpdateSubscription';

const ModalWrapper = styled.div`
    height: 100vh;
    width: 100vw;
    top: 0;
    position: fixed;
    background-color: rgba(0,0,0,0.35);
    overflow: hidden;

    > button {
        cursor: pointer;
    }
`;

const Modal = styled.div`
    position: absolute;
    inset: 50% auto auto 50%;
    border: 0px;
    background: rgb(255, 255, 255);
    overflow: auto;
    border-radius: 6px;
    outline: none;
    padding: 30px;
    box-shadow: rgb(0 0 0 / 27%) 2px 2px 5px;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    max-width: 600px;
    min-width: 300px;
`;

interface ConfirmChangeProps {
    subscription: Stripe.Subscription;
    subscriptionType: 'month' | 'year';
    toggleEditModal: (toggle: boolean) => void;
}

const ChangeSubscriptionModal: React.FC<ConfirmChangeProps> = (props) => {
    const dispatch = useDispatch();
    const { estimatedPayment } = useTheme();

    const {
        setError,
        toggleChangeModal,
        toggleMenu,
    } = useThemeUpdate();

    const [loading, setLoading] = useState<boolean>(false);

    const accountsToken = useSelector((state: OmniAccounts.GlobalReduxState) => state.accountsToken);
    const customerInfo = useSelector((state: OmniAccounts.GlobalReduxState) => state.customerInfo);
    const purchaseapiURL = useSelector((state: OmniAccounts.GlobalReduxState) => state.purchaseapiURL);


    const subscriptionPlanID = props.subscription?.items.data[0].plan.id;
    const subscriptionType = props.subscriptionType + 'ly';
    const changeSubscriptionType = subscriptionType === 'monthly' ? 'yearly' : 'monthly';

    const confirmBillingCycleChangeAndRefresh = async (current_id: string, plan_id: string) => {
        setLoading(true);
        if (!customerInfo) return;
        
        const customerID = customerInfo.id;
        await postUpdateSubscription({
            accountsToken,
            customerID,
            plan: plan_id.includes('ANNUAL')
                ? plan_id.replace('ANNUAL', 'MONTHLY')
                : plan_id.replace('MONTHLY', 'ANNUAL'),
            purchaseapiURL: purchaseapiURL,
            subscriptionID: current_id,
        })
        .then(() => getSubscriptionData({ accountsToken, customerID, purchaseapiURL }))
        .then((response) => dispatch({
            subscriptions: response,
            type: ActionType.SAVE_JUST_SUBSCRIPTION_INFO,
        }))
        .catch((error) => {
            console.error(error)
            setError('Unable to modify current subscription. Please contact the Omni Group.')
        });
        toggleChangeModal(false);
        toggleMenu(false);
        setLoading(false);
    };

    return (
        <ModalWrapper>
            <Modal>
                <h2>Convert to {changeSubscriptionType}</h2>
                <p>
                    This will convert your subscription from a {subscriptionType} to 
                    a {changeSubscriptionType} subscription.
                </p>
                <p>
                    The amount due now is based on the time remaining in
                    the current subscription period, and includes a
                    credit for the unused portion of the original
                    subscription. Taxes are not included, but will be
                    charged if applicable.
                </p>
                <SideBySide justify="flex-end">
                    <ButtonNeuComp
                        primary={false}
                        onClick={() => {
                            toggleChangeModal(false)
                            toggleMenu(true)
                        }}  
                    >
                        Cancel
                    </ButtonNeuComp>
                    <ButtonNeuComp
                        showLoading={loading}
                        primary={true}
                        onClick={() => confirmBillingCycleChangeAndRefresh(props.subscription.id, subscriptionPlanID)}
                    >
                        Pay ${(estimatedPayment / 100).toFixed(2)}
                        {' + tax (if any)'}
                    </ButtonNeuComp>
                </SideBySide>
            </Modal>
        </ModalWrapper>
    )
}

export default ChangeSubscriptionModal;
