import * as React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import postTeamSeatModification from '../../utilities/postTeamSeatModification';
import { ButtonNeuComp } from '../../reusable-components/Button';
import { List } from 'react-content-loader';
import dayjs from 'dayjs';

const UserName = styled.div`
    margin: auto 0;
    width: 200px;
`;

const Email = styled.div`
    width: 230px;
    margin: auto 0;
`;

const SmallSeatTable = styled.div`
    display: flex;
    flex-direction: column;
    margin: 20px 0;
`;

const Buttons = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 20px 0 0;
`;

interface SingleRowProps {
    setForRemoval: boolean;
}

const SingleRow = styled.div<SingleRowProps>`
    display: flex;
    flex-direction: row;
    padding: 5px 0;
    justify-content: flex-start;
    font-size: 15px;
    width: 500px;
    align-items: center;
    background-color: ${(props) =>
        props.setForRemoval ? 'rgba(250, 98, 22, 0.2)' : undefined};

    &:hover {
        background-color: ${(props) =>
            props.setForRemoval
                ? 'rgba(250, 98, 22, 0.2)'
                : 'rgba(92, 201, 245, 0.1)'};
    }

    &:first-of-type {
        background-color: none;
    }
`;

interface PickSeatsProps {
    interimState:
        | 'decrease_seats'
        | 'set_renewing_reminder'
        | 'no_action_needed';
    currentObjectID: number;
    renewal_date: string;
    users: Array<OmniAccounts.SeatObject>;
    current_seats: number;
    future_seats: number;
    cancelSeats: () => void;
    proceed: () => void;
}

const PickSeatsToExpireOrRenewSeats: React.FC<PickSeatsProps> = (
    props: PickSeatsProps
) => {
    // const dispatch = useDispatch();

    const [selectedSeatIDs, setSelectedSeatID] = React.useState<Array<string>>(
        []
    );

    const accountsToken = useSelector(
        (state: OmniAccounts.GlobalReduxState) => state.accountsToken
    );
    const accountsapiURL = useSelector(
        (state: OmniAccounts.GlobalReduxState) => state.accountsApiURL
    );

    const userInfo = useSelector((state: OmniAccounts.GlobalReduxState) => state.userInfo);

    const releaseTheseSeats = async () => {
        if (userInfo === null || selectedSeatIDs === undefined) return;

        // let’s POST to accounts to set this to release
        postTeamSeatModification({
            accountsApiURL: accountsapiURL,
            accountsToken: accountsToken,
            currentPlanID: props.currentObjectID,
            release: selectedSeatIDs?.join(', '),
            renew: undefined,
        });
        // We should then check to see if the result from the return equals something of equal value to the amount they're setting it to; then we can return something to tell the AddSeats component to move on to the next step.
        props.proceed();
    };

    const processSeats = (seat: string) => {
        if (selectedSeatIDs.filter((as) => as === seat).length > 0) {
            setSelectedSeatID(
                selectedSeatIDs.filter((seatTwo) => seatTwo !== seat)
            );
        } else {
            setSelectedSeatID([...selectedSeatIDs, seat]);
        }
    };

    React.useEffect(() => {
        if (props.interimState === 'no_action_needed') {
            props.proceed();
        }
    }, [props.interimState]);

    const amountOfSeatsToRemove = props.current_seats - props.future_seats;

    if (props.interimState === 'decrease_seats') {
        return (
            <div>
                <h2>Schedule Seats for Expiration</h2>
                <p style={{ maxWidth: '500px' }}>
                    Do you really want to reduce your subscription from{' '}
                    {props.current_seats} seats to {props.future_seats}? If so,
                    you must choose which assigned seats will expire when your
                    subscription renews on{' '}
                    {dayjs(props.renewal_date).format('MMMM DD, YYYY')}.
                    (Invitations that have not yet been accepted will
                    automatically be released.)
                </p>
                <SmallSeatTable>
                    <SingleRow setForRemoval={false}>
                        <span style={{ margin: '5px', width: '20px' }} />
                        <UserName style={{ fontWeight: 600 }}>
                            Username
                        </UserName>
                        <UserName style={{ fontWeight: 600 }}>Email</UserName>
                    </SingleRow>
                    {props.users
                        .filter((asd) => asd.id !== null)
                        .map((seat) => {
                            return (
                                <SingleRow
                                    onClick={() => {
                                        processSeats(seat.id);
                                    }}
                                    key={seat.email}
                                    setForRemoval={selectedSeatIDs.includes(
                                        seat.id
                                    )}
                                >
                                    <input
                                        type='checkbox'
                                        onChange={() => {
                                            processSeats(seat.id);
                                        }}
                                        style={{ margin: '5px', width: '20px' }}
                                        checked={selectedSeatIDs.includes(
                                            seat.id
                                        )}
                                    />
                                    <UserName>
                                        {seat.username ? (
                                            seat.username
                                        ) : (
                                            <em>Invitation pending</em>
                                        )}
                                    </UserName>
                                    <Email>{seat.email}</Email>
                                </SingleRow>
                            );
                        })}
                </SmallSeatTable>
                <p
                    style={{
                        margin: '10px',
                        textAlign: 'right',
                    }}
                >
                    Remaining number of seats to select:{' '}
                    {amountOfSeatsToRemove - selectedSeatIDs.length >= 0
                        ? amountOfSeatsToRemove - selectedSeatIDs.length
                        : 0}

                </p>
                {amountOfSeatsToRemove - selectedSeatIDs.length < 0 && (
                    <p
                        style={{
                            fontWeight: 600,
                            margin: '10px',
                            textAlign: 'right',
                        }}
                    >
                        You don’t need to select this many seats!
                    </p>
                )}
                <Buttons>
                    <ButtonNeuComp
                        primary={false}
                        onClick={() => props.cancelSeats()}
                    >
                        Cancel
                    </ButtonNeuComp>
                    <ButtonNeuComp
                        showDisabled={
                            amountOfSeatsToRemove !== selectedSeatIDs.length
                        }
                        primary
                        onClick={() => releaseTheseSeats()}
                    >
                        Continue
                    </ButtonNeuComp>
                </Buttons>
            </div>
        );
    } else if (props.interimState === 'set_renewing_reminder') {
        return (
            <div>
                <h3 style={{ fontSize: '22px', textTransform: 'none' }}>
                    Some users are scheduled to expire at renewal time.
                </h3>
                <p>
                    If you no longer want these users to expire, after checking
                    out you can go to the user list and change their status from
                    Expiring to Renewing.
                </p>
                <Buttons>
                    <ButtonNeuComp
                        primary={false}
                        onClick={() => props.cancelSeats()}
                    >
                        Cancel
                    </ButtonNeuComp>
                    <ButtonNeuComp primary onClick={() => props.proceed()}>
                        Continue
                    </ButtonNeuComp>
                </Buttons>
            </div>
        );
    }

    return <List />;
};

export default PickSeatsToExpireOrRenewSeats;
