

export interface RefreshTokenPost {
    accountsApiURL: string;
    refreshToken: string;
}

export interface RefreshTokenPostReturnI {
    errors?: { status: string; errors: Array<string> };
    token?: string;
    userinfo?: OmniAccounts.UserObject;
}

const refreshThisToken = async (
    props: RefreshTokenPost
): Promise<RefreshTokenPostReturnI> => {
    const loginParams = new URLSearchParams();
    loginParams.set('refresh_token', props.refreshToken);

    const rawToken = await fetch(`${props.accountsApiURL}/api/1.1/refresh/`, {
        body: loginParams,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
        },
        method: 'POST',
        mode: 'cors',
    })
        .then((response) => {
            return response.json();
        })
        .then((response) => {
            if (!response.errors) {
                return response;
            } else {
                return { errors: response };
            }
        })
        .catch((err) => {
            console.log(err);
            return err;
        });

    return rawToken;
};

export default refreshThisToken;
