interface GetConfigurationResponse {
    accountsApiURL: string;
    purchaseapiURL: string;
    revision: string;
}

export const getConfiguration = async (): Promise<GetConfigurationResponse> => {
    return fetch(`/conf`, {
        cache: 'reload',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        method: 'GET',
        mode: 'no-cors',
    })
        .then((response) => {
            if (response.status === 200) {
                return response.json();
            }
            return {
                //accountsApiURL: 'https://accounts.test.omnigroup.com',
                //purchaseapiURL: 'https://purchase.test.omnigroup.com',
                revision: 'BLANK',
            };
        })
        .catch((err) => {
            console.log(err);
            return {
                //accountsApiURL: 'https://accounts.omnigroup.com',
                //purchaseapiURL: 'https://purchase.omnigroup.com',
                revision: 'BLANK',
            };
        });
};
