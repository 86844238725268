interface GetStripeEnvResponse {
    publishable_key: string;
}

export const getStripeEnvironmentConfiguration = async (
    accountsurl: string
): Promise<GetStripeEnvResponse> => {
    return await fetch(`${accountsurl}/api/v2/get_conf`)
        .then((response) => {
            return response.json();
        })
        .catch((err) => console.log('err', err));
};
