import Stripe from 'stripe';

export interface PostCreateInvoice {
    customer_id: string;
    purchaseapiURL: string;
    accountsToken: string;
    subscription_id: string;
}

export interface PostCreateInvoiceResponseI {
    invoice?: Stripe.Invoice;
    errors?: string;
}

export const postCreateInvoice = async ({
    customer_id,
    purchaseapiURL,
    accountsToken,
    subscription_id,
}: PostCreateInvoice): Promise<PostCreateInvoiceResponseI> => {
    return await new Promise((resolve, reject) => {
        fetch(
            `${purchaseapiURL}/api/v2/customer/${customer_id}/invoice/${subscription_id}/create`,
            {
                body: JSON.stringify({ auto_advance: true }),
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${accountsToken}`,
                    'Content-Type': 'application/json',
                },
                method: 'POST',
                mode: 'cors',
            }
        )
            .then((response) => {
                return Promise.resolve(response.json()).then((res) => {
                    if (!res.errors) {
                        resolve({ invoice: res });
                    } else if (res.errors) {
                        console.log(res.errors);
                        return reject({ errors: res.errors });
                    }
                    return reject(true);
                });
            })
            .catch((err) => {
                console.log('err', err);
                return reject();
            });
    });
};
