interface GetSeatDataResponse {
    seats: Array<string>;
}

export const getSeatData = async (accountsApiURL: string, accountsToken: string): Promise<GetSeatDataResponse> => {
    const seatData = await fetch(`${accountsApiURL}/api/1.1/seats/`, {
        body: JSON.stringify({ show_personal_seats: false }),
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + accountsToken,
            'Content-Type': 'application/json',
        },
        method: 'POST',
        mode: 'cors',
    })
    .then((response) => response.json())
    .catch((err) => err);

    return seatData;
};
