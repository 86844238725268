import styled from 'styled-components';

export interface ErrorBoxProps {
    children: React.ReactNode;
    errorTitle?: string;
    style?: React.CSSProperties;
    textLeft?: boolean;
}

const StyledErrorBox = styled.div<ErrorBoxProps>`
    text-align: ${(props) => (props.textLeft ? 'left' : 'center')};
    padding: 15px 20px;
    margin: 20px 0;
    background-color: #ffff0055;
    border-radius: 8px;
    line-height: 1.3em;

    h2 {
        font-size: 18px;
        padding-bottom: 2px;
        display: inline-block;
        margin-bottom: 20px;
    }

    p {
        margin-top: 0;
    }

    ul {
        list-style-type: '-';
        padding-left: 10px;
        margin-top: 0;
        li {
            padding-left: 10px;
        }
    }
`;

const ErrorBox: React.FC<ErrorBoxProps> = ({
    children,
    ...props
}: ErrorBoxProps) => {
    return (
        <StyledErrorBox {...props}>
            <h2>{props.errorTitle}</h2>
            {children}
        </StyledErrorBox>
    );
};

export default ErrorBox;
