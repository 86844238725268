import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { ActionType } from '../store/types';
import { ButtonNeuComp } from '../reusable-components/Button';

const ModalWrapper = styled.div`
    height: 100vh;
    width: 100vw;
    top: 0;
    position: fixed;
    background-color: rgba(0,0,0,0.35);
    overflow: hidden;
`;

const Modal = styled.div`
    margin: auto;
    margin-top: 400px;
    width: 50%;
    top: 50%;
    background-color: white;
    padding: 35px;
    border-radius: 10px;
    max-width: 600px;
`;

const Header = styled.div`
    font-size: 26px;
    font-weight: bold;
    margin: 0;
`;

const Border = styled.span`
    width: 100%;
    display: inline-block;
    background-color: #635C5C; /* dark gray */
    height: 2px;
    margin: 20px 0;
`;

const Description = styled.div`
    font-size: 18px;
    line-height: 1.5em;
`;


const ButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    padding-top: 10px;

    > a {
        text-decoration: none;
    }
`;

interface AssignSeatPortalProps {
    toggleModal: (toggle: boolean) => void;
    teamId: null | number;
}

const AssignSeatPortal: React.FC<AssignSeatPortalProps> = (props) => {
    const dispatch = useDispatch();

    useEffect(() => {
        const teamIdToString = props.teamId?.toString();

        dispatch({
            type: ActionType.SET_TEAM_ID_TO_EDIT,
            value: teamIdToString,
        })
    }, []);
    
    return (
        <ModalWrapper>
            <Modal>
                <Header>Reassign Seat</Header>
                <Border></Border>
                <Description>
                    This license is currently assigned to you. 
                    If you wish to assign it to someone else, you can do that via the Team Management interface.
                </Description>
                <Border></Border>
                <ButtonWrapper>
                    <ButtonNeuComp 
                        primary={true}
                        onClick={() => props.toggleModal(false)}
                    >
                        Cancel
                    </ButtonNeuComp>
                    <Link 
                        to={'/manage-team'} >
                        <ButtonNeuComp primary={true}>Team Management</ButtonNeuComp>
                    </Link>
                </ButtonWrapper>
            </Modal>
        </ModalWrapper>
    )
}

export default AssignSeatPortal;