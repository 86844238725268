import * as React from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';

import HoldingBox from '../reusable-components/HoldingBox';
import { Button } from '../reusable-components/Button';
import ErrorBox from '../reusable-components/ErrorBox';
import { ActionType } from '../store/types';

const LineItemHeader = styled.div`
    font-weight: bold;
`;

const LineItem = styled.p`
    font-size: 16px;
`;

const Buttons = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const PasswordInput = styled.input`
    font-size: 16px;

    @media (max-width: 600px) {
        width: 90%;
    }
`;

const Password: React.FC = () => {
    const dispatch = useDispatch();
    const [success, setSuccess] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [oldPassword, setOldPassword] = React.useState<string>('');
    const [newPassword, setNewPassword] = React.useState<string>('');
    const [errorString, setErrorString] = React.useState<string>('');
    const [errors, setErrors] =
        React.useState<Array<{ message: string; code: number }>>();
    const [confirmedNewPassword, setConfirmedNewPassword] =
        React.useState<string>('');

    const accountsApiURL = useSelector(
        (state: OmniAccounts.GlobalReduxState) => state.accountsApiURL
    );

    const accountsToken = useSelector(
        (state: OmniAccounts.GlobalReduxState) => state.accountsToken
    );

    const editPasswordReady = useSelector(
        (state: OmniAccounts.GlobalReduxState) => state.editPasswordReady
    );

    const handleSubmit = async (e: React.SyntheticEvent) => {
        e.preventDefault();

        setLoading(true);

        if (newPassword !== confirmedNewPassword) {
            setErrorString('Your new passwords don’t match.');
            setLoading(false);
        } else if (oldPassword === '') {
            setErrorString(
                'You must enter your old password before proceeding.'
            );
            setLoading(false);
        } else {
            const passwordChangeResponse = await handlePasswordChange();

            if (!passwordChangeResponse.errors) {
                if ('updated_attributes' in passwordChangeResponse) {
                    setSuccess(true);
                    setLoading(false);
                    dispatch({ type: ActionType.EDIT_PASSWORD });
                }
            } else if (passwordChangeResponse.errors !== undefined) {
                if (passwordChangeResponse.errors.length >= 1) {
                    setErrors(passwordChangeResponse.errors);
                }
                switch (passwordChangeResponse.errors[0].code) {
                    case 3022: //unsure
                        setLoading(false);
                        setErrorString('Something went wrong.');
                        break;
                    case 3023: //password is too short
                        setLoading(false);
                        setErrorString('Something went wrong.');
                        break;
                    case 3024: //they got a bad password
                        setLoading(false);
                        setErrorString('Something went wrong.');
                        break;
                    default:
                        setLoading(false);
                        setErrorString(
                            'Something went wrong, but it’s not related to your account name, password, or account status. Email support.'
                        );
                        break;
                }
            }
        }
    };

    interface PasswordResponseI {
        status: string;
        errors?: Array<{ message: string; code: number }>;
    }

    const handlePasswordChange = async (): Promise<PasswordResponseI> => {
        return await new Promise((resolve, reject) => {
            fetch(`${accountsApiURL}/api/1.1/user/`, {
                body: JSON.stringify({
                    new_password: newPassword,
                    new_password_2: confirmedNewPassword,
                    old_password: oldPassword,
                }),
                headers: {
                    Authorization: 'Bearer ' + accountsToken,
                    'Content-Type': 'application/json; charset=utf-8',
                },
                method: 'POST',
                mode: 'cors',
            })
                .then((response) => {
                    resolve(response.json());
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };

    return (
        <HoldingBox
            sectionTitle='Password'
            actionTitle='Update Password'
            clickAction={() => dispatch({ type: ActionType.EDIT_PASSWORD })}
            cancelAction={() => dispatch({ type: ActionType.EDIT_PASSWORD })}
            activeWatcher={editPasswordReady}
        >
            {editPasswordReady ? (
                <form onSubmit={(event) => handleSubmit(event)}>
                    <LineItemHeader>Password</LineItemHeader>
                    {errors !== undefined && (
                        <ErrorBox textLeft errorTitle={errorString}>
                            <ul>
                                {errors.map((error, i) => {
                                    return <li key={i}>{error.message}</li>;
                                })}
                            </ul>
                        </ErrorBox>
                    )}
                    <label className='labelthing' htmlFor='current-password'>
                        Current Password:
                        <PasswordInput
                            type='password'
                            id='oldPassword'
                            autoComplete='current-password'
                            onChange={(e) => setOldPassword(e.target.value)}
                        />
                    </label>
                    <label className='labelthing' htmlFor='newPassword'>
                        New Password:
                        <PasswordInput
                            type='password'
                            id='newPassword'
                            autoComplete='new-password'
                            onChange={(e) => setNewPassword(e.target.value)}
                        />
                    </label>
                    <label className='labelthing' htmlFor='confirmPassword'>
                        New Password Again:
                        <PasswordInput
                            type='password'
                            id='confirmPassword'
                            autoComplete='new-password'
                            onChange={(e) =>
                                setConfirmedNewPassword(e.target.value)
                            }
                        />
                    </label>
                    <Buttons>
                        <Button
                            showLoading={loading ? loading : undefined}
                            type='submit'
                        >
                            Update Password
                        </Button>
                    </Buttons>
                </form>
            ) : (
                <React.Fragment>
                    <LineItemHeader>Password</LineItemHeader>
                    <LineItem>***************</LineItem>
                    {success && <p>Password successfully changed.</p>}
                </React.Fragment>
            )}
        </HoldingBox>
    );
};

export default Password;
