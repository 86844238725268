import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import AddEmail from './AddEmail';
import SeatTable from './SeatTable';
import {
    Button,
    ButtonNeuComp,
    WarningButton,
} from '../../reusable-components/Button';
import deleteEmails from './utilities/deleteEmails';
import ErrorBox from '../../reusable-components/ErrorBox';
import Modal from 'react-modal';

import dayjs from 'dayjs';
import { AddSeats } from './AddSeats';
import cancelSubscription from '../../utilities/cancelSubscription';
import { modalStyles } from './modalStyles';
import { postUpdateSubscription } from '../../utilities/postUpdateSubscription';
import getSubscriptionData from '../../utilities/getSubscriptionData';
import getPlans from '../../utilities/getPlans';
import Stripe from 'stripe';
import { ActionType } from '../../store/types';
import { List } from 'react-content-loader';
import { postRetrieveInvoicePreview } from '../../utilities/postRetrieveInvoicePreview';

const Meta = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0;
    width: auto;
    min-height: 70px;
    color: #404040;
`;

const SeatsInfo = styled.div`
    display: flex;
    flex-direction: row;
    padding: 0;
    margin: 0;
    font-size: 16px;
    align-items: center;
    font-weight: 500;
    justify-content: space-between;
    border-bottom: 1px solid rgba(212, 207, 199, 1);
`;

const MetaDiv = styled.div`
    margin: 10px 0 0;

    p {
        font-size: 12px;
        margin: 0;
        font-weight: 400;
    }
`;

const SubscriptionHeader = styled.div`
    h3 {
        font-size: 36px;
        font-weight: 700;
        text-transform: initial;
    }

    border-bottom: 1px solid rgba(212, 207, 199, 1);
    padding: 10px 0;

    span {
        &::after {
            content: ', ';
        }

        &:last-child::after {
            content: '';
        }
    }
`;

const Buttons = styled.div`
    display: flex;
    flex-direction: row;
    margin: 10px 0;
    justify-content: center;
`;

const LeftSide = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`;

const RightSide = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`;

const ModalP = styled.p`
    font-size: 18px;
    max-width: 500px;
`;

export interface EditSubscriptionSeatsProps {
    currentObject: OmniAccounts.LicenseObject;
}

const EditSubscriptionSeats: React.FC<EditSubscriptionSeatsProps> = (
    props: EditSubscriptionSeatsProps
) => {
    const dispatch = useDispatch();
    Modal.setAppElement('#root');
    const today = dayjs();
    const [loading, setLoading] = React.useState<boolean>(false);
    // const [changeSuccess, setChangeSuccess] = React.useState<boolean>(false);
    const [pendingrefresh, setPendingRefresh] = React.useState<boolean>(false);
    const [cancel, setCancel] = React.useState<boolean>(false);
    const [error, setError] = React.useState<string>('');
    const [emailError, setEmailError] = React.useState<string>('');
    const [positive, setIAmPositive] = React.useState<boolean>(false);
    const [stripePlans, setStripePlans] = React.useState<Array<Stripe.Plan> | undefined>(undefined);
    const [refreshNeeded, setRefreshNeeded] = React.useState<boolean>(false);

    const [listOfEmails, setListOfEmails] = React.useState<Array<{ email: string; status: string }> | undefined>();

    const [changePlanRenewalPeriod, setChangePlanRenewalPeriod] = React.useState<boolean>(false);

    const [whyYouCannotSwitch, toggleWhyYouCannotSwitch] = React.useState<boolean>(false);
    const [changePlanLoading, setChangePlanRenewalPeriodLoading] = React.useState(false);
    const [confirmFinalChangePlanPrice, setConfirmFinalChangePlanPrice] = React.useState(false);
    const [changePlanPeriodReturn, setChangePlanPeriodReturn] = React.useState<Stripe.Invoice>();
    const checkedSeatsForDeletion = useSelector((state: OmniAccounts.GlobalReduxState) => state.checkedSeatsForDeletion);
    const accountsToken = useSelector((state: OmniAccounts.GlobalReduxState) => state.accountsToken);
    const accountsApiURL = useSelector((state: OmniAccounts.GlobalReduxState) => state.accountsApiURL);
    const listOfSeats = useSelector((state: OmniAccounts.GlobalReduxState) => state.listOfSeats);
    const purchaseapiURL = useSelector((state: OmniAccounts.GlobalReduxState) => state.purchaseapiURL);
    const customerInfo = useSelector((state: OmniAccounts.GlobalReduxState) => state.customerInfo );
    const userInfo = useSelector((state: OmniAccounts.GlobalReduxState) => state.userInfo);
    const activeSubscriptionInfo = useSelector((state: OmniAccounts.GlobalReduxState) => state.activeSubscriptionInfo)
    const teamPageIDToEdit = useSelector((state: OmniAccounts.GlobalReduxState) => state.teamPageIDToEdit);

    React.useEffect(() => {
        const fetchSubscriptionData = async () => {
            const custInfo = await customerInfo;
            const subscriptionDataResponse =  custInfo && await getSubscriptionData({ accountsToken, customerID: customerInfo.id, purchaseapiURL });
            
            if (subscriptionDataResponse) {
                dispatch({
                    subscriptions: subscriptionDataResponse,
                    type: ActionType.SAVE_JUST_SUBSCRIPTION_INFO,
                })
            }
        }
        fetchSubscriptionData();
    }, [customerInfo]);


    const confirmChangeFunction = async () => {
        setLoading(true);
        if (customerInfo === undefined || userInfo === null) {
            setLoading(false);
            setError('Unable to modify current subscription. Please contact the Omni Group.');
            return;
        }
        if (activeSubscriptionInfo === undefined || activeSubscriptionInfo === null) {
            setLoading(false);
            setError('Unable to modify current subscription. Please contact the Omni Group.');
            return;
        }

        await postUpdateSubscription({
            accountsToken,
            customerID: userInfo.customer_id[0],
            plan: props.currentObject.product_id.includes('ANNUAL')
                ? props.currentObject.product_id.replace('ANNUAL', 'MONTHLY')
                : props.currentObject.product_id.replace('MONTHLY', 'ANNUAL'),
            proration_behavior: props.currentObject.product_id.includes('ANNUAL')
                ? 'none'
                : 'create_prorations',
            purchaseapiURL,
            quantity: activeSubscriptionInfo
                .filter((sub: Stripe.Subscription) => sub.id === props.currentObject.subscription_id)
                .map((sub) => {
                    return sub.items.data[0].quantity;
                })[0],
            subscriptionID: props.currentObject.subscription_id,
        })
            .then(() => {
                getSubscriptionData({
                    accountsToken,
                    customerID: userInfo.customer_id[0],
                    purchaseapiURL,
                }).then((response) => {
                    setError('');
                    dispatch({
                        subscriptions: response,
                        type: ActionType.SAVE_JUST_SUBSCRIPTION_INFO,
                    });
                    dispatch({
                        type: ActionType.UPDATING_COUNTER,
                    });
                    setChangePlanRenewalPeriod(false);
                    setLoading(false);
                    setRefreshNeeded(true);
                    setChangePlanRenewalPeriod(false);
                    setConfirmFinalChangePlanPrice(false);
                });
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
                setError(
                    'Unable to modify current subscription. Please contact the Omni Group.'
                );
            });
        setLoading(false);
    };

    React.useEffect(() => {
        getPlans(purchaseapiURL).then((result) => setStripePlans(result.plans));
    }, [purchaseapiURL]);


    React.useEffect(() => {
        // in this effect we’re going to previewInvoice and load until that's done.
        if (
            changePlanRenewalPeriod === true &&
            userInfo !== null &&
            customerInfo !== undefined &&
            activeSubscriptionInfo
        ) {
            setChangePlanRenewalPeriodLoading(true);
            const subscriptionTaxData = activeSubscriptionInfo.filter(
                (sub: Stripe.Subscription) => sub.id === props.currentObject.subscription_id
            )[0].default_tax_rates;

            postRetrieveInvoicePreview({
                accountsToken,
                customer_id: userInfo.customer_id[0],
                postBody: {
                    default_tax_rates:
                        subscriptionTaxData !== undefined &&
                        subscriptionTaxData !== null
                            ? subscriptionTaxData[0] !== undefined
                                ? subscriptionTaxData[0].id
                                : undefined
                            : undefined,
                    plan: props.currentObject.product_id.includes('ANNUAL')
                        ? props.currentObject.product_id.replace(
                              'ANNUAL',
                              'MONTHLY'
                          )
                        : props.currentObject.product_id.replace(
                              'MONTHLY',
                              'ANNUAL'
                          ),
                    quantity: props.currentObject.renewing_quantity,
                    subscription_item_id: activeSubscriptionInfo.filter((sub: Stripe.Subscription) =>
                                sub.id === props.currentObject.subscription_id
                        )[0].items.data[0].id,
                },
                purchaseapiURL,
                subscription_id: props.currentObject.subscription_id,
            })
                .then((result) => {
                    setChangePlanPeriodReturn(result);
                    setChangePlanRenewalPeriodLoading(false);
                    setConfirmFinalChangePlanPrice(true);
                })
                .catch(() =>
                    setError(
                        'Something went wrong fetching a preview of your invoice. Please try again later.'
                    )
                );
        }
    }, [changePlanRenewalPeriod]);

    interface CancelTeamSubI {
        planID: string;
        unCancel: boolean;
    }

    const cancelTeamSub = async ({ planID, unCancel }: CancelTeamSubI) => {
        setLoading(true);
        if (customerInfo === undefined || userInfo === null) {
            setLoading(false);
            setError(
                'Unable to modify current subscription. Please contact the Omni Group.'
            );
            return;
        }

        await cancelSubscription({
            accountsToken,
            cancelSubscription: unCancel ? false : true,
            currentPlanID: planID,
            customerID: userInfo.customer_id[0],
            purchaseapiURL,
        })
            .then(() => dispatch({ type: ActionType.REFRESH_USER_LICENSES }));
        setLoading(false);
        return true;
    };

    const interimDeleteAgree = () => {
        setIAmPositive(false);
        if (teamPageIDToEdit === null) return;
        deleteEmails(
            checkedSeatsForDeletion,
            accountsToken,
            accountsApiURL,
            props.currentObject.id
        )
            .then((result) => {
                if ('invalid_seats' in result) {
                    setEmailError(result.message);
                    setListOfEmails(result.invalid_seats?.map((seat) => seat));
                    return { errorString: true };
                }
                return false;
            })
            .then(() => dispatch({ type: ActionType.CLEAR_SEAT_REMOVAL_ARRAY }))
            .then(() => dispatch({ type: ActionType.CLEAR_LIST_OF_SEATS }))
            .then(() =>
                dispatch({
                    subscription: teamPageIDToEdit,
                    type: ActionType.REFRESH_THIS_SUBSCRIPTION,
                })
            );
    };

    if (
        stripePlans === undefined ||
        teamPageIDToEdit === null ||
        props.currentObject === undefined ||
        userInfo === null
    ) {
        return null;
    }

    return (
        <React.Fragment key={props.currentObject.id}>
            <Meta>
                <SubscriptionHeader>
                    <h3>
                        {props.currentObject.description ===
                        'OmniOutliner 5 for Mac'
                            ? 'OmniOutliner 5 Essentials for Mac'
                            : props.currentObject.description}
                    </h3>
                    {props.currentObject.subscription_id !== undefined && (
                        <span>
                            Includes{' '}
                            {props.currentObject.products[0]
                                .split(', ')
                                .filter((a: string) => !a.includes('8'))
                                .filter((x: string) => !x.includes('OmniFocus 3'))
                                .map((a: string, b: number) => (
                                    <span key={b}>{a.replace('vos', 'for Apple Vision Pro')}</span>
                                ))}
                        </span>
                    )}
                </SubscriptionHeader>
                {!refreshNeeded ? (
                    <SeatsInfo>
                        <LeftSide>
                            <MetaDiv>
                                <strong>Total Seats</strong>:{' '}
                                {props.currentObject.total_seats}
                            </MetaDiv>
                            <MetaDiv>
                                <strong>Total Seats After Next Renewal</strong>:{' '}
                                {props.currentObject.renewing_quantity}
                                <p>
                                    Assigned Seats:{' '}
                                    {props.currentObject.used_seats}{' '}
                                    {props.currentObject.invited_seats > 0 && (
                                        <span>
                                            (
                                            {props.currentObject.used_seats -
                                                props.currentObject
                                                    .invited_seats}{' '}
                                            in use;{' '}
                                            {props.currentObject.invited_seats}{' '}
                                            pending).
                                        </span>
                                    )}{' '}
                                    Unused Seats:{' '}
                                    {props.currentObject.available_seats}
                                </p>
                            </MetaDiv>
                            <MetaDiv>
                                <strong>Used Seats:</strong>{' '}
                                {props.currentObject.used_seats}
                            </MetaDiv>
                            <MetaDiv>
                                <strong>Renewal Period: </strong>
                                {props.currentObject.product_id.includes(
                                    'MONTHLY'
                                )
                                    ? 'Monthly'
                                    : 'Yearly'}
                            </MetaDiv>
                            {props.currentObject.subscription_id !==
                                undefined && (
                                <MetaDiv>
                                    <strong>
                                        {props.currentObject
                                            .cancel_at_period_end === true ||
                                        props.currentObject
                                            .subscription_state !== 'active'
                                            ? 'Expiring:'
                                            : 'Renewal Date:'}
                                    </strong>{' '}
                                    {dayjs(
                                        props.currentObject.expiration_date
                                    ).format('MMMM DD, YYYY')}
                                </MetaDiv>
                            )}
                        </LeftSide>
                        <RightSide>
                            <AddSeats
                                currentlyEditingID={props.currentObject.id}
                                disableAtWill={pendingrefresh}
                                licenseInQuestion={props.currentObject}
                                userID={userInfo.customer_id[0]}
                                seatList={
                                    listOfSeats
                                        .filter(
                                            (seat) =>
                                                seat.id ===
                                                props.currentObject.id
                                        )
                                        .map((seat) => {
                                            return seat.seats;
                                        })[0]
                                }
                            />
                            <Button
                                showDisabled={
                                    props.currentObject.subscription_state !==
                                    'active'
                                }
                                onClick={
                                    props.currentObject.product_id.includes(
                                        'ANNUAL'
                                    ) &&
                                    dayjs(
                                        props.currentObject.expiration_date
                                    ).diff(today, 'day') > 30
                                        ? () => toggleWhyYouCannotSwitch(true)
                                        : () => setChangePlanRenewalPeriod(true)
                                }
                                style={
                                    props.currentObject.product_id.includes(
                                        'ANNUAL'
                                    ) &&
                                    dayjs(
                                        props.currentObject.expiration_date
                                    ).diff(today, 'day') > 30
                                        ? {
                                              backgroundColor: '#e6e6e6',
                                              border: 'none',
                                              color: 'rgb(107 107 107)',
                                          }
                                        : {}
                                }
                            >
                                Switch to{' '}
                                {!props.currentObject.product_id.includes(
                                    'MONTHLY'
                                )
                                    ? 'Monthly'
                                    : 'Yearly'}
                            </Button>
                            <React.Fragment>
                                {props.currentObject.subscription_state !==
                                    'inactive' &&
                                    !props.currentObject
                                        .cancel_at_period_end && (
                                        <WarningButton
                                            onClick={() => setCancel(true)}
                                        >
                                            Cancel Subscription{' '}
                                            {pendingrefresh && '(Pending)'}
                                        </WarningButton>
                                    )}
                                {props.currentObject.subscription_state !==
                                    'inactive' &&
                                    props.currentObject
                                        .cancel_at_period_end && (
                                        <Button
                                            showLoading={loading}
                                            danger
                                            onClick={() => {
                                                setLoading(true);
                                                cancelTeamSub({
                                                    planID: props.currentObject
                                                        .subscription_id,
                                                    unCancel: true,
                                                })
                                                    .then(() => {
                                                        setPendingRefresh(true);
                                                    })
                                                    .then(() => {
                                                        dispatch({
                                                            subscription:
                                                                teamPageIDToEdit,
                                                            type: ActionType.REFRESH_THIS_SUBSCRIPTION,
                                                        });
                                                    });
                                            }}
                                        >
                                            Turn on Auto-Renewal (Uncancel){' '}
                                            {pendingrefresh && '(Pending)'}
                                        </Button>
                                    )}
                            </React.Fragment>
                        </RightSide>
                    </SeatsInfo>
                ) : (
                    <SeatsInfo>
                        <LeftSide>
                            <h2 style={{ margin: '20px 0' }}>
                                Subscription Changed
                            </h2>
                        </LeftSide>
                    </SeatsInfo>
                )}
            </Meta>
            <Modal isOpen={cancel} style={modalStyles}>
                <h2>Cancel Subscription</h2>
                <p style={{ maxWidth: '500px' }}>
                    Are you sure you want to let your subscription expire at the
                    end of the billing period? You won‘t be billed again, and
                    will continue to have access until{' '}
                    {dayjs(props.currentObject.expiration_date).format(
                        'MMMM DD, YYYY'
                    )}
                    .
                </p>
                <Buttons>
                    <ButtonNeuComp
                        primary={false}
                        onClick={() => setCancel(false)}
                    >
                        Do not Cancel
                    </ButtonNeuComp>
                    <ButtonNeuComp
                        primary
                        showLoading={loading ? loading : false}
                        onClick={() =>
                            cancelTeamSub({
                                planID: props.currentObject.subscription_id,
                                unCancel: false,
                            }).then(() => {
                                setCancel(false);
                                setPendingRefresh(true);
                            })
                        }
                    >
                        Cancel Subscription
                    </ButtonNeuComp>
                </Buttons>
            </Modal>
            <Modal isOpen={whyYouCannotSwitch} style={modalStyles}>
                <h2>Subscription period information</h2>
                <ModalP>
                    You cannot currently switch to a monthly subscription
                    because you still have more than 30 days of unused time
                    remaining. You can switch within the last 30 days of your
                    yearly subscription.
                </ModalP>
                <div style={{ margin: '20px auto' }}>
                    <Button
                        onClick={() => toggleWhyYouCannotSwitch(false)}
                        center
                    >
                        OK
                    </Button>
                </div>
            </Modal>
            <Modal isOpen={confirmFinalChangePlanPrice} style={modalStyles}>
                {changePlanLoading ? (
                    <List />
                ) : (
                    <div style={{ width: '500px' }}>
                        <h2>
                            Switch to{' '}
                            {!props.currentObject.product_id.includes('MONTHLY')
                                ? 'Monthly'
                                : 'Yearly'}{' '}
                            Subscription?
                        </h2>
                        <ModalP>
                            {props.currentObject.description.split(' ')[0]}{' '}
                            {!props.currentObject.product_id.includes('MONTHLY')
                                ? 'monthly'
                                : 'yearly'}{' '}
                            subscriptions are{' $'}
                            {stripePlans
                                .filter(
                                    (plan: Stripe.Plan) =>
                                        plan.id ===
                                        props.currentObject.product_id.replace(
                                            props.currentObject.product_id.includes(
                                                'MONTHLY'
                                            )
                                                ? 'MONTHLY'
                                                : 'ANNUAL',
                                            props.currentObject.product_id.includes(
                                                'MONTHLY'
                                            )
                                                ? 'ANNUAL'
                                                : 'MONTHLY'
                                        )
                                )
                                .map((plan: Stripe.Plan) =>
                                    plan.amount !== null
                                        ? plan.amount / 100
                                        : 0 / 100
                                )}{' '}
                            per seat, plus applicable sales tax.
                        </ModalP>
                        <ModalP>
                            You will receive credit for the remaining time of
                            your subscription and be charged $
                            {changePlanPeriodReturn !== undefined
                                ? (changePlanPeriodReturn.total / 100).toFixed(
                                      2
                                  )
                                : 0}
                            .
                        </ModalP>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <ButtonNeuComp
                                primary={false}
                                onClick={() => {
                                    {
                                        setConfirmFinalChangePlanPrice(false);
                                        setChangePlanPeriodReturn(undefined);
                                    }
                                }}
                            >
                                Cancel
                            </ButtonNeuComp>
                            <ButtonNeuComp
                                onClick={() => confirmChangeFunction()}
                                primary
                                showLoading={loading ? loading : false}
                                style={{ marginLeft: '10px' }}
                            >
                                Pay $
                                {changePlanPeriodReturn !== undefined
                                    ? (
                                          changePlanPeriodReturn.total / 100
                                      ).toFixed(2)
                                    : 0}
                            </ButtonNeuComp>
                        </div>
                    </div>
                )}{' '}
            </Modal>

            <AddEmail
                currentlyEditingID={props.currentObject.id}
                currentObject={props.currentObject}
                showDisabled={props.currentObject.available_seats <= 0}
            />
            {emailError && (
                <ErrorBox
                    errorTitle={'Error removing seats'}
                    style={{ maxWidth: '500px' }}
                    textLeft
                >
                    <p>{error}</p>
                    <p>
                        You may need to refresh this page if seats were recently
                        modified.
                    </p>
                    <ul>
                        {listOfEmails !== undefined &&
                            listOfEmails.map((item) => (
                                <li
                                    key={item.email}
                                    style={{ margin: '5px 20px' }}
                                >
                                    {item.email}
                                </li>
                            ))}
                    </ul>
                </ErrorBox>
            )}
            <SeatTable
                currentObject={props.currentObject}
                currentlyEditingID={props.currentObject.id}
                seatList={
                    listOfSeats
                        .filter((seat) => seat.id === props.currentObject.id)
                        .map((seat) => {
                            return seat.seats;
                        })[0]
                }
                subscription={true}
            />
            <div>
                <Button
                    onClick={() => setIAmPositive(true)}
                    showDisabled={
                        checkedSeatsForDeletion.length === 0 ? true : false
                    }
                >
                    Remove User(s)
                </Button>
            </div>

            <Modal isOpen={positive} style={modalStyles}>
                <h2>Confirm Removal</h2>
                <p>
                    Are you <em>sure</em> you want to remove{' '}
                    {checkedSeatsForDeletion.length >= 2
                        ? 'these users'
                        : 'this user'}{' '}
                    from the team?
                </p>
                <Buttons>
                    <ButtonNeuComp
                        primary={false}
                        onClick={() => setIAmPositive(false)}
                    >
                        No!
                    </ButtonNeuComp>
                    <ButtonNeuComp primary onClick={() => interimDeleteAgree()}>
                        Yes
                    </ButtonNeuComp>
                </Buttons>
            </Modal>
        </React.Fragment>
    );
};

export default EditSubscriptionSeats;
