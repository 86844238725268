import * as React from 'react';
import AppRouter from './AppRouter';

import { createStore, Store } from 'redux';
import { Provider } from 'react-redux';
import reducer from './store/reducer';
import { composeWithDevTools } from 'redux-devtools-extension';

//Store
const configureStore = (initialState?: OmniAccounts.GlobalReduxState): Store => {
    const store = createStore(reducer, initialState, composeWithDevTools());

    return store;
};

export const store = configureStore();
const Index = (): React.ReactElement => {
    return (
        <Provider store={store}>
            <AppRouter />
        </Provider>
    );
};

export default Index;
