import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import AccountSetup from './AccountSetup';
import { Button } from '../reusable-components/Button';
import ErrorBox from '../reusable-components/ErrorBox';
import { postConfirmEmail } from '../utilities/postConfirmEmail';

const SignUpForm = styled.div`
    max-width: 800px;
    margin: 30px auto;
    padding: 0 10px;

    h1 {
        font-size: 26px;
        text-align: center;
    }

    h2 {
        text-align: center;
        margin: 5px 0;
        font-weight: 800;
    }

    p {
        max-width: 500px;
        margin: 10px auto;
    }

    form {
        max-width: 500px;
        margin: auto;
    }
`;

const Buttons = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

const AccountCreated = styled.div`
    text-align: center;
    margin: auto;

    a {
        color: #fff;
        text-decoration: none;
    }

    p {
        max-width: initial;
        margin-top: 40px;
    }
`;

const SignUp: React.FC = () => {
    const [finalDestinationURL, setFinalDestinationURL] = useState<string>('');
    const [confirmKey, setConfirmKey] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [accountEmail, setAccountEmail] = useState<string>('');
    const [stepOneComplete, setStepOneComplete] = useState<boolean>(false);
    const [stepTwoComplete, setStepTwoComplete] = useState<boolean>(false);
    const [stepThreeComplete, setStepThreeComplete] = useState<boolean>(false);
    const [signupAccessToken, setSignupAccessToken] = useState<string>('');
    const [errorLanguage, setErrorLanguage] = useState<string>('');
    const [interimConfirmationStep, setInterimConfirmationStep] = useState<boolean>(false);

    const accountsApiURL = useSelector((state: OmniAccounts.GlobalReduxState) => state.accountsApiURL);

    useEffect(() => {
        document.title = 'Create Your Omni Account - The Omni Group';
        const doNotUseForReferrer = [
            'https://accounts.dev.omnigroup.com/register',
            'https://accounts.omnigroup.com/register',
            'http://localhost:3000/register',
        ];
        if (!doNotUseForReferrer.includes(document.referrer)) {
            sessionStorage.setItem('registerReferrer', document.referrer);
        } else {
            sessionStorage.setItem('registerReferrer', '/');
        }
        const finalDestination = sessionStorage.getItem('registerReferrer');
        setFinalDestinationURL(finalDestination ? finalDestination : '');
        const signUpEmail = localStorage.getItem('signUpEmail');
        const signUpToken = localStorage.getItem('signUpToken');
        if (typeof signUpEmail === 'string') {
            setAccountEmail(signUpEmail);
            setInterimConfirmationStep(true);
        } else {
            startSignupOver();
        }
        if (typeof signUpToken === 'string') {
            setSignupAccessToken(signUpToken);
        }
    }, []);

    const startSignupOver = () => {
        setSignupAccessToken('');
        setAccountEmail('');
        setErrorLanguage('');
        setLoading(false);
        setStepOneComplete(false);
        setInterimConfirmationStep(false);
        localStorage.removeItem('signUpToken');
        localStorage.removeItem('signUpEmail');
        return;
    };

    const signUpEmail = async (e: React.SyntheticEvent) => {
        if (e !== undefined) {
            e.preventDefault();
        }
        setLoading(true);

        if (accountEmail === undefined) {
            setLoading(false);
            return;
        }

        try {
            const response = await postConfirmEmail({ accountEmail, accountsApiURL, confirmKey: undefined, signupAccessToken});

            if (response?.results === 'success') {
                localStorage.setItem('signUpEmail', accountEmail);
                localStorage.setItem('signUpToken', response.token.access_token);
                setSignupAccessToken(response.token.access_token);
                setErrorLanguage('');
                setLoading(false);
                setStepOneComplete(true);
            } else if (response?.errors) {
                const responseErrorCode = response.errors[0].code;

                if (responseErrorCode === 11002) {
                    setErrorLanguage( 'A necessary service to create new accounts is not responding. Please try again later.')
                } else {
                    setErrorLanguage('The requested email address is not available.')
                }
                setLoading(false);
                setStepOneComplete(false);
            }
        } catch (error) {
            setErrorLanguage('Something went wrong. Refresh this page and try again.');
            setLoading(false);
            setStepOneComplete(false);
        }
    };

    const confirmEmail = async (e: React.SyntheticEvent) => {
        e.preventDefault();
        setLoading(true);

        if (accountEmail === undefined || signupAccessToken === '') {
            setLoading(false);
            return;
        }

        try {
            const response = await postConfirmEmail({ accountEmail, accountsApiURL, confirmKey, signupAccessToken});

            if (response?.results === 'success') {
                setErrorLanguage('');
                setLoading(false);
                setStepTwoComplete(true);
                setSignupAccessToken(response.token.access_token);
                return;
            } else if (response?.errors) {
                const errorMessage = response.errors[0].message;

                if (errorMessage){
                    setErrorLanguage(errorMessage);
                } else {
                    startSignupOver();
                    setErrorLanguage( 'Whoops! It looks like we’ll actually need to start over — the confirmation code had expired.');
                }
                setLoading(false);
                setStepTwoComplete(false);
            }
        } catch (error) {
            console.log('Confirm Email Error: ', error)
        }
    };

    return (
        <SignUpForm>
            {!stepThreeComplete && (
                <React.Fragment>
                    <h1>Sign up for an Omni Account</h1>
                    <p>
                        Your Omni Account is your key to services provided by
                        The Omni Group. This includes the Omni Sync Server,
                        OmniFocus for the Web, subscription and license
                        management, and future new services.
                    </p>
                </React.Fragment>
            )}
            {errorLanguage.length > 0 && (
                <ErrorBox>
                    {errorLanguage} Did you mean to <a href='/'>sign in</a>?
                </ErrorBox>
            )}

            {!stepOneComplete && (
                <form onSubmit={(e) => signUpEmail(e)}>
                    {interimConfirmationStep && (
                        <ErrorBox>
                            <p>
                                We found an in-progress signup. Would you like
                                to continue with {accountEmail}?
                            </p>
                            <Buttons>
                                <Button someMargin={true} onClick={() => startSignupOver()}>
                                    Start Over
                                </Button>
                                <Button someMargin={true}>Continue</Button>
                            </Buttons>
                        </ErrorBox>
                    )}
                    <label htmlFor='accountEmail'>Email Address:</label>
                    <input
                        autoComplete='false'
                        name='accountEmail'
                        placeholder=''
                        autoCapitalize='false'
                        onChange={(e) => setAccountEmail(e.target.value)}
                        spellCheck='false'
                        type='email'
                        autoFocus
                        style={{ width: '100%' }}
                    />
                    <Button
                        showDisabled={!accountEmail}
                        type='submit'
                        value='Sign Up'
                        showLoading={loading ? loading : undefined}
                    >
                        Sign Up
                    </Button>
                </form>
            )}
            {stepOneComplete && !stepTwoComplete && !stepThreeComplete && (
                <form onSubmit={(e) => confirmEmail(e)}>
                    <p>
                        To complete your registration, check your email for a
                        confirmation key to enter below.
                    </p>
                    <label htmlFor='confirmKey'>Confirm Key:</label>
                    <input
                        autoComplete='false'
                        name='confirmKey'
                        placeholder='ABC XYZ'
                        onChange={(e) => setConfirmKey(e.target.value)}
                        spellCheck='false'
                        type='text'
                        autoFocus
                    />
                    <Button
                        showLoading={loading ? loading : undefined}
                        showDisabled={!confirmKey}
                        type='submit'
                        value='Sign Up'
                    >
                        Submit
                    </Button>
                </form>
            )}
            {stepOneComplete && stepTwoComplete && !stepThreeComplete && signupAccessToken !== undefined && (
                    <AccountSetup
                        accessToken={signupAccessToken}
                        stepThreeComplete={() => setStepThreeComplete(true)}
                    />
                )}
            {stepThreeComplete && (
                <AccountCreated>
                    <h2>Your Omni Account is ready to go!</h2>
                    <p>
                        <a href={finalDestinationURL}>
                            <Button center={true} showLoading={false}>
                                Continue
                            </Button>
                        </a>
                    </p>
                </AccountCreated>
            )}
        </SignUpForm>
    );
};

export default SignUp;
