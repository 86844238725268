import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import Logout from './Logout';
import OmniMenu from './OmniHeader';

const HeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #000;
`;

const Account = styled.div`
    margin: 10px 20px 0 auto;
    background-color: #000;
    display: flex;
    flex-direction: row;

    @media (max-width: 600px) {
        flex-direction: column;
        margin: 10px;
    }
`;

const SubHead = styled.div`
    display: grid;
    grid-template-columns: 300px auto 300px;
    @media (max-width: 600px) {
        grid-template-columns: unset;
    }
`;

const NavigateBack = styled.div`
    color: #fff;
    font-size: 20px;
    grid-column-start: 1;
    grid-column-end: 2;
    margin: 10px auto;

    &::before {
        content: '<';
        padding-right: 10px;
        font-size: 25px;
    }
`;

const Title = styled.div`
    font-size: 40px;
    text-align: center;
    font-weight: 700;
    padding: 30px 0 20px 0;
    align-self: center;
    color: #fff;

    a {
        color: #fff;
        text-decoration: none;
    }
`;

interface HeadersI {
    bigger?: boolean;
    bottomPadding?: boolean;
}

const Headers = styled.div<HeadersI>`
    display: flex;
    flex-direction: column;
    margin: auto;
    grid-column-start: ${(props) => (props.bigger ? '1' : '2')};
    grid-column-end: ${(props) => (props.bigger ? '4' : '3')};
    padding: ${(props) => (props.bottomPadding ? '10px 0' : '0 0 10px')};

    @media (max-width: 600px) {
        width: 100%;
    }
`;

const Header: React.FC = () => {
    const signedIn = useSelector((state: OmniAccounts.GlobalReduxState) => state.signedIn);
    const location = useLocation();

    return (
        <HeaderContainer>
            <OmniMenu />
            {signedIn && !location.pathname.includes('manage-team') && (
                <Account>
                    <Logout />
                </Account>
            )}
            <SubHead>
                {(location.pathname.includes('manage-team') ||
                    location.pathname.includes('license-upgrade')
                ) && (
                    <Link
                        style={{ margin: 'auto', textDecoration: 'none' }}
                        to='/'
                        onClick={() => {
                            sessionStorage.removeItem('teameditid');
                        }}
                    >
                        <NavigateBack>Back to Omni Accounts</NavigateBack>
                    </Link>
                )}
                {location.pathname.includes('manage-team') ? (
                    <Headers bottomPadding={true}>
                        <Title>Team Management</Title>
                    </Headers>
                ) : location.pathname.includes('license-upgrade') ? (
                    <Headers bottomPadding={true}>
                        <Title>License Upgrades</Title>
                    </Headers>
                ) : (
                    <Headers bigger={true}>
                        <Title>
                            <a href='/'>Omni Accounts</a>
                        </Title>
                    </Headers>
                )}
            </SubHead>
        </HeaderContainer>
    );
};

export default Header;
