export const modalStyles = {
    content: {
        border: 0,
        borderRadius: '6px',
        bottom: 'auto',
        boxShadow: '2px 2px 5px  rgba(0,0,0,0.27)',
        left: '50%',
        marginRight: '-50%',
        maxWidth: '600px',
        minWidth: '300px',
        padding: '30px',
        right: 'auto',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 300,
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
        zIndex: 301,
    },
};
