interface RegisterLicenseKeyProps {
    accountsApiUrl: string;
    accountsToken: string;
    license_key: string;
    license_owner: string;
}

interface RegisterLicenseKeyResponse {
    status: 'success' | 'error';
    messages?: Array<{
        code: number, 
        message: string
    }>;
    message?: string;
}

const postRegisterLicenseKey = async ({
    accountsToken,
    accountsApiUrl,
    license_key,
    license_owner,
}: RegisterLicenseKeyProps): Promise<RegisterLicenseKeyResponse | undefined> => {
    try {
        const postLicenseKey = await fetch(`${accountsApiUrl}/api/1.1/register/`, {
            body: JSON.stringify({
                license_key,
                license_owner,
            }),
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${accountsToken}`,
                'Content-Type': 'application/json; charset=utf-8',
            },
            method: 'POST',
            mode: 'cors',
        });
        const response = await postLicenseKey.json();

        if (response) {
            //success = messages: Array<string>, registrations: Array<{}>, status: 'success'
            if (response.status === 'success') {
                return {
                    message: 'Successful license key registration',
                    status: response.status,
                }
            } else if (response.status === 'error') {
                return {
                    messages: response.messages,
                    status: response.status,
                }
            }
            return response;
        }
    } catch(error) {
        console.log('errors registering license key: ', error);
    }
}

export default postRegisterLicenseKey;