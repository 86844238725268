import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Stripe from 'stripe';
import styled from 'styled-components';

import ErrorBox from '../../reusable-components/ErrorBox';
import { useTheme, useThemeUpdate } from './ThemeContext';
import { ButtonNeuComp } from '../../reusable-components/Button';
import { postRetrieveInvoicePreview } from '../../utilities/postRetrieveInvoicePreview';
import { ActionType } from '../../store/types';

const ModalWrapper = styled.div`
    height: 100vh;
    width: 100vw;
    top: 0;
    position: fixed;
    background-color: rgba(0,0,0,0.35);
    overflow: hidden;
`;

const Modal = styled.div`
    position: absolute;
    inset: 50% auto auto 50%;
    border: 0px;
    background: rgb(255, 255, 255);
    overflow: auto;
    border-radius: 6px;
    outline: none;
    padding: 30px;
    box-shadow: rgb(0 0 0 / 27%) 2px 2px 5px;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    width: 600px;
`;

const LinkContainer = styled.div`
    margin: 20px 0;

    > a {
        text-decoration: underline;
        cursor: pointer;
    }
`;

const CancelSubscriptionLink = styled.a`
    text-decoration: underline;
    font-style: italic;
    color: red;
    margin-top: 20px;
    font-size: 13px;
    cursor: pointer;
`;


interface SubscriptionMenuProps {
    subscription: Stripe.Subscription;
    subscriptionType: 'month' | 'year';
    toggleModal: (toggle: boolean) => void;
    teamId: null | number;
}

const SubscriptionMenu: React.FC<SubscriptionMenuProps> = (props) => {
    const dispatch = useDispatch();
    const { error } = useTheme();
    const {
        setError,
        setEstimatedPayment,
        toggleChangeModal,
        toggleCancelModal,
        toggleMenu,
    } = useThemeUpdate();

    const accountsToken = useSelector((state: OmniAccounts.GlobalReduxState) => state.accountsToken);
    const customerInfo = useSelector((state: OmniAccounts.GlobalReduxState) => state.customerInfo);
    // const teamPageIDToEdit = useSelector((state: OmniAccounts.GlobalReduxState) => state.teamPageIDToEdit);
    const purchaseapiURL = useSelector((state: OmniAccounts.GlobalReduxState) => state.purchaseapiURL);

    const getInvoicePreview = () => {
        if (!customerInfo) return;

        const subscriptionPlanID = props.subscription.items.data[0].plan.id;
        postRetrieveInvoicePreview({
            accountsToken,
            customer_id: customerInfo.id,
            postBody: {
                default_tax_rates: undefined,
                plan: subscriptionPlanID.includes('ANNUAL')
                    ? subscriptionPlanID.replace('ANNUAL', 'MONTHLY')
                    : subscriptionPlanID.replace('MONTHLY', 'ANNUAL'),
                quantity: 1,
                subscription_item_id: props.subscription.items.data[0].id,
            },
            purchaseapiURL,
            subscription_id: props.subscription.id,
        })
        .then((result: Stripe.Invoice) => {
            const newEstimatedAmount = result.lines.data[1].amount + result.lines.data[0].amount
            setEstimatedPayment(newEstimatedAmount ?? 0);
            toggleMenu(false);
            toggleChangeModal(true);
        })
        .catch((error) => {
            console.error(error);
            setError('something went wrong loading preview.');
        })
    };

    return (
        <ModalWrapper>
            <Modal>
                {error.length > 0 && (
                    <ErrorBox>
                        <p>{error}</p>
                    </ErrorBox>
                )}

                <h2>Modify Subscription</h2>
                { props.subscriptionType === 'month' ? (
                    <div>
                        <LinkContainer>
                            <a onClick={() => {
                                getInvoicePreview()
                                toggleChangeModal(true)
                                toggleMenu(false)
                            }}>
                                Switch to yearly billing cycle
                            </a>
                        </LinkContainer>
                    </div>
                ) : (
                    <div>
                        <p>
                            Yearly billing periods cannot be converted to monthly more than 
                            30 days before the end of the current billing period.
                        </p>
                    </div>
                )}
                <LinkContainer>
                    <Link 
                        to="/manage-team"
                        onClick={() => dispatch({ 
                            type: ActionType.SET_TEAM_ID_TO_EDIT,
                            value: props.teamId?.toString(),
                        })}
                    >
                        Add Seats
                    </Link>
                </LinkContainer>
                <p>
                    <CancelSubscriptionLink 
                        onClick={() => {
                            toggleMenu(false)
                            toggleCancelModal(true)
                    }}>
                        Cancel Subscription...
                    </CancelSubscriptionLink>
                </p>
                <ButtonNeuComp 
                    primary
                    style={{ margin: '20px 0 0'}}
                    onClick={() => {
                        toggleMenu(false)
                        props.toggleModal(false)
                    }}
                >
                    Done
                </ButtonNeuComp>
            </Modal>
        </ModalWrapper>
    )
}

export default SubscriptionMenu;