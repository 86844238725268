import React, { createContext, useContext, useState } from 'react';
import Stripe from 'stripe';

type ThemeContextProps = {
    cancelModal: boolean;
    changeModal: boolean;
    currentSubscription: Stripe.Subscription | null;
    deleteCards: boolean;
    subscriptionMenu: boolean;
    error: string;
    estimatedPayment: number;
}

type ThemeUpdateContextProps = {
    setDeleteCards: React.Dispatch<React.SetStateAction<boolean>>;
    setError: React.Dispatch<React.SetStateAction<string>>;
    setEstimatedPayment: React.Dispatch<React.SetStateAction<number>>;
    toggleCancelModal: React.Dispatch<React.SetStateAction<boolean>>;
    toggleChangeModal: React.Dispatch<React.SetStateAction<boolean>>;
    toggleMenu: React.Dispatch<React.SetStateAction<boolean>>;
    setCurrentSubscription: React.Dispatch<React.SetStateAction<Stripe.Subscription | null>>;
}

const initialThemeValues = {
    cancelModal: false,
    changeModal: false,
    currentSubscription: null,
    deleteCards: false,
    error: '',
    estimatedPayment: 0,
    subscriptionMenu: false,
};

const initialThemeUpdateValues = {
    setCurrentSubscription: () => null,
    setDeleteCards: () => null,
    setError: () => null,
    setEstimatedPayment: () => null,
    toggleCancelModal: () => null,
    toggleChangeModal: () => null,
    toggleMenu: () => null,
}

const ThemeContext = createContext<ThemeContextProps>(initialThemeValues);
const ThemeUpdateContext = createContext<ThemeUpdateContextProps>(initialThemeUpdateValues);

interface ThemeProviderProps {
    children: React.ReactNode;
}

const ThemeContextProvider = ({ children }: ThemeProviderProps ) => {
    const [cancelModal, setCancelModal] = useState(false);
    const [changeModal, setChangeModal] = useState(false);
    const [currentSubscription, setCurrentSubscription] = useState<Stripe.Subscription | null>(null);
    const [deleteCards, setDeleteCards] = useState(false);
    const [subscriptionMenu, setToggleMenu] = useState(false);
    const [error, setError] = useState('');
    const [estimatedPayment, setEstimatedPayment] = useState<number>(0);

    return (
        <ThemeContext.Provider value={{
            cancelModal,
            changeModal,
            currentSubscription,
            deleteCards,
            error, 
            estimatedPayment,
            subscriptionMenu,
        }}>
            <ThemeUpdateContext.Provider value={{
                setCurrentSubscription,
                setDeleteCards,
                setError,
                setEstimatedPayment,
                toggleCancelModal: setCancelModal,
                toggleChangeModal: setChangeModal,
                toggleMenu: setToggleMenu,
            }}>
                {children}
            </ThemeUpdateContext.Provider>
        </ThemeContext.Provider>
    )
};

export default ThemeContextProvider;

export const useTheme = () => useContext(ThemeContext);
export const useThemeUpdate = () => useContext(ThemeUpdateContext);
