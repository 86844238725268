import Stripe from 'stripe';

export enum ActionType {
    ADD_STRIPE_KEY = 'ADD_STRIPE_KEY',
    UPDATING_COUNTER = 'UPDATING_COUNTER', 
    SET_TEAM_ID_TO_EDIT = 'SET_TEAM_ID_TO_EDIT',
    USER_LOGGED_IN = 'USER_LOGGED_IN',
    USER_LOGGED_OUT = 'USER_LOGGED_OUT',
    UPDATING_CARD = 'UPDATING_CARD',
    EDIT_PASSWORD = 'EDIT_PASSWORD',
    EDIT_ACCOUNT = 'EDIT_ACCOUNT',
    DONE_EDITING_ACCOUNTS = 'DONE_EDITING_ACCOUNTS',
    SAVE_TEAM_SEATS = 'SAVE_TEAM_SEATS',
    SAVE_PURCHASES_DATA = 'SAVE_PURCHASES_DATA',
    SAVE_REGISTRATIONS = 'SAVE_REGISTRATIONS',
    REFRESH_THIS_SUBSCRIPTION = 'REFRESH_THIS_SUBSCRIPTION',
    SAVE_USER_INFO = 'SAVE_USER_INFO',
    SAVE_USER_MANAGED_LICENSES = 'SAVE_USER_MANAGED_LICENSES',
    SAVE_CUS_AND_SUBS_INFO = 'SAVE_CUS_AND_SUBS_INFO',
    SAVE_JUST_SUBSCRIPTION_INFO = 'SAVE_JUST_SUBSCRIPTION_INFO',
    SIGNIFY_NO_STRIPE_ID = 'SIGNIFY_NO_STRIPE_ID',
    ADD_PAYMENT_METHODS = 'ADD_PAYMENT_METHODS',
    UPDATING_SUBSCRIPTIONS = 'UPDATING_SUBSCRIPTIONS',
    ADD_API_URL = 'ADD_API_URL',
    ADD_SEAT_FOR_REMOVAL = 'ADD_SEAT_FOR_REMOVAL',
    CLEAR_SEAT_REMOVAL_ARRAY = 'CLEAR_SEAT_REMOVAL_ARRAY',
    REMOVE_SEAT_FROM_REMOVAL = 'REMOVE_SEAT_FROM_REMOVAL',
    ADD_SEAT_LIST_TO_ARRAY = 'ADD_SEAT_LIST_TO_ARRAY',
    CLEAR_LIST_OF_SEATS = 'CLEAR_LIST_OF_SEATS',
    REFRESH_USER_LICENSES = 'REFRESH_USER_LICENSES',
    UNACTIVATED_UPGRADES = 'UNACTIVATED_UPGRADES',
}


// a key that we get from purchase-api
interface AddStripeKey {
    type: ActionType.ADD_STRIPE_KEY;
    publishable_key: string;
}

// a value that we use when we want to kick off useful updates
interface UpdateCounter {
    type: ActionType.UPDATING_COUNTER;
}

// an id from LicenseObject type things that are used on ManageTeam
interface TeamIDToEdit {
    type: ActionType.SET_TEAM_ID_TO_EDIT;
    value: string;
}

// when a user logs in, this carries the accesstoken
interface SetAccessToken {
    type: ActionType.USER_LOGGED_IN;
    accessToken: string;
}

// when a user logs out, clear a lot of things
interface UserLoggedOut {
    type: ActionType.USER_LOGGED_OUT;
}

// toggle editing of credit cards
interface EditCardToggle {
    type: ActionType.UPDATING_CARD;
    value: boolean;
}

// toggle editing of password
interface EditPasswordToggle {
    type: ActionType.EDIT_PASSWORD;
    value: boolean;
}

// toggle editing of account
interface EditAccountToggle {
    type: ActionType.EDIT_ACCOUNT;
    value: boolean;
}

// after they change email maybe
interface DoneEditingAccount {
    type: ActionType.DONE_EDITING_ACCOUNTS;
}

// after downloading team seats, save the array
interface SaveTeamSeats {
    type: ActionType.SAVE_TEAM_SEATS;
    value: Array<OmniAccounts.TeamSeatObject>;
}

// after downloading team seats, save the array
interface SavePurchasesData {
    type: ActionType.SAVE_PURCHASES_DATA;
    value: Array<OmniAccounts.LicenseObject>;
}

//after downloading userinfo registrations, save the array
interface SaveRegistrationsData {
    type: ActionType.SAVE_REGISTRATIONS;
    value: Array<OmniAccounts.RegistrationObject>;
}

// after doing something with a subscription, send a signall other places to refresh the GET
interface RefreshSubscriptionID {
    type: ActionType.REFRESH_THIS_SUBSCRIPTION;
    subscription: string;
}

// after fetching userinfo from accounts, save
interface SaveUserInfo {
    type: ActionType.SAVE_USER_INFO;
    userinfo: OmniAccounts.UserObject;
}

// after fetching licenses, save
interface SaveUserLicenses {
    type: ActionType.SAVE_USER_MANAGED_LICENSES;
    licenses: Array<OmniAccounts.LicenseObject>;
}

// save everything stripe returns from a GET
interface SaveStripeCustomerAndSubscription {
    type: ActionType.SAVE_CUS_AND_SUBS_INFO;
    customer: Stripe.Customer;
}

interface SaveSubscriptionInfo {
    type: ActionType.SAVE_JUST_SUBSCRIPTION_INFO;
    subscriptions: Array<Stripe.Subscription>;
}

// let the system know there isn’t a stripe id
interface SignifyNoStripeID {
    type: ActionType.SIGNIFY_NO_STRIPE_ID;
}

//Add all of the payment methods on file
interface AddStripePaymentMethods {
    type: ActionType.ADD_PAYMENT_METHODS;
    paymentMethods: Array<Stripe.PaymentMethod>;
}

interface UpdateSubscriptions {
    type: ActionType.UPDATING_SUBSCRIPTIONS;
    value: boolean;
}

// add URLS from dev
interface AddApiURL {
    type: ActionType.ADD_API_URL;
    server: 'accounts' | 'purchase';
    url: string;
}

// add seats to array for removal
interface AddSeatsForRemoval {
    type: ActionType.ADD_SEAT_FOR_REMOVAL;
    value: OmniAccounts.SeatObject;
}

//remove all of the seats from the array
interface ClearSeatsArray {
    type: ActionType.CLEAR_SEAT_REMOVAL_ARRAY;
}

interface RemoveSeatsForRemoval {
    type:  ActionType.REMOVE_SEAT_FROM_REMOVAL;
    value: OmniAccounts.SeatObject;
}
//
interface AddSeatList {
    type: ActionType.ADD_SEAT_LIST_TO_ARRAY;
    value: { id: number; seats: Array<OmniAccounts.SeatObject> };
}

//
interface ClearSeatList {
    type: ActionType.CLEAR_LIST_OF_SEATS;
}

// refresh the users licenses
interface RefreshUserLicenses {
    type: ActionType.REFRESH_USER_LICENSES;
}

//saves registration seats that can be upgraded
interface UnactivatedUpgrades {
    type: ActionType.UNACTIVATED_UPGRADES;
    registrations: OmniAccounts.UnactivatedUpgradesInformation;
}

export type Action =
    | AddStripeKey
    | UpdateCounter
    | TeamIDToEdit
    | SetAccessToken
    | UserLoggedOut
    | EditCardToggle
    | EditPasswordToggle
    | EditAccountToggle
    | DoneEditingAccount
    | SaveTeamSeats
    | SavePurchasesData
    | SaveRegistrationsData
    | RefreshSubscriptionID
    | SaveUserInfo
    | SaveUserLicenses
    | SaveStripeCustomerAndSubscription
    | SaveSubscriptionInfo
    | SignifyNoStripeID
    | AddStripePaymentMethods
    | UpdateSubscriptions
    | AddApiURL
    | AddSeatsForRemoval
    | ClearSeatsArray
    | RemoveSeatsForRemoval
    | AddSeatList
    | ClearSeatList
    | RefreshUserLicenses
    | UnactivatedUpgrades;