import * as React from 'react';
import styled from 'styled-components';

interface StyleProps {
    ableToSelectAllUsers?: boolean;
}

const TableWrapper = styled.div`
    border: 1px solid black;
    border-radius: 5px;

    > div {
        font-size: 14px;
    }
`;

const Header = styled.div`
    font-size: 18px;
    font-weight: bold;
    background-color: black;
    color: white;
    padding: 10px;
`;

const ProductTitle = styled.div`
    font-size: 18px;
    font-weight: bold;

    > span {
        font-style: italic;
        font-size: 14px;
        font-weight: 400;
    }
`;

const TopPortion = styled.div`
    display: flex;
    flex-direction: row;
    background-color: black;
`;

const TableContents = styled.div`
    margin: 10px;
`;

const TableRow = styled.div`
    display: grid;
    grid-template-columns: 50px 150px 300px auto;
    align-items: center;
`;

const List = styled.div`
    border: 1px solid rgb(212, 207, 199);

    ${TableRow}:nth-of-type(even) {
        background-color: rgba(240, 240, 241, 0.5);
    }

    ${TableRow}:hover {
        background-color: rgba(211, 230, 249, 1);
    }
`

const TableHeader = styled.div<StyleProps>`
    padding: 10px;
    display: ${props => props.ableToSelectAllUsers ? 'none' : 'block'};
`;

const BottomPortion = styled.div`
    display: flex;
    margin: 20px 0px;

    > input {
        width: 60px;
        margin: 0 50px;
        height: 25px;
    }
`;

interface EditUpgradesProps {
    selectedSeats: Array<OmniAccounts.SeatRegistrationInfo>;
    upgradeInformation: OmniAccounts.RegistrationObj;
    unassignedSeats: number;

    setNumberOfUnassignedSeats: React.Dispatch<React.SetStateAction<number>>;
    setNumberOfSeatsSelected: (seats: number) => void;
    setSelectedSeats: React.Dispatch<React.SetStateAction<OmniAccounts.SeatRegistrationInfo[]>>;
}

const EditUpgrades: React.FC<EditUpgradesProps> = (props) => {
    const [allChecked, setAllChecked] = React.useState<boolean>(false);

    if (!props.upgradeInformation) {
        return null;
    }

    const { 
        from_name,
        seats,
        total_seats, //seats left over to upgrade
        upgrades_available,
    } = props.upgradeInformation;

    const { 
        selectedSeats, 
        setSelectedSeats,
        setNumberOfUnassignedSeats, 
        unassignedSeats,
    } = props;

    const adjustUserList = (event: React.MouseEvent<HTMLInputElement>, user: OmniAccounts.SeatRegistrationInfo) => {
        const selection = event.currentTarget.checked;
        const email = user.email;

        if (selection === true) {
            props.setNumberOfSeatsSelected(props.selectedSeats.length + 1);
            setSelectedSeats(prevSeats => [...prevSeats, user]);
        } else {
            const currentSeats = selectedSeats.filter((user) => user.email !== email);
            setSelectedSeats(currentSeats);
            props.setNumberOfSeatsSelected(currentSeats.length);
        }
    }

    const selectAllUsers = () => {
        if (!allChecked === true) {
            setSelectedSeats(seats);
            props.setNumberOfSeatsSelected(seats.length);
        } else {
            setSelectedSeats([]);
            props.setNumberOfSeatsSelected(0);
        }
        setAllChecked(!allChecked);
    }

    const checkedStatus = (email: string) => {
        const seat = selectedSeats.filter((user) => user.email === email);
        const isChecked = seat.length > 0;
        return allChecked === true ? true : isChecked;
    }

    const addRemoveUnassignedSeats = (e: React.ChangeEvent<HTMLInputElement>) => {
        const numberOfSeats = Number(e.currentTarget.value);
        setNumberOfUnassignedSeats(numberOfSeats);
    }

    const ableToSelectAllUsers = (upgrades_available >= seats.length) && (upgrades_available >= (unassignedSeats + selectedSeats.length));
    const totalSeatsOnTeam = upgrades_available + total_seats;
    const unassignedSeatsOnTeam = totalSeatsOnTeam - seats.length;

    const unassignedSeatsAvailableToUpgrade = unassignedSeatsOnTeam > upgrades_available ? 
        upgrades_available : unassignedSeatsOnTeam;

    return (
        <TableWrapper>
            <TopPortion>
                <Header>
                    <ProductTitle>  
                        {from_name} &nbsp;
                        <span>({selectedSeats.length + unassignedSeats} seat selected)</span>
                    </ProductTitle>
                    <div style={{
                        fontSize: '14px',
                        fontWeight: '400'
                        }}
                    >
                        Total Seats: {totalSeatsOnTeam}
                    </div>
                </Header>
            </TopPortion>
            <TableContents>
                {seats.length > 0 && (
                    <>
                        <TableRow>
                            {ableToSelectAllUsers && (
                                <input 
                                    type="checkbox" 
                                    id="all-selection"
                                    onClick={() => selectAllUsers()}
                                />
                            )}
                            <TableHeader ableToSelectAllUsers={ableToSelectAllUsers}></TableHeader>
                            <TableHeader>User</TableHeader>
                            <TableHeader>Email</TableHeader>
                        </TableRow>
                        <List>
                            {seats.map((user) => {
                                const checked = checkedStatus(user.email);
                                return (
                                    <TableRow key={user.id}>
                                        <input 
                                            type='checkbox'
                                            onClick={(e) => adjustUserList(e, user)}
                                            checked={checked}
                                            readOnly
                                        />
                                        <div>{user.user}</div>
                                        <div>{user.email}</div>
                                    </TableRow>
                                )
                            })} 
                        </List>
                    </>
                )}
                <BottomPortion>
                    <div>Unassigned seats to upgrade: </div>
                    <input 
                        disabled={unassignedSeatsAvailableToUpgrade === 0 ? true : false}
                        min={0}
                        max={unassignedSeatsAvailableToUpgrade}
                        type="number"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => addRemoveUnassignedSeats(e)}
                    />
                    <div>Max: {unassignedSeatsAvailableToUpgrade}</div>
                </BottomPortion>
            </TableContents>
        </TableWrapper>
    )
}

export default EditUpgrades;
