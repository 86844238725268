import { useState, Fragment, SyntheticEvent } from 'react';
import styled from 'styled-components';
import HoldingBox from '../reusable-components/HoldingBox';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button } from '../reusable-components/Button';
import ErrorBox from '../reusable-components/ErrorBox';
import LikeEmail from './LikeEmail';
import postEmailChange from '../utilities/postEmailChange';
import { ActionType } from '../store/types';

const LineItemHeader = styled.div`
    /* margin-bottom: 5px; */
    font-weight: bold;
`;

const WaitingForVerification = styled.span`
    margin: 10px 0;
    padding: 10px 20px;
    font-style: italic;
    background: rgba(255, 255, 0, 0.47);
    line-height: 1.4em;
`;

const DeleteAndDeactivate = styled.div`
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    > a {
        margin: 0 20px 0 0;
    }
`;

const CheckForDeactivateEmail = styled.div`
    margin: 0 40px 0 0;
    width: 300px;
    z-index: 2000;
    padding: 20px;
    position: fixed;
    display: flex;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px 0px;
    top: 50%;
    left: 50%;
    background: #fff;
    transform: translate(-50%, -50%);
    border: 2px solid rgba(58, 159, 242, 1);
    transition: all 0.4s;

    @media (max-width: 600px) {
        width: initial;
    }

    h2 {
        text-align: center;
    }
`;

const LineItem = styled.p`
    font-size: 16px;
`;

const EmailLabel = styled.label`
    font-size: 16px;
    width: 100%;
    margin-top: 0;
`;

const LineItemInput = styled.input`
    font-size: 16px;

    @media (max-width: 600px) {
        width: 90%;
    }
`;

const AccountForm = styled.form`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
`;

const Buttons = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

interface ErrorState {
    error: boolean;
    errorMessage: string;
}

const Account = (): React.ReactElement => {
    const dispatch = useDispatch();

    const [loading, setLoading] = useState<boolean>(false);
    const [showAlertForDeactivateEmail, setAlertForDeactivateEmail] = useState<boolean>(false);
    const [email, setEmail] = useState<string>('');
    const [emailChangedSuccess, setEmailChangedSuccess] = useState<boolean>(false);

    const [errors, setErrors] = useState<ErrorState>({ error: false, errorMessage: '', });

    const userEmail = useSelector((state: OmniAccounts.GlobalReduxState) => state.userEmail);
    const accountName = useSelector((state: OmniAccounts.GlobalReduxState) => state.signedInUserName);
    const accountsApiURL = useSelector((state: OmniAccounts.GlobalReduxState) => state.accountsApiURL);

    const accountsToken = useSelector((state: OmniAccounts.GlobalReduxState) => state.accountsToken);
    const accountsEditReady = useSelector((state: GlobalReduxState) => state.accountsEditReady);

    const handleSubmit = async (e: SyntheticEvent) => {
        e.preventDefault();
        setLoading(true);

        const emailChangeResponse = await postEmailChange({
            accountsApiURL,
            accountsToken,
            email,
        });

        if (emailChangeResponse.updated_attributes) {
            setEmailChangedSuccess(true);
            setLoading(false);
            dispatch({ type: ActionType.DONE_EDITING_ACCOUNTS });
        } else if (emailChangeResponse.errors !== undefined) {
            switch (emailChangeResponse.errors[0].code) {
                case 11010: //This email address is already in use
                    setErrors({
                        error: true,
                        errorMessage: emailChangeResponse.errors[0].message,
                    });
                    setLoading(false);
                    break;
                case 3019: //email address already in use by...this user?
                    setErrors({
                        error: true,
                        errorMessage: emailChangeResponse.errors[0].message,
                    });
                    setLoading(false);
                    break;
                case 3020: //email address already in use by...this user?
                    setErrors({
                        error: true,
                        errorMessage: emailChangeResponse.errors[0].message,
                    });
                    setLoading(false);
                    break;
                case 4000: // cannot send email so prob a bad email address
                    setErrors({
                        error: true,
                        errorMessage: emailChangeResponse.errors[0].message,
                    });
                    setLoading(false);
                    break;
                default:
                    setErrors({
                        error: true,
                        errorMessage:
                            'Something went wrong. Please try again, or contact support@omnigroup.com',
                    });
                    setLoading(false);
                    break;
            }
        }
    };

    const handleEmailInput = (event: React.FormEvent<HTMLInputElement>) => {
        setEmail(event.currentTarget.value);
    };

    return (
        <HoldingBox
            sectionTitle='Account'
            actionTitle='Edit'
            clickAction={() => dispatch({ type: ActionType.EDIT_ACCOUNT })}
            cancelAction={() => dispatch({ type: ActionType.DONE_EDITING_ACCOUNTS })}
            activeWatcher={accountsEditReady}
            disableUpperBorder={true}
        >
            {accountsEditReady ? (
                <Fragment>
                    <AccountForm
                        method='post'
                        onSubmit={(e) => handleSubmit(e)}
                    >   
                        <EmailLabel>
                            <LineItemHeader>Email Address</LineItemHeader>
                        </EmailLabel>
                        {errors.error && (
                            <ErrorBox
                                errorTitle='Something went wrong.'
                                textLeft
                            >
                                <p>{errors.errorMessage}</p>
                            </ErrorBox>
                        )}
                        <LineItemInput
                            type='email'
                            placeholder={userEmail}
                            onChange={(e) => handleEmailInput(e)}
                        />

                        <Buttons>
                            <Button
                                showDisabled={
                                    email.length === 0 ||
                                    !email.includes('@') ||
                                    !email.includes('.')
                                }
                                showLoading={loading ? loading : undefined}
                                type='submit'
                            >
                                <span>Update Email</span>
                            </Button>
                        </Buttons>
                    </AccountForm>
                    <DeleteAndDeactivate>
                        <Link to={'/reset'}>Reset Data...</Link>
                    </DeleteAndDeactivate>
                    {showAlertForDeactivateEmail && (
                        <CheckForDeactivateEmail>
                            <p>
                                To deactivate your account, we’ll send an email
                                to {userEmail} to confirm that you want to
                                deactivate your account. Instructions will be
                                included in the email to continue deactivating
                                your account.
                            </p>
                            <Buttons>
                                <Button
                                    type='button'
                                    center
                                    onClick={() =>
                                        setAlertForDeactivateEmail(false)
                                    }
                                >
                                    <span>OK</span>
                                </Button>
                            </Buttons>
                        </CheckForDeactivateEmail>
                    )}
                </Fragment>
            ) : (
                <Fragment>
                    <LineItemHeader>Account Name</LineItemHeader>
                    <LineItem>{accountName ? accountName : '...'}</LineItem>
                    <LineItemHeader>Email Address</LineItemHeader>
                    <LineItem>{userEmail ? userEmail : '...'}</LineItem>
                    {emailChangedSuccess ? (
                        <WaitingForVerification>
                            Check your inbox to verify {email}.
                        </WaitingForVerification>
                    ) : null}
                </Fragment>
            )}
            {false && <LikeEmail emailaddy={userEmail} />}
        </HoldingBox>
    );
};

export default Account;
