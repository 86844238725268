import * as React from 'react';
import styled from 'styled-components';

import { useDispatch, useSelector } from 'react-redux';
import { ActionType } from '../store/types';
import { Link } from 'react-router-dom';
import { List } from 'react-content-loader';

const License = styled.li`
    font-size: 14px;
    list-style-type: none;
    line-height: 1.3em;
    padding-left: 20px;
    border-left: 2px solid #404040;
    margin-bottom: 10px;
`;

const SeatInfo = styled.div`
    font-size: 14px;
`;

interface ManagedLicenseI {
    license: OmniAccounts.LicenseObject;
}

const ManagedLicense: React.FC<ManagedLicenseI> = (props: ManagedLicenseI) => {
    const dispatch = useDispatch();

    const teamManager = useSelector((state: OmniAccounts.GlobalReduxState) => state.teamManager);

    if (teamManager === null) {
        return <List />;
    }

    return (
        <License key={props.license.id}>
            <h3>
                {props.license.description === 'OmniOutliner 5 for Mac'
                    ? 'OmniOutliner 5 Essentials for Mac'
                    : props.license.description}
            </h3>
            <SeatInfo>Total seats: {props.license.total_seats}</SeatInfo>
            <SeatInfo>Unused seats: {props.license.available_seats}</SeatInfo>
            {teamManager && (
                <Link
                    onClick={() => {
                        sessionStorage.setItem(
                            'teameditid',
                            props.license.id.toString()
                        );
                        dispatch({
                            type: ActionType.SET_TEAM_ID_TO_EDIT,
                            value: props.license.id.toString(),
                        });
                    }}
                    to={{
                        pathname: '/manage-team',
                    }}
                >
                    Manage Team
                </Link>
            )}
        </License>
    );
};

export default ManagedLicense;
