interface HandleSignInI {
    accountsApiURL: string;
    username: string;
    password: string;
}

interface HandleSignInResponse {
    token: { access_token: string };
    userinfo: OmniAccounts.UserObject;
    errors?: Array<{ message: string }>;
    code?: number;
}

const handleSignIn = async (props: HandleSignInI): Promise<HandleSignInResponse | undefined> => {
    const loginParams = new URLSearchParams();

    loginParams.set('username', props.username);
    loginParams.set('password', props.password);

    const rawToken = await fetch(`${props.accountsApiURL}/api/1.1/signin/`, {
        body: loginParams,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
        method: 'POST',
        mode: 'cors',
    })
    
    try {
        const response = await rawToken.json()
        return response;
    } catch (errors) {
        // console.log('Sign In Error: ',errors);
        return undefined;
    }
};

export default handleSignIn;
