import Stripe from 'stripe';

export interface GetPlansResponse {
    plans?: Array<Stripe.Plan>;
    error?: boolean;
}

const getPlans = async (purchaseURL: string): Promise<GetPlansResponse> => {
    const plans = await fetch(`${purchaseURL}/api/v2/plans`, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        method: 'GET',
        mode: 'cors',
    })
        .then((response) => {
            return response.json();
        })
        .then((response) => {
            return { plans: response };
        })
        .catch((err) => {
            console.log('err', err);
            return { error: true };
        });
    return plans;
};

export default getPlans;
