import Stripe from 'stripe';

interface PostFinalizeInvoice extends Stripe.InvoiceFinalizeInvoiceParams {
    purchaseapiURL: string;
    accountsToken: string;
    invoice_id: string;
}
interface PostFinalizeInvoiceResponse {
    invoice: Stripe.Invoice;
}

export const postFinalizeInvoice = async ({
    purchaseapiURL,
    accountsToken,
    invoice_id,
}: PostFinalizeInvoice): Promise<PostFinalizeInvoiceResponse> => {
    return await new Promise((resolve, reject) => {
        fetch(`${purchaseapiURL}/api/v2/invoice/${invoice_id}/finalize`, {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${accountsToken}`,
                'Content-Type': 'application/json',
            },
            method: 'POST',
            mode: 'cors',
        })
            .then((response) => {
                return Promise.resolve(response.json()).then((res) => {
                    if (!res.errors) {
                        resolve({ invoice: res });
                    } else if (res.errors) {
                        console.log(res.errors);
                        return reject(true);
                    }
                    return reject(true);
                });
            })
            .catch((err) => {
                console.log('err', err);
                return reject();
            });
    });
};
