import * as React from 'react';
import styled from 'styled-components/macro';

declare const voidPromise: (e: React.SyntheticEvent) => Promise<void>;
declare const voidVoid: () => void;

interface ButtonProps {
    alignRight?: boolean;
    buttonType?: string;
    center?: boolean;
    children?: React.ReactNode;
    disabled?: boolean;
    danger?: boolean;
    inline?: boolean;
    minWidth?: string;
    onClick?: typeof voidPromise | typeof voidVoid;
    selected?: boolean;
    showDisabled?: boolean;
    showLoading?: boolean;
    someMargin?: boolean;
    style?: React.CSSProperties;
    type?: 'submit' | 'reset' | 'button';
    uppercase?: boolean;
    value?: string;
}

export interface SideBySideProps {
    children?: React.ReactNode;
    flexStart?: boolean;
    minWidth?: string;
    justify: 'space-between' | 'flex-start' | 'flex-end';
    style?: React.CSSProperties;
}

const SideBySideHolder = styled.div<SideBySideProps>`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: ${(props: SideBySideProps): string => props.justify};
    flex-wrap: wrap;
`;

interface TinyStyledButtonProps {
    children: React.ReactNode;
    onClick?: typeof voidPromise | typeof voidVoid;
    showDisabled: boolean;
    showLoading: boolean;
}

const TinyStyledButton = styled.button<TinyStyledButtonProps>`
    padding: 6px 10px;
    font-size: 12px;
    background: #fff;
    border: 1px solid #909090;
    border-radius: 8px;
`;

interface ButtonNeuI {
    alignRight?: boolean;
    children: React.ReactNode;
    onClick?: () => void;
    primary: boolean;
    showDisabled?: boolean;
    showLoading?: boolean;
    style?: React.CSSProperties;
}

const BaseButton = styled.button<ButtonProps>`
    background: #fff;
    transition: all 0.3s;
    transition-timing-function: ease-in;
    border: 1px solid #909090;
    cursor: pointer;
    display: block;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
        Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif, sans-serif;
    font-size: 16px;
    font-weight: 600;
    height: initial;
    min-width: 195px;
    line-height: 22px;
    margin: ${(props): string => (props.center ? '10px auto' : '10px 0')};
    padding: 10px 20px;
`;

const ButtonNeu = styled(BaseButton)<ButtonNeuI>`
    background-color: ${(props): string =>
        props.primary ? '#438ef6' : '#fff'};
    color: ${(props): string => (!props.primary ? '#000' : '#fff')};
    border: ${(props): string =>
        props.primary ? '0' : '1px solid rgba(128, 128, 128, 1.00)'};
    padding: 10px 16px;
    border-radius: 8px;
    min-width: 80px;
    font-size: 16px;
    margin: ${(props): string =>
        props.alignRight ? '10px 0 10px auto' : '10px'};

    &:disabled {
        background-color: rgba(0, 0, 0, 0.05);
        color: #404040;
        border: 1px solid rgba(0, 0, 0, 0);
    }
`;

const WarningButton = styled(BaseButton)`
    background: #fff;
    color: rgba(255, 0, 0, 1);
    border: 1px solid rgba(255, 0, 0, 1);

    &:hover {
        background: rgba(255, 179, 179, 0.71);
        color: rgba(255, 0, 0, 1);
    }
    &:active {
        background: rgba(255, 0, 0, 0.71);
        color: #fff;
    }
`;

const StyledButton = styled(BaseButton)<ButtonProps>`
    color: ${(props): string => (props.selected ? '#fff' : '#000')};
    background: ${(props): string =>
        props.selected ? 'rgba(48, 140, 255, 1.00)' : '#fff'};
    border: ${(props): string | undefined =>
        props.selected ? '1px solid rgba(17, 61, 147, 1.00)' : undefined};
    display: ${(props): string => (props.inline ? 'inline' : 'block')};
    margin: ${(props): string | undefined =>
        props.someMargin ? '10px 10px' : undefined};
    margin: ${(props): string | undefined =>
        props.alignRight ? '10px 0 10px auto' : undefined};
    text-transform: ${(props): string =>
        props.uppercase ? 'uppercase' : 'initial'};
    min-width: ${(props): string | undefined => props.minWidth};

    &:disabled {
        background-color: rgba(0, 0, 0, 0.05);
        color: #404040;
        border: 1px solid rgba(0, 0, 0, 0);
    }

    &:disabled:hover {
        background-color: rgba(0, 0, 0, 0.05);
        color: #404040;
    }

    &:disabled:active {
        background-color: rgba(0, 0, 0, 0.05);
        color: #404040;
    }

    &:hover {
        cursor: pointer;
        background: ${(props): string =>
            props.danger ? 'red' : 'rgba(211, 230, 249, 1.00)'};
    }

    &:active {
        background: rgba(48, 140, 255, 1);
        color: #fff;
    }
`;

const SideBySide: React.FC<SideBySideProps> = ({
    children,
    ...props
}: SideBySideProps) => {
    return <SideBySideHolder {...props}>{children}</SideBySideHolder>;
};

const Button: React.FC<ButtonProps> = ({ children, ...props }: ButtonProps) => {
    return (
        <StyledButton
            disabled={props.showDisabled}
            showDisabled={props.showDisabled}
            className={
                props.showLoading === true
                    ? 'ld-ext-right running'
                    : 'ld-ext-right'
            }
            {...props}
        >
            {children}
            <div className='ld ld-ring ld-spin'></div>
        </StyledButton>
    );
};

const ButtonNeuComp: React.FC<ButtonNeuI> = ({
    children,
    ...props
}: ButtonNeuI) => {
    return (
        <ButtonNeu
            disabled={props.showDisabled}
            className={
                props.showLoading === true
                    ? 'ld-ext-right running'
                    : 'ld-ext-right'
            }
            {...props}
        >
            {children}
            <div className='ld ld-ring ld-spin'></div>
        </ButtonNeu>
    );
};

const TinyButton: React.FC<TinyStyledButtonProps> = ({
    children,
    ...props
}: TinyStyledButtonProps) => {
    return (
        <TinyStyledButton
            className={
                props.showLoading === true
                    ? 'ld-ext-right running'
                    : 'ld-ext-right'
            }
            {...props}
        >
            {children}
            <div className='ld ld-ring ld-spin'></div>
        </TinyStyledButton>
    );
};

export { Button, SideBySide, TinyButton, WarningButton, ButtonNeuComp };
